import "../locales";
import * as React from "react";
import { PostHogProvider } from "posthog-js/react";
import { Outlet } from "react-router-dom";
import { useUserSlice } from "@/models/useUserSlice";
import { isNativeApp } from "@/models/platform";
import { useUISlice } from "@/models/useUISlice";
import { Toaster } from "react-hot-toast";
import { Colors } from "@/constants";

const HelmetProvider = React.lazy(() =>
  import("react-helmet-async").then((m) => ({ default: m.HelmetProvider }))
);

const GoogleOAuthProvider = React.lazy(() =>
  import("@react-oauth/google").then((m) => ({
    default: m.GoogleOAuthProvider,
  }))
);

// Lazy load analytics
const loadAnalytics = () => {
  import("@/fetchData/gtag").then((gtag) => {
    gtag.pageview(window.location.pathname);
  });
  import("@/fetchData/fpixel").then((fbq) => {
    fbq.pageview();
  });
  import("@/fetchData/datadog");
};

export default function MyApp() {
  const _hydrated = useUserSlice((state) => state._hasHydrated);

  React.useEffect(() => {
    const handleResize = () => {
      useUISlice.getState().setDevice({
        isMobile: window.innerWidth < 768,
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    loadAnalytics();

    if (isNativeApp()) {
      import("@/models/useNative").then(({ setStatusBarStyleDark }) => {
        setStatusBarStyleDark();
      });
    }

    const handlePopState = () => {
      loadAnalytics();
    };

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  if (!_hydrated) {
    return null;
  }

  return (
    <React.Suspense>
      <HelmetProvider>
        <PostHogProvider
          apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
          options={{
            api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
            disable_session_recording: true,
          }}
        >
          <GoogleOAuthProvider
            clientId={import.meta.env.VITE_PUBLIC_GOOGLE_CLIENT_ID}
          >
            <Outlet />
            <Toaster
              toastOptions={{
                style: { fontFamily: "sans-serif", color: Colors.text },
              }}
            />
          </GoogleOAuthProvider>
        </PostHogProvider>
      </HelmetProvider>
    </React.Suspense>
  );
}

import { create } from "zustand";
import { createJSONStorage, persist, StateStorage } from "zustand/middleware";
import { Preferences } from "@capacitor/preferences";
import { Capacitor } from "@capacitor/core";
import * as Types from "@/models/types";
import * as serverClient from "@/models/serverClient";

const storage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => {
    const { value } = await Preferences.get({ key: name });
    return value;
  },
  setItem: async (name: string, value: string): Promise<void> => {
    await Preferences.set({ key: name, value });
  },
  removeItem: async (name: string): Promise<void> => {
    await Preferences.remove({ key: name });
  },
};

type State = {
  _hasHydrated: boolean;
  migrateStatus?: "success" | "error";
  userToken?: string;
  deviceId?: string;
  transcriptionLanguage: string;

  transcripts: Types.Transcript[];
  user: Types.User | undefined;
  isSubscribed?: boolean;
};

type Actions = {
  setUserToken: (token: string) => void;
  setIsSubscribed: (isSubscribed: boolean) => void;
  setDeviceId: (deviceId: string) => void;
  setHasHydrated: (status: boolean) => void;
  setMigrateStatus: (status: State["migrateStatus"]) => void;
  setTranscripts: (transcripts: Types.Transcript[]) => void;
  setTranscriptionLanguage: (language: string) => void;
  createTranscript: (payload: Types.Transcript) => void;
  updateTranscript: (id: string, payload: Partial<Types.Transcript>) => void;
  deleteTranscript: (id: string) => void;

  reset: () => void;
};

export const initialState: State = {
  _hasHydrated: false,
  transcripts: [],
  user: undefined,
  transcriptionLanguage: navigator.language.toLowerCase().slice(0, 2),
};

// create store
export const useUserSlice = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      setHasHydrated: (status) => {
        set({ _hasHydrated: status });
      },
      setUserToken: (token) => {
        set({ userToken: token });
      },
      setIsSubscribed: (isSubscribed) => {
        set({ isSubscribed });
      },
      setTranscriptionLanguage: (language) => {
        set({ transcriptionLanguage: language });
      },
      setMigrateStatus: (status) => {
        set({ migrateStatus: status });
      },
      setTranscripts: (transcripts) => {
        set({ transcripts });
      },
      setDeviceId: (deviceId) => {
        set({ deviceId });
      },
      createTranscript: (payload) => {
        set((state) => ({
          transcripts: [payload, ...state.transcripts],
        }));

        if (get().userToken) {
          try {
            serverClient.transcriptAsync({ type: "create", payload });
          } catch (error) {}
        }
      },
      updateTranscript: (id, payload) => {
        set((state) => ({
          transcripts: state.transcripts.map((t) =>
            t.id === id ? { ...t, ...payload } : t
          ),
        }));

        if (get().userToken) {
          try {
            serverClient.transcriptAsync({
              type: "update",
              payload: { id, updates: payload },
            });
          } catch (error) {}
        }
      },
      deleteTranscript: (id) => {
        set({ transcripts: get().transcripts.filter((t) => t.id !== id) });

        if (get().userToken) {
          try {
            serverClient.transcriptAsync({ type: "delete", payload: { id } });
          } catch (error) {}
        }
      },
      reset: () => {
        set({
          ...get(),
          userToken: undefined,
          isSubscribed: false,
        });
      },
    }),
    {
      name: `1transcribe-${Capacitor.getPlatform()}-user-storage`,
      storage: createJSONStorage(() => storage),
      onRehydrateStorage: () => (state) => {
        state && state.setHasHydrated(true);
      },
    }
  )
);

//Default guideline sizes are based on standard ~5" screen mobile device
import { Dimensions } from "react-native";

// Adjusted base dimensions for better scaling across devices
const SIZE_MATTERS_BASE_WIDTH = 375; // Standard iPhone width
const SIZE_MATTERS_BASE_HEIGHT = 667; // Standard iPhone height
const MAX_SCALE_MULTIPLIER = 1.2; // Further reduced max scale for smaller elements
const MIN_SCALE_MULTIPLIER = 0.7; // Slightly reduced minimum scale

const { width, height } = Dimensions.get("window");

// Get the shorter dimension for consistent scaling across orientations
const [shortDimension, longDimension] =
  width < height ? [width, height] : [height, width];

const guidelineBaseWidth = SIZE_MATTERS_BASE_WIDTH;
const guidelineBaseHeight = SIZE_MATTERS_BASE_HEIGHT;

// Calculate scale based on both dimensions
const widthScale = shortDimension / guidelineBaseWidth;
const heightScale = longDimension / guidelineBaseHeight;
const baseScale = Math.min(widthScale, heightScale);

// Ensure scale stays within reasonable bounds
const scale = Math.min(
  Math.max(baseScale, MIN_SCALE_MULTIPLIER),
  MAX_SCALE_MULTIPLIER
);

const moderateScale = (size: number, factor = 0.3) => {
  const scaledSize = size * scale;
  const diffSize = scaledSize - size;
  return size + diffSize * Math.min(factor, MAX_SCALE_MULTIPLIER);
};

export const ms = moderateScale;

import { useState, useRef, useCallback, useEffect } from "react";
import { VoiceRecorder } from "capacitor-voice-recorder";
import { ForegroundService } from "@capawesome-team/capacitor-android-foreground-service";
import { Capacitor } from "@capacitor/core";
import { useTranslation } from "react-i18next";
import { logger } from "@/fetchData/posthog";
import { useUISlice } from "@/models/useUISlice";
import toast from "react-hot-toast";

export const formatTime = (ms: number) => {
  const totalSeconds = Math.floor(ms / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
};

export const useRecorder = () => {
  const [status, setStatus] = useState<"inactive" | "recording" | "paused">(
    "inactive"
  );
  const isAndroid = Capacitor.getPlatform() === "android";
  const { t } = useTranslation();
  const startTimeRef = useRef<number | null>(null);
  const durationRef = useRef<number>(0);
  const [elapsedTime, setElapsedTime] = useState(0);
  const timerIntervalRef = useRef<NodeJS.Timeout>();
  const isInitializedRef = useRef(false);

  const startForegroundService = async () => {
    if (!isAndroid) return;

    await ForegroundService.startForegroundService({
      title: "Recording in progress",
      body: "The recording started in the background",
      id: 12343,
      smallIcon: "ic_stat_notify",
    });
  };

  // Cleanup function
  const cleanup = useCallback(async () => {
    if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current);
    }
    try {
      if (status !== "inactive") {
        await VoiceRecorder.stopRecording();
        if (isAndroid) {
          await ForegroundService.stopForegroundService();
        }
      }
    } catch (error) {
      logger.error("Recorder: Error in cleanup:", error);
    }
    setStatus("inactive");
    startTimeRef.current = null;
    durationRef.current = 0;
    setElapsedTime(0);
  }, [status, isAndroid]);

  useEffect(() => {
    // Only initialize once
    if (!isInitializedRef.current) {
      isInitializedRef.current = true;
      const initPlatform = async () => {
        if (isAndroid) {
          await ForegroundService.stopForegroundService();
        }
      };

      initPlatform().catch((error) => {
        logger.error("Recorder: Error in initialization:", error);
        abortAndGoBack();
      });
    }

    // Cleanup on unmount
    return () => {
      cleanup();
    };
  }, []);

  // Timer effect
  useEffect(() => {
    if (status === "recording") {
      timerIntervalRef.current = setInterval(() => {
        if (startTimeRef.current) {
          setElapsedTime(
            Date.now() - startTimeRef.current + durationRef.current
          );
        }
      }, 1000);
    } else {
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
      }
      if (status === "inactive") {
        setElapsedTime(0);
      }
    }

    return () => {
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
      }
    };
  }, [status]);

  const checkPermission = async () => {
    try {
      const { value: deviceCanRecord } =
        await VoiceRecorder.canDeviceVoiceRecord();

      if (!deviceCanRecord) {
        toast.error(t("ui.deviceNotSupportedMessage"));
        abortAndGoBack();
        return false;
      }

      const { value: hasPermission } =
        await VoiceRecorder.hasAudioRecordingPermission();

      if (!hasPermission) {
        const { value: permissionGranted } =
          await VoiceRecorder.requestAudioRecordingPermission();

        if (!permissionGranted) {
          toast.error(t("ui.microphonePermissionRequiredMessage"));
          abortAndGoBack();
          return false;
        }
      }

      if (isAndroid) {
        await startForegroundService();
      }

      return true;
    } catch (error) {
      logger.error("Recorder: Error checking permission:", error);
      toast.error(t("ui.microphonePermissionRequiredMessage"));
      abortAndGoBack();
      return false;
    }
  };

  const startRecording = async (): Promise<void> => {
    try {
      const hasPermission = await checkPermission();
      if (!hasPermission) return Promise.reject();

      const { value: started } = await VoiceRecorder.startRecording();

      if (!started) {
        throw new Error("Failed to start recording");
      }

      startTimeRef.current = Date.now();
      setStatus("recording");
    } catch (error) {
      toast.error(t("ui.startRecordingError"));
      abortAndGoBack();
      return Promise.reject();
    }
  };

  const stopRecording = async (callback?: (file: File) => void) => {
    try {
      const result = await VoiceRecorder.stopRecording();

      const { value } = result;

      // Convert base64 to blob
      const byteCharacters = atob(value.recordDataBase64);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "audio/wav" });

      // Create file from blob
      const now = new Date();
      const formattedDate = now.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });

      const fileName = `Recording-${formattedDate}.wav`;
      const file = new File([blob], fileName, { type: "audio/wav" });

      if (startTimeRef.current) {
        durationRef.current = Date.now() - startTimeRef.current;
      }

      setStatus("inactive");
      startTimeRef.current = null;
      durationRef.current = 0;

      if (callback) {
        callback(file);
      }
    } catch (error) {
      toast.error(t("ui.stopRecordingError"));
      abortAndGoBack();
    }
  };

  const pauseRecording = async () => {
    try {
      const { value: paused } = await VoiceRecorder.pauseRecording();

      if (!paused) {
        throw new Error("Failed to pause recording");
      }

      if (startTimeRef.current) {
        durationRef.current += Date.now() - startTimeRef.current;
        startTimeRef.current = null;
      }

      setStatus("paused");
    } catch (error) {
      logger.error("Recorder: Error pausing recording:", error);
    }
  };

  const resumeRecording = async () => {
    try {
      const { value: resumed } = await VoiceRecorder.resumeRecording();

      if (!resumed) {
        throw new Error("Failed to resume recording");
      }

      startTimeRef.current = Date.now();
      setStatus("recording");
    } catch (error) {
      logger.error("Recorder: Error resuming recording:", error);
    }
  };

  const pauseOrPlay = async () => {
    if (status === "inactive") {
      await startRecording();
    } else if (status === "recording") {
      await pauseRecording();
    } else if (status === "paused") {
      await resumeRecording();
    }
  };

  const cancelRecording = async () => {
    useUISlice.getState().setAlertModal(undefined);
    abortAndGoBack();
  };

  const abortAndGoBack = async () => {
    try {
      await VoiceRecorder.stopRecording();

      if (isAndroid) {
        await ForegroundService.stopForegroundService();
      }
    } catch (error) {}

    setStatus("inactive");
    startTimeRef.current = null;
    durationRef.current = 0;
  };

  return {
    status,
    elapsedTime,
    isRecording: status === "recording",
    startRecording,
    stopRecording,
    pauseOrPlay,
    cancelRecording,
  };
};

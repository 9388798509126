import { Suspense } from "react";
import { Routes } from "@generouted/react-router";
import { createRoot } from "react-dom/client";

const App = () => {
  return (
    <Suspense>
      <Routes />
    </Suspense>
  );
};

createRoot(document.getElementById("root")!).render(<App />);

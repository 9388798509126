import { useRouter } from "@/_router";
import * as fbq from "@/fetchData/fpixel";
import * as gtag from "@/fetchData/gtag";
import { useUISlice } from "@/models/useUISlice";
import * as serverClient from "@/models/serverClient";
const { Purchases } = await import("@revenuecat/purchases-capacitor");

import { useUserSlice } from "@/models/useUserSlice";
import { Pressable, View, Text, StyleSheet } from "react-native";
import { useTranslation } from "react-i18next";
import { ms } from "./moderateScale";
import {
  Colors,
  REVENUE_CAT_ANDROID_API_KEY,
  REVENUE_CAT_IOS_API_KEY,
} from "@/constants";
import { getPlatform } from "@/models/platform";
import {
  CustomerInfo,
  PurchasesPackage,
} from "@revenuecat/purchases-capacitor";
import { useEffect, useState } from "react";
import { useTranscribe } from "@/models/useTranscribe";
import { datadogLogs } from "@datadog/browser-logs";
import toast from "react-hot-toast";
import { gotoPrivacy, gotoTerms } from "@/models/utils";

const NativePaywall = () => {
  const { t } = useTranslation();
  const isSubscribed = useUserSlice((state) => state.isSubscribed);
  const [mobilePkg, setMobilePkg] = useState<PurchasesPackage>();
  const { retranscribeOriginalFile } = useTranscribe();
  const router = useRouter();

  useEffect(() => {
    NativePaywall.getRevenueCatPackage().then((pkg) => {
      setMobilePkg(pkg);
    });
  }, []);

  const _restorePurchase = async () => {
    const customerInfo = useUISlice.getState().revenueCatCustomerInfo;
    if (!customerInfo) return;

    const isSubscribed = customerInfo.activeSubscriptions.length > 0;
    useUserSlice.getState().setIsSubscribed(isSubscribed);

    if (isSubscribed) {
      return useUISlice.getState().setAlertModal({
        title: t("ui.paywallFooter.restorePurchaseTitle"),
        subtitle: t("ui.paywallFooter.restorePurchaseDescription"),
        button: {
          text: t("ui.paywallFooter.restorePurchaseButton"),
          onPress: () => null,
        },
      });
    }

    toast.success(t("ui.paywallFooter.noSubscriptionText"));
  };

  if (isSubscribed) return null;
  if (!mobilePkg) return null;

  let perPeriod = t("ui.paywallFooter.perWeek");
  if (mobilePkg.identifier.includes("monthly")) {
    perPeriod = t("ui.paywallFooter.perMonth");
  } else if (mobilePkg.identifier.includes("annual")) {
    perPeriod = t("ui.paywallFooter.perYear");
  }

  return (
    <View style={styles.paywallContainer}>
      <Text style={styles.title}>{t("ui.paywallFooter.title")}</Text>
      <Text style={styles.subtitle}>{t("ui.paywallFooter.subtitle")}</Text>

      <Text style={styles.subscribeText}>
        {t("ui.paywallFooter.price", {
          price: mobilePkg?.product?.priceString,
          period: perPeriod,
        })}
      </Text>

      <Pressable
        onPress={() =>
          NativePaywall.handlePurchase({
            mobilePkg,
            router,
            retranscribeOriginalFile,
          })
        }
        style={styles.button}
      >
        <Text style={styles.buttonText}>
          {t("ui.paywallFooter.buttonText")}
        </Text>
      </Pressable>

      <View style={styles.footer}>
        <Text onPress={gotoTerms} style={styles.footerLink}>
          {t("ui.paywallFooter.terms")}
        </Text>
        <Text onPress={gotoPrivacy} style={styles.footerLink}>
          {t("ui.paywallFooter.privacy")}
        </Text>
        <Text onPress={_restorePurchase} style={styles.footerLink}>
          {t("ui.paywallFooter.restore")}
        </Text>
      </View>
    </View>
  );
};

NativePaywall.initialize = async (
  forceRefresh = false
): Promise<CustomerInfo> => {
  // return {
  //   allPurchaseDates: {
  //     "2025-03-18": 1741304501544,
  //   },
  // } as any;
  if (!useUserSlice.getState().userToken) return;

  let _customerInfo = useUISlice.getState().revenueCatCustomerInfo;

  if (!_customerInfo) {
    Purchases.configure({
      appUserID: useUserSlice.getState().deviceId,
      apiKey:
        getPlatform() === "ios"
          ? REVENUE_CAT_IOS_API_KEY
          : REVENUE_CAT_ANDROID_API_KEY,
    });
    _customerInfo = await Purchases.getCustomerInfo().then(
      (res) => res.customerInfo
    );
    useUISlice.getState().setRevenueCatCustomerInfo(_customerInfo);
  }

  if (forceRefresh) {
    _customerInfo = await Purchases.getCustomerInfo().then(
      (res) => res.customerInfo
    );
    useUISlice.getState().setRevenueCatCustomerInfo(_customerInfo);
  }

  if (_customerInfo?.activeSubscriptions) {
    useUserSlice
      .getState()
      .setIsSubscribed(_customerInfo?.activeSubscriptions?.length > 0);
  }

  return _customerInfo;
};

let _mobilePkg: PurchasesPackage;
NativePaywall.getRevenueCatPackage = async (): Promise<PurchasesPackage> => {
  if (_mobilePkg) return _mobilePkg;

  let packageId = "mid_tier_weekly";

  await serverClient
    .subscriptionAsync({ type: "revenueCatPackageId" })
    .then((res) => {
      if (res.data.packageId) {
        packageId = res.data.packageId;
      }
    });

  const offerings = await Purchases.getOfferings();
  const pkg = offerings.current.availablePackages.filter(
    (pkg) => pkg.identifier === packageId
  )[0];

  _mobilePkg = pkg;
  return pkg;
};

NativePaywall.handlePurchase = async ({
  mobilePkg,
  router,
  retranscribeOriginalFile,
}: {
  mobilePkg?: PurchasesPackage;
  router?: ReturnType<typeof useRouter>;
  retranscribeOriginalFile?: (id: string) => void;
}) => {
  const platform = getPlatform();

  try {
    datadogLogs.logger.info("[TranscribeFunnel][9] - go to checkout", {
      platform,
    });

    const pkg = mobilePkg || (await NativePaywall.getRevenueCatPackage());

    const { customerInfo } = await Purchases.purchasePackage({
      aPackage: pkg,
    });

    if (customerInfo.activeSubscriptions.length > 0) {
      datadogLogs.logger.info("[TranscribeFunnel][10] - payment success", {
        platform,
      });
      useUISlice.getState().setRevenueCatCustomerInfo(customerInfo);
      useUserSlice.getState().setIsSubscribed(true);
      if (router?.query?.id && retranscribeOriginalFile) {
        retranscribeOriginalFile(router.query.id);
      }

      gtag.conversion({
        value: mobilePkg.product.price.toString(),
        transactionId: String(Date.now()),
      });
      fbq.event("Subscribe", {
        currency: mobilePkg.product.currencyCode,
        value: mobilePkg.product.price.toString(),
      });
    }
  } catch (e: any) {
    __DEV__ && console.log(e);
    if (!e.userCancelled) {
    }
  }
};

NativePaywall.getMobileSubscriptionStatus = async (): Promise<string> => {
  const isActive = useUserSlice.getState().isSubscribed;
  return isActive
    ? serverClient.cipher({ status: "user_subscription_active" })
    : serverClient.cipher({ status: "user_subscription_inactive" });
};

const styles = StyleSheet.create({
  paywallContainer: {
    padding: ms(24),
    paddingHorizontal: ms(24),
    borderRadius: ms(8),
    backgroundColor: Colors.text,
  },
  title: {
    fontSize: ms(32),
    color: Colors.white,
    fontWeight: "700",
    marginBottom: ms(8),
  },
  subtitle: {
    fontSize: ms(16),
    color: Colors.gray4,
    lineHeight: ms(24),
  },
  button: {
    marginTop: ms(24),
    height: ms(56),
    backgroundColor: Colors.white,
    borderRadius: ms(8),
    alignItems: "center",
    justifyContent: "center",
  },
  buttonText: {
    fontSize: ms(18),
    fontWeight: "600",
    color: Colors.text,
  },
  subscribeText: {
    marginTop: ms(12),
    fontWeight: "600",
    fontSize: ms(16),
    color: Colors.gray4,
  },
  footer: {
    flexDirection: "row",
    justifyContent: "center",
    gap: ms(32),
    marginTop: ms(12),
  },
  footerLink: {
    fontSize: ms(14),
    color: Colors.textSecondary,
  },
});

export default NativePaywall;

import {
  Text,
  StyleSheet,
  ViewStyle,
  View,
  ImageStyle,
  Pressable,
  ActivityIndicator,
  TextStyle,
} from "react-native";
import { Colors } from "@/constants";
import Icon, { icons } from "@/ui/Icon";
import { getLangDir } from "@/models/utils";
import { ms } from "@/ui/moderateScale";
const isRTL = getLangDir() === "rtl";

const PrimaryButton = ({
  text,
  onPress,
  style,
  disabled = false,
  loading = false,
  loadingColor,
  textStyle = {},
  iconStyle = {},
  height = ms(40),
  icon,
}: {
  text: string;
  onPress: () => void;
  loadingColor?: string;
  height?: number;
  disabled?: boolean;
  loading?: boolean;
  style?: ViewStyle;
  textStyle?: TextStyle;
  icon?: keyof typeof icons;
  iconStyle?: ImageStyle;
}) => {
  return (
    <View style={{ flexDirection: "row" }}>
      <Pressable
        disabled={disabled || loading}
        onPress={onPress}
        style={StyleSheet.flatten([
          {
            ...s.container,
            height,
            backgroundColor: disabled || loading ? Colors.gray6 : Colors.accent,
          },
          style || {},
        ])}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {!loading && icon && (
            <Icon icon={icon} style={StyleSheet.flatten([s.icon, iconStyle])} />
          )}
          {!loading && (
            <Text style={StyleSheet.flatten([s.text, textStyle])}>{text}</Text>
          )}
          {loading && (
            <ActivityIndicator
              size="small"
              color={loadingColor || Colors.white}
            />
          )}
        </View>
      </Pressable>
    </View>
  );
};

const s = StyleSheet.create({
  container: {
    borderRadius: ms(8),
    backgroundColor: Colors.accent,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: ms(40),
    paddingHorizontal: ms(20),
  },
  text: {
    fontWeight: "500",
    color: Colors.white,
    fontSize: ms(14),
    lineHeight: ms(20),
  },
  icon: {
    marginRight: isRTL ? 0 : ms(8),
    marginLeft: isRTL ? ms(8) : 0,
    tintColor: Colors.white,
  },
});

export default PrimaryButton;

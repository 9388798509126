import {
  View,
  Pressable,
  Text,
  StyleSheet,
  ImageStyle,
  ViewStyle,
  TextStyle,
} from "react-native";
import { Colors } from "@/constants";
import Icon, { IconType } from "@/ui/Icon";
import { ms } from "@/ui/moderateScale";
import { useToast } from "@/models/useToast";
import AlertModal from "@/ui/AlertModal";
import AlertPrompt from "@/ui/AlertPrompt";
import AlertExportTranscriptOptions from "@/ui/AlertExportTranscriptOptions";
import AlertSelect from "@/ui/AlertSelect";
import { getPlatform } from "@/models/platform";

const NativePage = ({
  onBackButtonPress,
  screenTitle,
  children,
  headerRight,
  HeaderComponent,
}: {
  onBackButtonPress?: () => void;
  screenTitle?: string;
  children: React.ReactNode;
  headerRight?: {
    icon?: IconType;
    text?: string;
    textStyle?: TextStyle;
    onPress?: () => void;
    iconStyle?: ImageStyle;
    containerStyle?: ViewStyle;
    Component?: React.ReactNode;
  }[];
  HeaderComponent?: React.ReactNode;
}) => {
  const { ToastContainer } = useToast();

  return (
    <View style={[s.container, { position: "fixed" } as any]}>
      <View style={s.innerContainer}>
        {HeaderComponent ? (
          HeaderComponent
        ) : (
          <View style={s.header}>
            {onBackButtonPress ? (
              <Pressable onPress={onBackButtonPress} style={s.backButton}>
                <Icon icon="arrowLeft" style={s.backIcon} />
              </Pressable>
            ) : (
              <View />
            )}

            <View style={s.titleContainer}>
              <Text numberOfLines={1} style={s.title}>
                {screenTitle}
              </Text>
            </View>

            <View style={s.rightHeaderButtonContainer}>
              {headerRight?.map((button, index) => {
                if (button.Component) {
                  return <View key={index}>{button.Component}</View>;
                }

                if (button.text) {
                  return (
                    <Text
                      onPress={button.onPress}
                      key={index}
                      style={StyleSheet.flatten([
                        s.headerRightText,
                        button.textStyle,
                      ])}
                    >
                      {button.text}
                    </Text>
                  );
                }

                return (
                  <Pressable
                    key={index}
                    style={StyleSheet.flatten([
                      {
                        marginLeft:
                          index === headerRight?.length - 1 ? 0 : ms(8),
                      },
                      button.containerStyle,
                    ])}
                    onPress={button.onPress}
                  >
                    <Icon
                      icon={button.icon}
                      style={StyleSheet.flatten([
                        {
                          width: ms(24),
                          height: ms(24),
                          tintColor: Colors.text,
                        },
                        button.iconStyle,
                      ])}
                    />
                  </Pressable>
                );
              })}
            </View>
          </View>
        )}

        <View style={s.content}>{children}</View>
      </View>
      <AlertModal />
      <AlertPrompt />
      <AlertExportTranscriptOptions />
      <AlertSelect />
    </View>
  );
};

NativePage.SCREEN_HEADER_HEIGHT = ms(50);
NativePage.TAB_BAR_HEIGHT = ms(70);

const s = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  },
  innerContainer: {
    flex: 1,
    marginTop: getPlatform() === "ios" ? -ms(10) : undefined,
  },
  header: {
    paddingHorizontal: ms(16),
    flexDirection: "row",
    alignItems: "center",
    height: NativePage.SCREEN_HEADER_HEIGHT,
    width: "100%",
    justifyContent: "space-between",
  },
  backButton: {
    height: NativePage.SCREEN_HEADER_HEIGHT,
    width: ms(50),
    alignItems: "center",
    marginLeft: -ms(16),
    justifyContent: "center",
  },
  backIcon: {
    width: ms(24),
    height: ms(24),
    tintColor: Colors.text,
  },
  titleContainer: {
    position: "absolute",
    width: "100%",
    alignItems: "center",
    left: 0,
    zIndex: -1,
  },
  title: {
    fontSize: ms(14),
    fontWeight: "500",
    color: Colors.text,
    width: "60%",
    textAlign: "center",
  },
  rightHeaderButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  content: {
    flex: 1,
    position: "relative",
  },
  tabBar: {
    backgroundColor: Colors.white,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    height: NativePage.TAB_BAR_HEIGHT,
    borderTopWidth: 1,
    borderTopColor: Colors.gray3,
    position: "relative",
    zIndex: 3,
    marginBottom: getPlatform() === "ios" ? -ms(10) : undefined,
  },
  tabButton: {
    alignItems: "center",
    justifyContent: "center",
    height: NativePage.TAB_BAR_HEIGHT,
    width: ms(48),
  },
  tabIcon: {
    width: ms(24),
    height: ms(24),
    tintColor: Colors.textSecondary,
  },
  addButton: {
    backgroundColor: Colors.accent,
    borderRadius: 100,
    height: ms(48),
    width: ms(48),
    alignItems: "center",
    justifyContent: "center",
  },
  addButtonIcon: {
    width: ms(24),
    height: ms(24),
    tintColor: Colors.white,
  },
  modalOverlay: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: Colors.white,
    borderTopLeftRadius: ms(16),
    borderTopRightRadius: ms(16),
    shadowColor: "#000",
    shadowOffset: { width: 0, height: ms(40) },
    shadowOpacity: 0.5,
    shadowRadius: ms(300),
    zIndex: 2,
  },
  headerRightText: {
    paddingVertical: ms(8),
    color: Colors.accentPrimary,
    fontWeight: "500",
    fontSize: ms(14),
  },
  modalContent: {
    paddingVertical: ms(16),
  },
  modalItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: ms(12),
    paddingHorizontal: ms(16),
  },
  modalIconContainer: {
    width: ms(40),
    height: ms(40),
    borderRadius: ms(20),
    backgroundColor: Colors.gray2,
    alignItems: "center",
    justifyContent: "center",
    marginRight: ms(16),
  },
  modalIcon: {
    width: ms(20),
    height: ms(20),
    tintColor: Colors.text,
  },
  modalTextContainer: {
    flex: 1,
  },
  modalTitle: {
    fontSize: ms(16),
    fontWeight: "500",
    color: Colors.text,
    marginBottom: ms(4),
  },
  modalSubtitle: {
    fontSize: ms(14),
    color: Colors.textSecondary,
  },
  modalBackdrop: {
    position: "absolute",
    top: -5000,
    left: 0,
    right: 0,
    bottom: 0,
    height: ms(10000),
    // backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1,
  },
  tabBarHeader: {
    paddingHorizontal: ms(16),
    height: ms(70),
    justifyContent: "center",
    marginTop: getPlatform() === "ios" ? -ms(5) : undefined,
  },
  tabBarHeaderContent: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  tabBarHeaderTitle: {
    fontSize: ms(24),
    fontWeight: "600",
  },
  tabBarHeaderActions: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: ms(4),
    marginRight: -ms(8),
  },
  tabBarHeaderAction: {
    // backgroundColor: Colors.gray3,
    width: ms(40),
    height: ms(40),
    alignItems: "center",
    justifyContent: "center",
    borderRadius: ms(100),
  },
  tabBarHeaderIcon: {
    width: ms(24),
    height: ms(24),
  },
  tabBarHeaderPremiumIcon: {
    tintColor: Colors.warning,
    width: ms(24),
    height: ms(24),
  },
  tabBarHeaderActionButton: {
    alignItems: "center",
  },
  tabBarHeaderActionButtons: {
    height: ms(64),
    backgroundColor: Colors.gray2,
    borderRadius: ms(40),
    marginBottom: ms(12),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  tabBarHeaderActionButtonText: {
    color: Colors.accent,
    fontSize: ms(10),
    marginTop: ms(2),
  },
  tabBarHeaderActionButtonIcon: {
    tintColor: Colors.accent,
    width: ms(20),
    height: ms(20),
  },
});

export default NativePage;

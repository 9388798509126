import { View, StyleSheet } from "react-native";
import { Colors, DESKTOP_MAX_WIDTH } from "@/constants";
import { getLangDir } from "@/models/utils";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import AlertSelect from "@/ui/AlertSelect";
import LandingPageContainer from "@/ui/LandingPageContainer";
import LandingPageFeatures from "@/ui/LandingPageFeatures";
import LandingHero from "@/ui/LandingHero";
import { datadogLogs } from "@datadog/browser-logs";
import { useEffect } from "react";
import { getPlatform } from "@/models/platform";
function WebApp() {
  const { t } = useTranslation();
  const platform = getPlatform();

  useEffect(() => {
    datadogLogs.logger.info("[TranscribeFunnel][1] - Page loaded", {
      platform,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("seo.title")}</title>
        <meta name="description" content={t("seo.description")} />
      </Helmet>

      <View
        style={{
          direction: getLangDir(),
          flex: 1,
          backgroundColor: Colors.white,
          zIndex: 1,
        }}
      >
        <LandingPageContainer>
          <View style={styles.container}>
            <LandingHero />
            <LandingPageFeatures />
          </View>
        </LandingPageContainer>
      </View>
      <AlertSelect />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: DESKTOP_MAX_WIDTH,
    marginHorizontal: "auto",
  },
});

export default WebApp;

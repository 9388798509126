import { View, Text, StyleSheet, Pressable } from "react-native";
import { ms } from "./moderateScale";
import { Colors } from "@/constants";
import { getBasePath, isMobileWindow } from "@/models/utils";
import { useTranslation } from "react-i18next";
import Menu from "./Menu";
import { formatDateTime } from "@/models/utils";
import Icon from "./Icon";
import { useRouter } from "@/_router";
import { useEffect } from "react";
import { useTranscript } from "@/models/useTranscript";
import { Transcript } from "@/models/types";

const isMobile = isMobileWindow();

export default function ListTranscript({
  EmptyComponent,
}: {
  EmptyComponent?: React.ReactNode;
}) {
  const { transcripts, listTranscripts, rename, remove } = useTranscript();

  const router = useRouter();
  const { t } = useTranslation();

  useEffect(() => {
    listTranscripts();
  }, []);

  const _viewTranscript = (id: string) => {
    router.push(`${getBasePath()}/transcribe?id=${id}`);
  };

  const getTranscriptDuration = (segments: Transcript["segments"]) => {
    if (!segments || segments.length === 0) {
      return 0;
    }
    return segments[segments.length - 1].end * 1000; // Convert to milliseconds
  };

  const formatDurationAsMinutesSeconds = (durationInMilliseconds: number) => {
    const totalSeconds = Math.floor(durationInMilliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    if (minutes === 0) {
      return `${seconds}s`;
    }

    return `${minutes}m ${seconds}s`;
  };

  if (transcripts.length === 0) {
    return (
      <View style={styles.emptyContainer}>
        {EmptyComponent || (
          <Text style={styles.emptyText}>{t("ui.emptyTranscripts")}</Text>
        )}
      </View>
    );
  }

  return (
    <View style={styles.listContainer}>
      {transcripts.map((item, index) => (
        <Pressable
          key={item.createdAt}
          onPress={() => _viewTranscript(item.id)}
          style={StyleSheet.flatten([
            styles.itemContainer,
            {
              borderTopLeftRadius: index === 0 ? ms(10) : 0,
              borderTopRightRadius: index === 0 ? ms(10) : 0,
              borderBottomLeftRadius:
                index === transcripts.length - 1 ? ms(10) : 0,
              borderBottomRightRadius:
                index === transcripts.length - 1 ? ms(10) : 0,
            },
            isMobile && {
              paddingLeft: ms(16),
              paddingRight: ms(4),
              height: ms(64),
            },
          ])}
        >
          <Text
            numberOfLines={1}
            style={StyleSheet.flatten([
              styles.itemText,
              { flex: 3 },
              isMobile && { fontSize: ms(15) },
              styles.nameText,
            ])}
          >
            {item.fileName}
          </Text>

          <Menu
            actionComponent={
              <View
                style={StyleSheet.flatten([
                  styles.itemIconContainer,
                  isMobile && { width: ms(56) },
                ])}
              >
                <Icon
                  icon={isMobile ? "moreHorizontal" : "moreVertical"}
                  style={styles.icon}
                />
              </View>
            }
            actions={[
              {
                text: t("ui.viewTranscriptButton"),
                onPress: () => _viewTranscript(item.id),
              },
              {
                text: t("ui.renameButton"),
                onPress: () => rename(item.id),
              },
              {
                text: t("ui.deleteButton"),
                destructive: true,
                onPress: () => remove(item.id),
              },
            ]}
          />

          {!isMobile && (
            <>
              <Text
                style={StyleSheet.flatten([
                  styles.itemText,
                  {
                    flex: 1,
                    textAlign: "left",
                    fontSize: ms(14),
                  },
                  isMobile && { fontSize: ms(13) },
                  styles.durationText,
                ])}
              >
                {formatDurationAsMinutesSeconds(
                  getTranscriptDuration(item.segments)
                )}
              </Text>

              <Text
                style={StyleSheet.flatten([
                  styles.itemText,
                  {
                    flex: 2,
                    textAlign: "left",
                    fontSize: ms(14),
                  },
                  isMobile && { fontSize: ms(13) },
                  styles.createdAtText,
                ])}
              >
                {formatDateTime(item.createdAt)}
              </Text>
            </>
          )}

          {index !== transcripts.length - 1 && (
            <View style={styles.separator} />
          )}
        </Pressable>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  listContainer: {
    overflow: "hidden",
    backgroundColor: Colors.white,
  },
  itemContainer: {
    height: ms(56),
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Colors.white,
    // paddingHorizontal: ms(15),
    position: "relative",
  },
  itemText: {
    color: Colors.text,
    fontSize: ms(16),
    flexGrow: 1,
    fontWeight: "400",
  },
  itemIconContainer: {
    marginLeft: ms(12),
    width: ms(40),
    height: ms(40),
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    tintColor: Colors.gray8,
    width: ms(20),
    height: ms(20),
  },
  emptyContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: ms(80),
    backgroundColor: Colors.white,
    borderRadius: ms(10),
    marginTop: ms(10),
    padding: ms(30),
  },
  emptyText: {
    color: Colors.gray6,
    fontSize: ms(16),
    textAlign: "center",
  },
  separator: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    height: StyleSheet.hairlineWidth,
    backgroundColor: Colors.gray2,
  },
  nameText: {
    fontWeight: "400",
    color: Colors.text,
    fontSize: ms(16),
  },
  durationText: {
    fontWeight: "400",
    color: Colors.gray6,
    fontSize: ms(12),
    marginHorizontal: ms(8),
  },
  createdAtText: {
    fontWeight: "400",
    color: Colors.gray6,
    letterSpacing: 0.2,
    fontSize: ms(12),
  },
});

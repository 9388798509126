import { View, StyleSheet, ScrollView, Text, Dimensions } from "react-native";
import { Colors, DESKTOP_MAX_WIDTH } from "@/constants";
import { ms } from "@/ui/moderateScale";
import { getLangDir } from "@/models/utils";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import AlertSelect from "@/ui/AlertSelect";
import LandingPageContainer from "@/ui/LandingPageContainer";
import { useTranscript } from "@/models/useTranscript";
import { useEffect } from "react";
import { isMobileWindow } from "@/models/utils";
import ListTranscript from "@/ui/ListTranscript";

const WIDTH = Dimensions.get("window").width;
const isMobile = isMobileWindow();

function Transcripts() {
  const { t } = useTranslation();
  const { listTranscripts } = useTranscript();

  useEffect(() => {
    listTranscripts();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("seo.title")}</title>
        <meta name="description" content={t("seo.description")} />
      </Helmet>

      <View
        style={{
          direction: getLangDir(),
          flex: 1,
          backgroundColor: Colors.white,
          zIndex: 1,
        }}
      >
        <LandingPageContainer
          hideLanguageSelector={isMobile}
          withBorder={isMobile}
          bgColor={Colors.gray3}
        >
          <View style={styles.container}>
            <ScrollView
              style={isMobile ? styles.mobileScrollView : styles.scrollView}
              showsVerticalScrollIndicator={false}
            >
              <Text
                style={StyleSheet.flatten([
                  styles.title,
                  isMobile && {
                    paddingLeft: ms(16),
                    fontSize: ms(20),
                    marginTop: ms(20),
                    fontWeight: "500",
                  },
                ])}
              >
                {t("ui.transcripts")}
              </Text>
              <ListTranscript />
            </ScrollView>
          </View>
        </LandingPageContainer>
      </View>
      <AlertSelect />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: DESKTOP_MAX_WIDTH,
    marginHorizontal: "auto",
  },
  title: {
    fontSize: ms(24),
    fontWeight: "600",
    marginTop: ms(48),
    marginBottom: ms(16),
    color: Colors.text,
  },
  scrollView: {
    flex: 1,
    paddingTop: ms(16),
    marginTop: ms(48),
    width: Math.min(820, WIDTH),
    padding: ms(64),
    alignSelf: "center",
    backgroundColor: Colors.white,
    borderWidth: ms(1),
    borderColor: Colors.gray4,
    marginBottom: ms(32),
  },
  mobileScrollView: {
    flex: 1,
    width: WIDTH,
    alignSelf: "center",
    backgroundColor: Colors.white,
  },
});

export default Transcripts;

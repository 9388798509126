export * from "./Colors";

export const PRIVACY_POLICY =
  "https://docs.google.com/document/d/e/2PACX-1vQdFYIrFpqoz-1Jhd7UFkucjrA1eLiu72Cd6Zep7qX6DvHsfAD__BpEi7qlYs4HCMvrti3z02JxOhrF/pub";
export const TERMS_OF_USE =
  "https://docs.google.com/document/d/e/2PACX-1vQdFYIrFpqoz-1Jhd7UFkucjrA1eLiu72Cd6Zep7qX6DvHsfAD__BpEi7qlYs4HCMvrti3z02JxOhrF/pub";

export const ANDROID_APP_LINK =
  "https://play.google.com/store/apps/details?id=com.transcribe.audio.to.text";

export const APP_ID = "6705123880";
export const IOS_APP_LINK = `https://apps.apple.com/us/app/${APP_ID}`;

export const DESKTOP_MAX_WIDTH = 1024;

export const REVENUE_CAT_IOS_API_KEY = "appl_irSTXHHMlTkheHOLHYYamIHSnJT"; // 1transcribe
export const REVENUE_CAT_ANDROID_API_KEY = "goog_NmeMKcNQFojdpuRrbvTiDrszZQX"; // 1transcribe
// export const REVENUE_CAT_IOS_API_KEY = "appl_JeRKDZSUzpIYhskHqnqdhhcXyOL"; // anytranscribe
// export const REVENUE_CAT_ANDROID_API_KEY = "goog_YeeSXLMOrAUFGFkjvsGarFnXCMd"; // anytranscribe

export const SUPPORTED_MEDIA_FILE_EXTENSIONS = [
  ".3ga",
  ".webm",
  ".8svx",
  ".mts",
  ".m2ts",
  ".ts",
  ".aac",
  ".mov",
  ".ac3",
  ".mp2",
  ".aif",
  ".mp4",
  ".m4v",
  ".aiff",
  ".mxf",
  ".alac",
  ".amr",
  ".ape",
  ".au",
  ".dss",
  ".flac",
  ".flv",
  ".m4a",
  ".m4b",
  ".m4p",
  ".m4r",
  ".mp3",
  ".mpga",
  ".ogg",
  ".oga",
  ".mogg",
  ".opus",
  ".qcp",
  ".tta",
  ".voc",
  ".wav",
  ".wma",
  ".wv",
];

export const AUDIO_LANGUAGES: { value: string; label: string }[] = [
  { value: "en", label: "🇺🇸 English" },
  { value: "es", label: "🇪🇸 Español" },
  { value: "fr", label: "🇫🇷 Français" },
  { value: "pt", label: "🇵🇹 Português" },
  { value: "it", label: "🇮🇹 Italiano" },
  { value: "de", label: "🇩🇪 Deutsch" },
  { value: "nl", label: "🇳🇱 Nederlands" },
  { value: "ja", label: "🇯🇵 日本語" },
  { value: "ko", label: "🇰🇷 한국어" },
  { value: "ar", label: "🇸🇦 العربية" },
  { value: "zh", label: "🇨🇳 中文 (普通话)" },
  { value: "hi", label: "🇮🇳 हिन्दी" },
  { value: "ru", label: "🇷🇺 Русский" },
  { value: "tr", label: "🇹🇷 Türkçe" },
  { value: "pl", label: "🇵🇱 Polski" },
  { value: "vi", label: "🇻🇳 Tiếng Việt" },
  { value: "th", label: "🇹🇭 ไทย" },
  { value: "id", label: "🇮🇩 Bahasa Indonesia" },
  { value: "uk", label: "🇺🇦 Українська" },
  { value: "sv", label: "🇸🇪 Svenska" },
  { value: "ro", label: "🇷🇴 Română" },
  { value: "el", label: "🇬🇷 Ελληνικά" },
  { value: "cs", label: "🇨🇿 Čeština" },
  { value: "fi", label: "🇫🇮 Suomi" },
  { value: "da", label: "🇩🇰 Dansk" },
  { value: "no", label: "🇳🇴 Norsk" },
  { value: "he", label: "🇮🇱 עברית" },
  { value: "hu", label: "🇭🇺 Magyar" },
  { value: "bn", label: "🇧🇩 বাংলা" },
  { value: "ms", label: "🇲🇾 Bahasa Melayu" },
  { value: "ur", label: "🇵🇰 اردو" },
  { value: "fa", label: "🇮🇷 فارسی" },
  { value: "sw", label: "🇹🇿 Kiswahili" },
  { value: "tl", label: "🇵🇭 Tagalog" },
  { value: "ta", label: "🇮🇳 தமிழ்" },
  { value: "mr", label: "🇮🇳 मराठी" },
  { value: "te", label: "🇮🇳 తెలుగు" },
  { value: "jw", label: "🇮🇩 Basa Jawa" },
  { value: "gu", label: "🇮🇳 ગુજરાતી" },
  { value: "kn", label: "🇮🇳 ಕನ್ನಡ" },
  { value: "ml", label: "🇮🇳 മലയാളം" },
  { value: "si", label: "🇱🇰 සිංහල" },
  { value: "km", label: "🇰🇭 ភាសាខ្មែរ" },
  { value: "lo", label: "🇱🇦 ພາສາລາວ" },
  { value: "my", label: "🇲🇲 မြန်မာစာ" },
  { value: "ne", label: "🇳🇵 नेपाली" },
  { value: "pa", label: "🇵🇰 پنجابی" },
  { value: "sd", label: "🇵🇰 سنڌي" },
  { value: "af", label: "🇿🇦 Afrikaans" },
  { value: "am", label: "🇪🇹 አማርኛ" },
  { value: "as", label: "🇮🇳 অসমীয়া" },
  { value: "az", label: "🇦🇿 Azərbaycan dili" },
  { value: "ba", label: "🇷🇺 Башҡорт теле" },
  { value: "be", label: "🇧🇾 Беларуская" },
  { value: "bg", label: "🇧🇬 Български" },
  { value: "bo", label: "🇨🇳 བོད་ཡིག" },
  { value: "br", label: "🇫🇷 Brezhoneg" },
  { value: "bs", label: "🇧🇦 Bosanski" },
  { value: "ca", label: "🇪🇸 Català" },
  { value: "cy", label: "🏴󠁧󠁢󠁷󠁬󠁳󠁿 Cymraeg" },
  { value: "et", label: "🇪🇪 Eesti" },
  { value: "eu", label: "🇪🇸 Euskara" },
  { value: "fo", label: "🇫🇴 Føroyskt" },
  { value: "gl", label: "🇪🇸 Galego" },
  { value: "ha", label: "🇳🇬 Hausa" },
  { value: "hr", label: "🇭🇷 Hrvatski" },
  { value: "ht", label: "🇭🇹 Kreyòl Ayisyen" },
  { value: "hy", label: "🇦🇲 Հայերեն" },
  { value: "is", label: "🇮🇸 Íslenska" },
  { value: "ka", label: "🇬🇪 ქართული" },
  { value: "kk", label: "🇰🇿 Қазақ тілі" },
  { value: "la", label: "🇻🇦 Latina" },
  { value: "lb", label: "🇱🇺 Lëtzebuergesch" },
  { value: "ln", label: "🇨🇩 Lingála" },
  { value: "lt", label: "🇱🇹 Lietuvių" },
  { value: "lv", label: "🇱🇻 Latviešu" },
  { value: "mg", label: "🇲🇬 Malagasy" },
  { value: "mi", label: "🇳🇿 Māori" },
  { value: "mk", label: "🇲🇰 Македонски" },
  { value: "mn", label: "🇲🇳 Монгол" },
  { value: "mt", label: "🇲🇹 Malti" },
  { value: "nn", label: "🇳🇴 Nynorsk" },
  { value: "oc", label: "🇫🇷 Occitan" },
  { value: "ps", label: "🇦🇫 پښتو" },
  { value: "sa", label: "🇮🇳 संस्कृतम्" },
  { value: "sk", label: "🇸🇰 Slovenčina" },
  { value: "sl", label: "🇸🇮 Slovenščina" },
  { value: "sn", label: "🇿🇼 ChiShona" },
  { value: "so", label: "🇸🇴 Soomaali" },
  { value: "sq", label: "🇦🇱 Shqip" },
  { value: "sr", label: "🇷🇸 Српски" },
  { value: "su", label: "🇮🇩 Basa Sunda" },
  { value: "tg", label: "🇹🇯 Тоҷикӣ" },
  { value: "tk", label: "🇹🇲 Türkmen" },
  { value: "tt", label: "🇷🇺 Татар теле" },
  { value: "uz", label: "🇺🇿 Oʻzbek" },
  { value: "yi", label: "🇮🇱 ייִדיש" },
  { value: "yo", label: "🇳🇬 Yorùbá" },
];

export const __DEV__ =
  Boolean(process) && process.env.NODE_ENV === "development";

import { getLocale } from "@/models/utils";
import i18n from "i18next";
import { Device } from "@capacitor/device";
import { isNativeApp } from "@/models/platform";
import { initReactI18next } from "react-i18next";

import en from "./en.json";
import es from "./es.json";
import pt from "./pt.json";
import nl from "./nl.json";
import fr from "./fr.json";
import { AUDIO_LANGUAGES } from "@/constants";

const resources = {
  en: { translation: en },
  es: { translation: es },
  pt: { translation: pt },
  fr: { translation: fr },
  nl: { translation: nl },
};

const appLanguages = Object.keys(resources).map((item) =>
  AUDIO_LANGUAGES.find((lang) => lang.value === item)
);

if (!i18n.isInitialized && typeof window === "object") {
  if (isNativeApp()) {
    Device.getLanguageTag().then((languageCode) => {
      const deviceLang =
        localStorage.getItem("locale") || languageCode.value.slice(0, 2);

      i18n.use(initReactI18next).init({
        resources,
        lng: deviceLang,
        fallbackLng: "en",
        compatibilityJSON: "v4",
        interpolation: {
          escapeValue: false,
        },
      });
    });
  } else {
    let deviceLang = localStorage.getItem("locale") || getLocale();
    const urlLang = window.location.href.includes("?lang=")
      ? window.location.href.split("?lang=")[1]
      : null;
    if (urlLang) {
      deviceLang = urlLang.slice(0, 2);
      localStorage.setItem("locale", deviceLang);
    }

    i18n.use(initReactI18next).init({
      resources,
      lng: deviceLang,
      fallbackLng: "en",
      compatibilityJSON: "v4",
      interpolation: {
        escapeValue: false,
      },
    });
  }
}

export { i18n, appLanguages };

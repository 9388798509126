import { parseJwt } from "@/models/serverClient";
import { useUserSlice } from "@/models/useUserSlice";
import { version } from "../../package.json";
import posthog from "posthog-js";
import { isNativeApp } from "@/models/platform";

export const initPostHog = () => {
  if (__DEV__ || isNativeApp()) return;

  try {
    const userToken = useUserSlice.getState().userToken;

    if (!userToken) return;

    const authUser = parseJwt(userToken);

    if (!authUser?.userId) return;

    posthog.identify(authUser?.userId, {
      email: authUser?.email,
      ip: authUser?.ip,
      country: authUser?.country,
      appVersion: version,
    });
  } catch (error) {
    console.error("Error initializing PostHog", error);
  }
};

export const logger = {
  error: (message: string, error?: Record<string, any>) => {
    if (__DEV__ || isNativeApp()) return;

    posthog.captureException(new Error(message), {
      message,
      error,
    });
  },
  info: (message: string, properties?: Record<string, any>) => {
    if (__DEV__ || isNativeApp()) return;

    posthog.capture(message, properties);
  },
};

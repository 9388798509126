import { View, Text, StyleSheet, Pressable, Image } from "react-native";
import { useRouter } from "@/_router";
import { contactUs, isMobileWindow } from "@/models/utils";
import { useTranslation } from "react-i18next";
import Menu from "./Menu";
import { ms } from "./moderateScale";
import { Colors, DESKTOP_MAX_WIDTH } from "@/constants";
import { getBasePath } from "@/models/utils";
import { appLanguages } from "@/locales";
import { useAuth } from "@/models/useAuth";
import { useUserSlice } from "@/models/useUserSlice";
import WebPaywall from "@/ui/WebPaywall";
import { useEffect } from "react";
import Icon from "./Icon";

const isMobile = isMobileWindow();

const LandingPageHeader = ({
  onLogoPress,
  hideLanguageSelector,
  hideLogoTitle,
  withBorder,
}: {
  onLogoPress?: () => void;
  hideLanguageSelector?: boolean;
  hideLogoTitle?: boolean;
  withBorder?: boolean;
}) => {
  const router = useRouter();
  const { t, i18n } = useTranslation();
  const { signIn, signOut, currentAuthUser } = useAuth();
  const isLoggedIn = useUserSlice((state) => state.userToken);
  const isSubscribed = useUserSlice((state) => state.isSubscribed);

  useEffect(() => {
    if (isLoggedIn) {
      WebPaywall.hasActiveSubscription(true);
    }
  }, []);

  const handleLanguageChange = (langCode: string) => {
    localStorage.setItem("locale", langCode);
    i18n.changeLanguage(langCode);
  };

  const getCurrentLanguageLabel = () => {
    return (
      appLanguages.find((lang) => lang.value === i18n.language)?.label ||
      "English"
    );
  };

  const languageActions = appLanguages.map((lang) => ({
    text: lang.label,
    onPress: () => handleLanguageChange(lang.value),
  }));

  const _onSignIn = () => {
    signIn({
      onGoogleSignIn: () => {},
    });
  };

  const _onCheckout = () => {
    signIn({
      onGoogleSignIn: () => WebPaywall.openCheckoutLink("MONTHLY_LOW_TIER"),
      callback: () => WebPaywall.openCheckoutLink("MONTHLY_LOW_TIER"),
    });
  };

  const _onBilling = () => {
    WebPaywall.getManageSubscriptionLink().then((url) => {
      if (url) {
        window.open(url, "_self");
      }
    });
  };

  const navItems = [];

  if (isLoggedIn) {
    navItems.push({
      label: t("ui.transcripts"),
      onPress: () => router.push(`${getBasePath()}/transcripts`),
      href: `${getBasePath()}/transcripts`,
      color: Colors.text,
    });
  } else {
    navItems.push({
      label: t("ui.subscribe"),
      onPress: _onCheckout,
      href: `${getBasePath()}/subscribe`,
      color: Colors.text,
    });
    navItems.push({
      label: t("ui.login"),
      onPress: _onSignIn,
      href: `${getBasePath()}/auth`,
      color: Colors.text,
    });
  }

  const LanguageSelector = () => (
    <Menu
      actionComponent={
        <View style={styles.languageButton}>
          <Text style={styles.languageButtonText}>
            {getCurrentLanguageLabel()}
          </Text>
          <Text style={styles.dropdownArrow}>▼</Text>
        </View>
      }
      actions={languageActions}
      position="bottom right"
    />
  );

  const ProfileMenu = () => (
    <Menu
      key={`${isSubscribed}`}
      actionComponent={
        isMobile ? (
          <View style={styles.menuButton}>
            <Icon
              icon="profile"
              style={{ tintColor: undefined, width: ms(30), height: ms(30) }}
            />
          </View>
        ) : (
          <View style={styles.languageButton}>
            <Text style={styles.languageButtonText}>
              {currentAuthUser()?.email}
            </Text>
            <Text style={styles.dropdownArrow}>▼</Text>
          </View>
        )
      }
      actions={[
        {
          text: t("ui.transcripts"),
          onPress: () => router.push(`${getBasePath()}/transcripts`),
        },
        {
          text: isSubscribed ? t("ui.billing") : t("ui.subscribe"),
          onPress: isSubscribed ? _onBilling : _onCheckout,
        },
        {
          text: t("ui.support"),
          onPress: contactUs,
        },
        {
          text: t("ui.logout"),
          onPress: signOut,
          destructive: true,
        },
      ]}
      position="bottom right"
    />
  );

  return (
    <View style={{ backgroundColor: Colors.white }}>
      <View
        style={StyleSheet.flatten([
          styles.header,
          isMobile && styles.headerMobile,
          withBorder && {
            borderBottomWidth: StyleSheet.hairlineWidth,
            borderColor: Colors.gray2,
          },
        ])}
      >
        <Pressable
          style={styles.logoContainer}
          onPress={onLogoPress || (() => router.push("/"))}
        >
          <View
            style={{
              width: ms(32),
              height: ms(32),
              borderRadius: ms(8),
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              source={{ uri: "/logo2.png" }}
              style={StyleSheet.flatten([
                styles.logoImage,
                isMobile && { width: ms(26), height: ms(26) },
              ])}
            />
          </View>
          {!hideLogoTitle && (
            <Text
              style={StyleSheet.flatten([
                styles.logo,
                isMobile && { fontSize: ms(16) },
              ])}
            >
              1Transcribe
            </Text>
          )}
        </Pressable>

        {isMobile ? (
          <View style={styles.mobileControls}>
            {!hideLanguageSelector && !isLoggedIn && <LanguageSelector />}
            {isLoggedIn && <ProfileMenu />}
            {!isLoggedIn && (
              <Menu
                actionComponent={
                  <View style={styles.menuButton}>
                    <Icon
                      icon="hamburger"
                      style={{
                        tintColor: Colors.text,
                        width: ms(28),
                        height: ms(28),
                      }}
                    />
                  </View>
                }
                actions={navItems.map((item) => ({
                  text: item.label,
                  onPress: item.onPress,
                  destructive: item.label === t("ui.logout"),
                }))}
              />
            )}
          </View>
        ) : (
          <View style={styles.navContainer}>
            {navItems.map((item, index) => (
              <Pressable
                key={index}
                style={styles.navItem}
                onPress={item.onPress}
              >
                <a
                  href={item.href}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Text
                    style={StyleSheet.flatten([
                      styles.navText,
                      item.color && { color: item.color },
                    ])}
                  >
                    {item.label}
                  </Text>
                </a>
              </Pressable>
            ))}
            {!hideLanguageSelector && !isLoggedIn && <LanguageSelector />}
            {isLoggedIn && <ProfileMenu />}
          </View>
        )}
      </View>
    </View>
  );
};

LandingPageHeader.HEIGHT = ms(64);

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: ms(16),
    height: LandingPageHeader.HEIGHT,
    maxWidth: DESKTOP_MAX_WIDTH,
    marginHorizontal: "auto",
    width: "100%",
  },
  headerMobile: {
    paddingHorizontal: ms(16),
  },
  logoContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: ms(8),
  },
  logo: {
    fontSize: ms(20),
    fontWeight: "600",
  },
  logoImage: {
    resizeMode: "contain",
    width: ms(28),
    height: ms(28),
    borderRadius: 0,
  },
  navContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: ms(16),
  },
  navItem: {
    padding: ms(8),
  },
  navText: {
    fontSize: ms(16),
  },
  mobileControls: {
    flexDirection: "row",
    alignItems: "center",
    gap: ms(8),
  },
  menuButton: {
    padding: ms(8),
    paddingRight: 0,
  },
  menuButtonText: {
    fontSize: ms(24),
    marginTop: ms(-4),
  },
  languageButton: {
    flexDirection: "row",
    alignItems: "center",
    padding: ms(8),
    borderRadius: ms(4),
    gap: ms(8),
  },
  languageButtonText: {
    fontSize: ms(16),
    color: Colors.text,
  },
  dropdownArrow: {
    fontSize: ms(12),
    color: Colors.text,
  },
});

export default LandingPageHeader;

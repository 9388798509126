import React from "react";
import { useAudioPlayer as useBaseAudioPlayer } from "react-use-audio-player";
import { fileExists } from "./utils";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export const useAudioPlayer = (url: string) => {
  const {
    load,
    pause,
    play: basePlay,
    getPosition,
    duration,
    seek,
    playing: isPlaying,
    rate,
    setRate,
  } = useBaseAudioPlayer();
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState<boolean>(true);
  const { t } = useTranslation();
  const [position, setPosition] = React.useState(0);

  // Load the audio when URL changes
  React.useEffect(() => {
    const loadAudio = async () => {
      if (!url) return setHasError(true);

      const exists = await fileExists(url);
      if (!exists) return setHasError(true);

      setIsLoading(true);
      // The format will be automatically detected from the file extension
      load(url, {
        autoplay: false,
        html5: true,
        onload: () => {
          setIsLoading(false);
          setHasError(false);
        },
      });
    };

    loadAudio();

    return () => {
      pause();
      seek(0);
      setPosition(0);
    };
  }, [url, load]);

  // Update position every second when playing and handle end of audio
  React.useEffect(() => {
    let interval: NodeJS.Timeout;

    if (isPlaying) {
      interval = setInterval(() => {
        const currentPosition = getPosition();
        setPosition(currentPosition);

        // Check if we've reached the end
        if (currentPosition >= (duration || 0)) {
          pause();
          seek(0);
          setPosition(0);
        }
      }, 500);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isPlaying, getPosition, duration, pause, seek]);

  // Wrapper for play to handle loading state
  const play = React.useCallback(() => {
    if (hasError) return;

    if (!isLoading) {
      basePlay();
    }
  }, [isLoading, basePlay, hasError]);

  // Seek to a specific position in seconds
  const seekTo = React.useCallback(
    (seconds: number) => {
      if (!isLoading && seconds >= 0 && seconds <= (duration || 0)) {
        seek(seconds);
        setPosition(seconds);
      }
    },
    [isLoading, duration, seek]
  );

  return {
    isLoading,
    play,
    pause,
    position,
    duration: duration || 0,
    hasError,
    seekTo,
    isPlaying,
    rate,
    setRate,
  };
};

export default useAudioPlayer;

import { useCallback } from "react";
import { getPlatform } from "@/models/platform";
import { FilePicker } from "@capawesome/capacitor-file-picker";

const useFileSelector = () => {
  const openFilePicker = useCallback(
    async (supportedExtensions?: string[]): Promise<File | null> => {
      return new Promise(async (resolve) => {
        if (getPlatform() === "ios") {
          try {
            const result = await FilePicker.pickFiles({
              readData: true,
              limit: 1,
            });

            if (result.files.length > 0) {
              const fileData = result.files[0];
              // Convert the picked file data to a File object
              const file = new File(
                [Uint8Array.from(atob(fileData.data!), (c) => c.charCodeAt(0))],
                fileData.name,
                { type: fileData.mimeType }
              );
              resolve(file);
            } else {
              resolve(null);
            }
          } catch (error) {
            console.error("Error picking file:", error);
            resolve(null);
          }
          return;
        }

        // Always create a new input element
        const input = document.createElement("input");
        input.type = "file";
        if (supportedExtensions?.length) {
          input.accept = supportedExtensions.join(",");
        }
        input.style.display = "none";

        const handleChange = (event: Event) => {
          const input = event.target as HTMLInputElement;
          const file = input.files?.[0] || null;
          input.value = ""; // Reset the input value
          input.removeEventListener("change", handleChange);
          document.body.removeChild(input);
          resolve(file);
        };

        input.addEventListener("change", handleChange);
        document.body.appendChild(input);
        input.click();
      });
    },
    []
  );

  return {
    openFilePicker,
  };
};

export default useFileSelector;

import {
  View,
  StyleSheet,
  Text,
  Pressable,
  ActivityIndicator,
} from "react-native";
import Icon from "@/ui/Icon";
import { Colors } from "@/constants";
import PlayerProgress from "@/ui/PlayerProgress";
import { useUISlice } from "@/models/useUISlice";
import { getLangDir } from "@/models/utils";
import LandingPageHeader from "./LandingePageHeader";
import { ms } from "./moderateScale";

const isRTL = getLangDir() === "rtl";

const AudioPlayer = ({
  duration,
  _pauseOrPlay,
  _seekPrevious,
  _seekNext,
  _setRate,
  _seekPosition,
  isPlaying,
  rate,
  position,
  loading,
}: {
  duration: number;
  _pauseOrPlay: () => void;
  _seekPrevious: () => void;
  _seekNext: () => void;
  _setRate: () => void;
  _seekPosition: (position: number) => void;
  isPlaying: boolean;
  rate: number;
  position: number;
  loading: boolean;
}) => {
  const isMobile = useUISlice((state) => state.device.isMobile);

  return (
    <View style={{ direction: "ltr" }}>
      <View style={{ ...s.container }}>
        <View style={s.buttonContainer}>
          <Pressable onPress={_pauseOrPlay} disabled={loading} style={s.button}>
            {loading && (
              <ActivityIndicator color={Colors.accent} size={"small"} />
            )}

            {!loading && (
              <Icon
                disabled={loading}
                icon={isPlaying ? "pause" : "play"}
                style={{
                  width: ms(30),
                  height: ms(30),
                  tintColor: Colors.text,
                }}
              />
            )}
          </Pressable>

          <Pressable
            onPress={_seekPrevious}
            disabled={loading}
            style={s.button}
          >
            <Icon
              icon="timeBackward"
              style={{ width: ms(20), height: ms(20), tintColor: Colors.text }}
            />
          </Pressable>

          <Pressable onPress={_seekNext} disabled={loading} style={s.button}>
            <Icon
              icon="timeForward"
              style={{ width: ms(20), height: ms(20), tintColor: Colors.text }}
            />
          </Pressable>

          <Pressable onPress={_setRate} disabled={loading} style={s.button}>
            <Text style={s.rateText}>{rate}X</Text>
          </Pressable>
        </View>

        {!isMobile && (
          <View style={{ flexGrow: 1 }}>
            <PlayerProgress
              onPositionChange={_seekPosition}
              position={position}
              duration={duration}
            />
          </View>
        )}
      </View>
    </View>
  );
};

AudioPlayer.HEIGHT = LandingPageHeader.HEIGHT;

const s = StyleSheet.create({
  container: {
    height: AudioPlayer.HEIGHT,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  button: {
    width: AudioPlayer.HEIGHT - ms(4),
    height: AudioPlayer.HEIGHT - ms(4),
    alignItems: "center",
    justifyContent: "center",
    color: Colors.text,
  },
  rateText: {
    fontWeight: "500",
    color: Colors.text,
    fontSize: ms(18),
  },
});

export default AudioPlayer;

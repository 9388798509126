import { useRouter } from "@/_router";
import * as serverClient from "@/models/serverClient";
import * as fbq from "@/fetchData/fpixel";
import * as gtag from "@/fetchData/gtag";
import { SubscriptionPlan } from "@/models/types";
import { useUISlice } from "@/models/useUISlice";
import { datadogLogs } from "@datadog/browser-logs";
import { i18n } from "@/locales";

import { useUserSlice } from "@/models/useUserSlice";
import { Pressable, View, Text, StyleSheet } from "react-native";
import { useTranslation } from "react-i18next";
import { ms } from "./moderateScale";
import { Colors } from "@/constants";
import { useAuth } from "@/models/useAuth";
import { getPlatform, isNativeApp } from "@/models/platform";

const platform = getPlatform();

const WebPaywall = () => {
  const { t } = useTranslation();
  const { currentAuthUser, signIn } = useAuth();
  const isSubscribed = useUserSlice((state) => state.isSubscribed);

  const showPaywall = () => {
    signIn({
      onGoogleSignIn: () => WebPaywall.openCheckoutLink("MONTHLY_LOW_TIER"),
      callback: () => WebPaywall.openCheckoutLink("MONTHLY_LOW_TIER"),
    });
  };

  const getPriceText = () => {
    const price = 9.99;
    let localPrice = price;
    let localSymbol = "$";

    // Prices for Latin American countries
    const countryCurrencies = {
      CO: { symbol: "COP", price: "39900" }, // 9.99 USD to COP
      GT: { symbol: "Q", price: "77.92" }, // 9.99 USD to GTQ
      ES: { symbol: "€", price: "9.19" }, // 9.99 USD to EUR
      BR: { symbol: "R$", price: "50.95" }, // 9.99 USD to BRL
      AR: { symbol: "ARS", price: "8950" }, // 9.99 USD to ARS
      PE: { symbol: "S/", price: "36.96" }, // 9.99 USD to PEN
      CL: { symbol: "CLP", price: "9450" }, // 9.99 USD to CLP
      EC: { symbol: "$", price: "9.99" }, // Ecuador uses USD
      HN: { symbol: "L", price: "246.75" }, // 9.99 USD to HNL
      MX: { symbol: "MX$", price: "167.83" }, // 9.99 USD to MXN
      PA: { symbol: "B/", price: "9.99" }, // Panama uses USD equivalent
      UR: { symbol: "$U", price: "384.62" }, // 9.99 USD to UYU
      CA: { symbol: "C$", price: "13.75" }, // 9.99 USD to CAD
    };

    let token = useUISlice.getState().guestToken;
    if (!token) {
      token = useUserSlice.getState().userToken;
    }

    const authUser = currentAuthUser(token);
    const countryCode = authUser?.country;
    if (!countryCode || !countryCurrencies[countryCode]) return `US$${price}`;

    localPrice = countryCurrencies[countryCode].price;
    localSymbol = countryCurrencies[countryCode].symbol;

    // Get user's country code - this would need to be implemented
    // For now, just return the USD and EUR prices
    return `US$${price} ≈ ${localSymbol}${localPrice}`;
  };

  if (isSubscribed) return null;

  return (
    <View style={styles.paywallContainer}>
      <Text style={styles.title}>{t("ui.paywallFooter.title")}</Text>
      <Text style={styles.subtitle}>{t("ui.paywallFooter.subtitle")}</Text>
      <Text
        style={{
          marginTop: ms(12),
          fontWeight: "600",
          fontSize: ms(16),
          color: Colors.gray4,
        }}
      >
        {t("ui.paywallFooter.price", {
          price: getPriceText(),
          period: i18n.t("ui.paywallFooter.perMonth"),
        })}
      </Text>

      <Pressable onPress={showPaywall} style={styles.button}>
        <Text style={styles.buttonText}>
          {t("ui.paywallFooter.buttonText", {
            price: getPriceText(),
          })}
        </Text>
      </Pressable>
    </View>
  );
};

WebPaywall.hasActiveSubscription = async (
  forceRefresh = false
): Promise<boolean> => {
  if (isNativeApp()) return useUserSlice.getState().isSubscribed;

  let isSubscribed = useUserSlice.getState().isSubscribed;

  if (!isSubscribed || forceRefresh) {
    isSubscribed = await serverClient
      .subscriptionAsync({ type: "status" })
      .then((res) => res?.data?.isActive);

    useUserSlice.getState().setIsSubscribed(isSubscribed);
  }

  return isSubscribed;
};

WebPaywall.openCheckoutLink = async (subscriptionPlan: SubscriptionPlan) => {
  const isSubscribed = await WebPaywall.hasActiveSubscription(true);

  if (isSubscribed) return;

  datadogLogs.logger.info("[TranscribeFunnel][9] - go to checkout", {
    platform,
  });
  const response = await serverClient.subscriptionAsync({
    type: "checkout",
    payload: { baseAppUrl: window.location.href, subscriptionPlan },
  });
  window.open(response?.data?.url, "_self");
};

WebPaywall.onCheckoutSuccess = (
  router: ReturnType<typeof useRouter>,
  retranscribeOriginalFile?: (id: string) => Promise<void>
) => {
  const slug = router.query.checkout_success;
  const id = router.query.id;

  if (slug === "true") {
    const planPrices = {
      MONTHLY_TOP_TIER: 19.99,
      MONTHLY_LOW_TIER: 9.99,
    };
    const value = planPrices[router.query.plan as SubscriptionPlan] || 9.99;

    datadogLogs.logger.info("[TranscribeFunnel][10] - payment success", {
      platform,
    });
    fbq.event("Subscribe", { currency: "USD", value: value.toString() });
    gtag.conversion({ value, transactionId: String(Date.now()) });

    useUISlice.getState().setAlertModal({
      title: i18n.t("ui.subscriptionSuccess"),
      subtitle: i18n.t("ui.subscriptionSuccessDescription"),
      hideDismissIcon: true,
      shouldPersistBackdropClick: true,
      button: {
        text: i18n.t("ui.subscriptionSuccessButton"),
        onPress: async () => {
          // Wait for 2 seconds to ensure backend has processed the subscription
          useUISlice.getState().setAlertModal({
            ...useUISlice.getState().alertModal,
            loading: true,
          });

          await new Promise((resolve) => setTimeout(resolve, 2000));
          useUISlice.getState().setAlertModal(undefined);

          if (id) {
            router.push(`${router.pathname}?id=${id}`);
          } else {
            router.push(router.pathname);
          }

          const isSubscribed = await WebPaywall.hasActiveSubscription(true);

          if (isSubscribed) {
            setTimeout(() => {
              retranscribeOriginalFile?.(id);
            }, 100);
          }
        },
      },
    });
  }
};

WebPaywall.getManageSubscriptionLink = async () => {
  const response = await serverClient.subscriptionAsync({
    type: "billingPortal",
    payload: { baseAppUrl: window.location.href },
  });
  return response?.data?.url;
};

const styles = StyleSheet.create({
  paywallContainer: {
    padding: ms(24),
    paddingHorizontal: ms(24),
    borderRadius: ms(8),
    backgroundColor: Colors.text,
  },
  title: {
    fontSize: ms(32),
    color: Colors.white,
    fontWeight: "700",
    marginBottom: ms(8),
  },
  subtitle: {
    fontSize: ms(16),
    color: Colors.gray4,
    lineHeight: ms(24),
  },
  button: {
    marginTop: ms(24),
    height: ms(56),
    backgroundColor: Colors.white,
    borderRadius: ms(8),
    alignItems: "center",
    justifyContent: "center",
  },
  buttonText: {
    fontSize: ms(18),
    fontWeight: "600",
    color: Colors.text,
  },
});

export default WebPaywall;

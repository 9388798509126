import { View, Text, StyleSheet, Pressable } from "react-native";
import { ms } from "@/ui/moderateScale";
import { Colors } from "@/constants";
import { useTranslation } from "react-i18next";
import Icon from "@/ui/Icon";
import { formatTime } from "@/models/useRecorder";

const Recorder = (props: {
  statusText: string;
  elapsedTime: number;
  status: "recording" | "paused" | "inactive";
  cancelRecording: () => void;
  stopRecording: () => void;
  pauseOrPlay: () => void;
  isRecording: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <View style={{ marginBottom: ms(64) }}>
      <View style={{ alignItems: "center" }}>
        <Text
          style={{
            ...s.statusText,
            color:
              props.status === "recording"
                ? Colors.error
                : Colors.textSecondary,
          }}
        >
          {props.statusText}
        </Text>
        <View style={s.timerContainer}>
          <Text style={s.timer}>{formatTime(props.elapsedTime)}</Text>
        </View>
      </View>

      <View style={s.buttonsContainer}>
        <Pressable
          disabled={props.status === "inactive"}
          style={s.sideButton}
          onPress={props.cancelRecording}
        >
          <Icon icon="close" style={s.sideButtonIcon} />
          <Text style={s.buttonLabel}>{t("ui.cancelButton")}</Text>
        </Pressable>

        <Pressable
          disabled={props.status === "inactive"}
          style={[s.recordButton, props.isRecording && s.recordingButton]}
          onPress={props.stopRecording}
        >
          <View style={s.recordingIndicator} />
        </Pressable>

        <Pressable
          disabled={props.status === "inactive"}
          style={s.sideButton}
          onPress={props.pauseOrPlay}
        >
          <Icon
            icon={
              props.isRecording || props.status === "inactive"
                ? "pause"
                : "play"
            }
            style={s.sideButtonIcon}
          />
          <Text style={s.buttonLabel}>
            {props.isRecording || props.status === "inactive"
              ? t("ui.pauseButton")
              : t("ui.resumeButton")}
          </Text>
        </Pressable>
      </View>
    </View>
  );
};

const s = StyleSheet.create({
  leftContainer: {
    flex: 1,
    padding: ms(16),
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: -ms(8),
    height: ms(40),
    marginBottom: ms(8),
    position: "relative",
  },
  title: {
    fontSize: ms(14),
    fontWeight: "500",
    color: Colors.text,
  },
  statusText: {
    fontSize: ms(12),
    letterSpacing: 1,
    fontWeight: "400",
    color: Colors.text,
    marginBottom: ms(16),
  },
  timerContainer: {
    alignItems: "center",
    marginBottom: ms(48),
  },
  timer: {
    fontSize: ms(48),
    fontWeight: "400",
    color: Colors.text,
    letterSpacing: -1,
  },
  buttonsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: ms(32),
  },
  sideButton: {
    alignItems: "center",
    justifyContent: "center",
    width: ms(60),
  },
  sideButtonIcon: {
    width: ms(24),
    height: ms(24),
    tintColor: Colors.text,
  },
  buttonLabel: {
    marginTop: ms(8),
    fontSize: ms(12),
    color: Colors.text,
  },
  recordButton: {
    width: ms(64),
    height: ms(64),
    borderRadius: ms(32),
    backgroundColor: Colors.error,
    alignItems: "center",
    justifyContent: "center",
  },
  recordingButton: {
    backgroundColor: Colors.error,
  },
  recordingIndicator: {
    width: ms(32),
    height: ms(32),
    borderRadius: ms(6),
    backgroundColor: Colors.white,
  },
  rightContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  welcomeText: {
    fontSize: ms(24),
    fontWeight: "600",
    color: Colors.text,
    marginBottom: ms(8),
  },
  subText: {
    fontSize: ms(16),
    color: Colors.textSecondary,
  },
  backButton: {
    position: "absolute",
    left: -ms(16),
    height: ms(50),
    width: ms(50),
    alignItems: "center",
    justifyContent: "center",
  },
  backIcon: {
    width: ms(24),
    height: ms(24),
    tintColor: Colors.text,
  },
});

export default Recorder;

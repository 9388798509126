import { useState } from "react";
import { Image, Pressable, View } from "react-native";
import { ImageStyle, ViewStyle } from "react-native";
import { ms } from "@/ui/moderateScale";
import { Colors } from "@/constants/Colors";
import { isNativeApp } from "@/models/platform";

export const icons = {
  searchBar: "search_bar.svg",
  premium: "premium.svg",
  home: "home.svg",
  folderAdd: "folder_add.svg",
  downloadAndroid: "download_android.svg",
  downloadIos: "download_ios.svg",
  folder: "folder.svg",
  close: "close.svg",
  filter: "filter.svg",
  timeBackward: "time_backward.png",
  timeForward: "time_forward.png",
  profile: "profile.png",
  prompt: "prompt.svg",
  help: "help.svg",
  video: "video.svg",
  clock: "clock.svg",
  chat: "chat.svg",
  gift: "gift.svg",
  plus: "plus.svg",
  hamburger: "hamburger.svg",
  cog: "cog.svg",
  mic: "mic.svg",
  import: "import.svg",
  wave: "wave.svg",
  arrowLeft: "arrow_left.svg",
  download: "download.svg",
  export: "export.svg",
  play: "play.svg",
  pause: "pause.svg",
  moreVertical: "more_vertical.svg",
  moreHorizontal: "more_horizontal.svg",
  heart: "heart.svg",
  share: "share.svg",
  reload: "reload.svg",
  translate: "translate.svg",
  send: "send.svg",
  star: "star.svg",
  starFilled: "star_filled.svg",
  copy: "copy.svg",
  tick: "tick.svg",
  pen: "pen.svg",
  calendar: "calendar.svg",
  caretLeft: "caret_left.svg",
  email: "email.svg",
  google: "google.svg",
  apple: "apple.svg",
  logo: "logo.png",
  bin: "bin.svg",
  folderFilled: "folder.png",
};

export type IconType = keyof typeof icons;

const Icon = ({
  icon,
  onPress,
  style = {},
  containerStyle = {},
  disabled = false,
}: {
  icon: keyof typeof icons;
  disabled?: boolean;
  onPress?: () => void;
  style?: ImageStyle;
  containerStyle?: ViewStyle;
}) => {
  const [active, setActive] = useState(false);
  let baseUrl = "/icons";

  if (!onPress)
    return (
      <View style={containerStyle || {}}>
        <div
          style={{
            WebkitTouchCallout: "none",
            WebkitUserSelect: "none",
            KhtmlUserSelect: "none",
            MozUserSelect: "none",
            msUserSelect: "none",
            userSelect: "none",
            touchAction: "manipulation",
          }}
        >
          <Image
            source={{
              uri: `${baseUrl}/${icons[icon]}`,
            }}
            alt={icon}
            style={{ width: ms(24), height: ms(24), ...style }}
          />
        </div>
      </View>
    );

  return (
    <Pressable
      onPress={onPress}
      disabled={disabled}
      onHoverIn={() => setActive(true)}
      onHoverOut={() => setActive(false)}
      style={{
        backgroundColor: active ? Colors.text + "08" : "transparent",
        width: ms(48),
        height: ms(48),
        alignItems: "center",
        justifyContent: "center",
        borderRadius: ms(200),
        ...containerStyle,
      }}
    >
      <div
        style={{
          WebkitTouchCallout: "none",
          WebkitUserSelect: "none",
          KhtmlUserSelect: "none",
          MozUserSelect: "none",
          msUserSelect: "none",
          userSelect: "none",
          touchAction: "manipulation",
        }}
      >
        <Image
          source={{
            uri: `${baseUrl}/${icons[icon]}`,
          }}
          alt={icon}
          style={{ width: ms(24), height: ms(24), ...style }}
        />
      </div>
    </Pressable>
  );
};

export default Icon;

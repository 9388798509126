import { View, Text, StyleSheet, Pressable, Dimensions } from "react-native";
import { ms } from "@/ui/moderateScale";
import { Colors, DESKTOP_MAX_WIDTH } from "@/constants";
import { isMobileWindow } from "@/models/utils";
import { useTranslation } from "react-i18next";
import Icon from "./Icon";
import Recorder from "./Recorder";
import useCreateTranscription from "@/models/useCreateTranscription";

const isMobile = isMobileWindow();

const LandingHero = () => {
  const { t } = useTranslation();
  const {
    importFile,
    startRecordingAudio,
    stopRecordingAudio,
    cancelRecordingAudio,
    recorderVisible,
    status,
    elapsedTime,
    isRecording,
    pauseOrPlay,
    getRecordingStatusText,
  } = useCreateTranscription();

  return (
    <View style={styles.hero}>
      <Text
        accessibilityRole="header"
        aria-level={1}
        style={StyleSheet.flatten([
          styles.title,
          isMobile && styles.titleMobile,
        ])}
      >
        {t("landingPage.hero.title")}
      </Text>
      <Text
        accessibilityRole="header"
        aria-level={2}
        style={StyleSheet.flatten([
          styles.subtitle,
          isMobile && styles.subtitleMobile,
        ])}
      >
        {t("landingPage.hero.subtitle")}
      </Text>

      {!recorderVisible && (
        <View style={styles.buttonContainer}>
          <Pressable style={styles.button} onPress={importFile}>
            <Text style={styles.buttonText}>
              {t("landingPage.hero.button")}
            </Text>
          </Pressable>

          <Icon
            icon="mic"
            style={styles.micIcon}
            containerStyle={styles.micIconContainer}
            onPress={startRecordingAudio}
          />
        </View>
      )}

      {recorderVisible && (
        <View
          style={{
            backgroundColor: Colors.accentSecondary,
            marginTop: ms(32),
            borderRadius: ms(16),
            padding: ms(24),
            paddingBottom: 0,
            width: Math.min(ms(400), Dimensions.get("window").width - ms(32)),
            alignSelf: "center",
          }}
        >
          <Recorder
            statusText={getRecordingStatusText()}
            elapsedTime={elapsedTime}
            status={status}
            cancelRecording={cancelRecordingAudio}
            stopRecording={stopRecordingAudio}
            pauseOrPlay={pauseOrPlay}
            isRecording={isRecording}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  hero: {
    flex: 1,
    padding: ms(16),
    maxWidth: DESKTOP_MAX_WIDTH,
    marginHorizontal: "auto",
    width: "100%",
  },
  title: {
    marginTop: ms(60),
    fontWeight: "600",
    textAlign: "center",
    fontSize: ms(38),
    color: Colors.text,
  },
  titleMobile: {
    fontSize: ms(24),
  },
  subtitle: {
    alignSelf: "center",
    maxWidth: ms(500),
    lineHeight: ms(24),
    marginTop: ms(16),
    textAlign: "center",
    fontSize: ms(16),
    color: Colors.textSecondary,
  },
  subtitleMobile: {
    fontSize: ms(14),
    maxWidth: ms(300),
    lineHeight: ms(20),
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    marginTop: ms(24),
  },
  button: {
    alignSelf: "center",
    height: ms(64),
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxWidth: Math.max(ms(260), Dimensions.get("window").width * 0.7),
    backgroundColor: Colors.accentPrimary,
    borderRadius: ms(4),
  },
  buttonText: {
    color: Colors.white,
    fontWeight: "500",
    fontSize: ms(20),
  },
  micIcon: {
    tintColor: Colors.accent,
    width: ms(30),
    height: ms(30),
  },
  micIconContainer: {
    marginLeft: ms(16),
    backgroundColor: Colors.accentSecondary,
    borderRadius: ms(40),
    padding: ms(8),
    width: ms(64),
    height: ms(64),
  },
});

export default LandingHero;

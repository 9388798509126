export const GA_TRACKING_ID = import.meta.env.VITE_PUBLIC_GA_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  (window as any)?.gtag?.("config", GA_TRACKING_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  (window as any)?.gtag?.("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

export const conversion = ({ value, transactionId }) => {
  (window as any)?.gtag?.("event", "conversion", {
    send_to: "AW-318426315/GtFaCMau1u0ZEMuZ65cB",
    value,
    currency: "USD",
    transaction_id: transactionId,
  });
};

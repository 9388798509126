import { useUISlice } from "./useUISlice";
import { useUpload } from "./useUpload";
import * as serverClient from "./serverClient";
import { Transcript } from "./types";
import { useUserSlice } from "./useUserSlice";
import { requestReview } from "./useNative";
import { datadogLogs } from "@datadog/browser-logs";
import { getPlatform, isNativeApp } from "@/models/platform";
import { fileExists, getBasePath } from "@/models/utils";
import { useRouter } from "@/_router";
import { useAuth } from "@/models/useAuth";
import WebPaywall from "@/ui/WebPaywall";
import NativePaywall from "@/ui/NativePaywall";

export type Status =
  | "UPLOADING"
  | "TRIMMING"
  | "TRANSCRIBING"
  | "ERROR"
  | "DONE";

export function useTranscribe() {
  const router = useRouter();
  const platform = getPlatform();

  const { signIn } = useAuth();
  const { uploadFile, uploadProgress, setUploadParams } = useUpload({
    maxFileSize: 5 * 1024 * 1024 * 1024, // 5GB
  });

  const transcribeParams = useUISlice((state) => state?.transcribeParams);

  let _trimmingInterval: NodeJS.Timeout | undefined;

  const setStatus = (status: Status) => {
    useUISlice.getState().setTranscribeParams({
      ...useUISlice.getState().transcribeParams,
      status,
    });
  };

  const setProgress = (progress: number, reset?: boolean) => {
    const prevProgress = useUISlice.getState().transcribeParams?.progress || 0;

    useUISlice.getState().setTranscribeParams({
      ...useUISlice.getState().transcribeParams,
      progress: reset ? progress : Math.min(95, prevProgress + progress),
    });
  };

  const onUploadCompleted = async (params: {
    fileName: string;
    uploadedFileUrl: string;
    withSpeakerLabels?: boolean;
    originalFileUrl?: string;
    language: string;
    id: string;
    isReTranscribe?: boolean;
  }) => {
    let mobileSubscriptionStatus = undefined;
    if (isNativeApp()) {
      mobileSubscriptionStatus =
        await NativePaywall.getMobileSubscriptionStatus();
    }

    // 3. Guest transcribe file
    const transcribeResponse = await serverClient.transcribeAsync({
      type: "createAudioTranscript",
      fileUrl: params.uploadedFileUrl,
      mobileSubscriptionStatus,
      fileName: params.fileName,
      language: params.language,
      withSpeakerLabels: params.withSpeakerLabels,
      authToken: useUserSlice.getState().userToken,
    });

    datadogLogs.logger.info(
      "[TranscribeFunnel][6] - guest transcribe started",
      { isNative: isNativeApp(), transcriptId: params.id, platform }
    );

    if (transcribeResponse.error) return setStatus("ERROR");

    setStatus("TRANSCRIBING");
    clearInterval(_trimmingInterval);
    setProgress(5, true);

    const setTimeoutId = setTimeout(
      () => {
        clearInterval(intervalId);
        setStatus("ERROR");
        clearTimeout(setTimeoutId);
      },
      1000 * 60 * 5
    );

    const intervalId = setInterval(async () => {
      setProgress(5);

      const transcriptResponse = await serverClient.transcribeAsync({
        type: "status",
        pendingTranscript: transcribeResponse.data?.pendingTranscript,
      });

      if (transcriptResponse.error) {
        clearInterval(intervalId);
        setStatus("ERROR");
      }

      if (transcriptResponse.data?.transcript) {
        datadogLogs.logger.info(
          "[TranscribeFunnel][7] - guest transcribe completed",
          { isNative: isNativeApp(), transcriptId: params.id, platform }
        );

        const newTranscript: Transcript = {
          ...transcriptResponse.data.transcript,
          createdAt: new Date().toISOString(),
          originalFileUrl: params?.originalFileUrl,
          fileName: params.fileName,
          id: params.id,
        };

        useUserSlice
          .getState()
          .setTranscriptionLanguage(
            transcriptResponse.data.transcript.language
          );

        useUserSlice.getState().createTranscript(newTranscript);

        clearInterval(intervalId);
        setStatus("DONE");
        router.replace(`${getBasePath()}/transcribe?id=${params.id}`);
        clearTimeout(setTimeoutId);
        useUISlice.getState().setTranscribeParams(undefined);

        setTimeout(() => {
          requestReview();
        }, 1000);
      }
    }, 3000);
  };

  const runTranscribe = async () => {
    const getUploadedFileUrl = async (): Promise<string | undefined> => {
      const getGuestToken = async (): Promise<string | undefined> => {
        const guestTokenResponse = await serverClient.authenticateAsync({
          type: "OnGuestSignIn",
        });
        const guestToken = guestTokenResponse?.data?.accessToken;

        if (!guestToken) return;

        useUISlice.getState().setGuestToken(guestToken);

        return guestToken;
      };

      let token = useUserSlice.getState().userToken;
      if (!token) {
        token = await getGuestToken();
      }

      if (!token) return;

      const fileExtension =
        transcribeParams?.file?.name?.split(".").pop() || "";
      setUploadParams({
        fileType: transcribeParams?.file?.type,
        fileExtension,
      });

      const uploadResponse = await uploadFile(transcribeParams?.file);

      if (!uploadResponse.success) return;

      return uploadResponse.downloadUrl;
    };

    if (!transcribeParams?.file || !transcribeParams?.language) {
      return setStatus("ERROR");
    }

    datadogLogs.logger.info("[TranscribeFunnel][4] - upload started", {
      isNative: isNativeApp(),
      platform,
    });

    const uploadedFileUrl = await getUploadedFileUrl();
    if (!uploadedFileUrl) return setStatus("ERROR");

    datadogLogs.logger.info("[TranscribeFunnel][5] - upload completed", {
      isNative: isNativeApp(),
      platform,
    });

    setStatus("TRIMMING");

    // Add countdown timer for TRIMMING status
    let countdown = 60;
    setProgress(5, true);
    _trimmingInterval = setInterval(() => {
      countdown--;
      setProgress(Math.min(95, 5));
    }, 1000);

    // 3. Guest transcribe file
    onUploadCompleted({
      fileName: transcribeParams?.file.name.split(".")[0],
      uploadedFileUrl,
      language: transcribeParams?.language,
      id: new Date().getTime().toString(),
      originalFileUrl: uploadedFileUrl,
    });
  };

  const retranscribeWithSpeakerLabels = (id: string) => {
    if (useUserSlice.getState().isSubscribed) {
      datadogLogs.logger.info(
        "[TranscribeFunnel][8] - retranscribe with speaker labels",
        { isNative: isNativeApp(), platform }
      );

      const transcript = useUserSlice
        .getState()
        .transcripts.find((t) => t.id === id);

      if (!transcript) return;

      setProgress(0, true);

      return onUploadCompleted({
        ...transcript,
        withSpeakerLabels: true,
        uploadedFileUrl: transcript.originalFileUrl || transcript.audioUrl,
        isReTranscribe: true,
      });
    }

    if (isNativeApp()) {
      NativePaywall.handlePurchase({
        router,
        retranscribeOriginalFile,
      });
    } else {
      signIn({
        onGoogleSignIn: () => WebPaywall.openCheckoutLink("MONTHLY_LOW_TIER"),
        callback: () => WebPaywall.openCheckoutLink("MONTHLY_LOW_TIER"),
      });
    }
  };

  const retranscribeOriginalFile = async (id: string) => {
    datadogLogs.logger.info(
      "[TranscribeFunnel][8] - retranscribe original file",
      { isNative: isNativeApp(), platform }
    );

    const transcript = useUserSlice
      .getState()
      .transcripts.find((t) => t.id === id);

    if (!transcript || !transcript.originalFileUrl) return;

    if (!(await fileExists(transcript.originalFileUrl))) return;

    setProgress(0, true);

    return onUploadCompleted({
      ...transcript,
      uploadedFileUrl: transcript.originalFileUrl,
      isReTranscribe: true,
    });
  };

  return {
    uploadProgress,
    retranscribeWithSpeakerLabels,
    retranscribeOriginalFile,
    runTranscribe,
  };
}

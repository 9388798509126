import { Colors } from "@/constants/Colors";
import LandingPageContainer from "@/ui/LandingPageContainer";
import { useTranscribe } from "@/models/useTranscribe";
import TranscriptViewerAudio from "@/ui/TranscriptViewerAudio";
import WebPaywall from "@/ui/WebPaywall";
import { useUISlice } from "@/models/useUISlice";
import { useTranscript } from "@/models/useTranscript";
import { useEffect } from "react";
import TranscribeLoadingIndicator from "@/ui/TranscribeLoadingIndicator";

export default function WebTranscribe() {
  const { uploadProgress, runTranscribe } = useTranscribe();
  const { status, progress } = useUISlice((state) => ({
    status: state?.transcribeParams?.status,
    progress: state?.transcribeParams?.progress,
  }));

  useEffect(() => {
    if (status === "UPLOADING") {
      runTranscribe();
    }
  }, []);

  const { currentTranscript } = useTranscript();

  if (status && status !== "DONE") {
    return (
      <LandingPageContainer bgColor={Colors.gray3} withBorder>
        <TranscribeLoadingIndicator
          status={status || "ERROR"}
          uploadProgress={uploadProgress}
          transcriptionProgress={progress}
        />
      </LandingPageContainer>
    );
  }

  return (
    <TranscriptViewerAudio item={currentTranscript} Paywall={<WebPaywall />} />
  );
}

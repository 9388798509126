import { isNativeApp } from "@/models/platform";
import NativeApp from "@/pages/native";
import WebApp from "@/pages/web";

function Home() {
  if (isNativeApp()) return <NativeApp />;

  return <WebApp />;
}

export default Home;

import { Share } from "@capacitor/share";
import { isNativeApp } from "@/models/platform";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { ANDROID_APP_LINK } from "@/constants";
import { IOS_APP_LINK } from "@/constants";
import { i18n } from "@/locales";

export function getMobileOperatingSystem() {
  if (typeof window !== "object") return;

  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return "iOS";
  }

  return "unknown";
}

export const getBasePath = () => {
  if (isNativeApp()) return "/native";

  return "/web";
};

export const getLangDir = (): "rtl" | "ltr" => {
  const rtlLanguages = ["ar", "he", "fa", "ur"];

  return rtlLanguages.includes(getLocale()) ? "rtl" : "ltr";
};

export const getLocale = () => {
  let locale = localStorage.getItem("locale");

  if (locale) {
    return locale;
  }

  locale = navigator.language.slice(0, 2).toLowerCase();

  return locale;
};

export function formatDuration(durationInSeconds: number): string {
  const duration = Math.floor(durationInSeconds);
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = duration % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  if (hours > 0) {
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
}

export const downloadFile = async (
  params:
    | {
        type: "base64";
        base64: string;
        fileName: string;
      }
    | {
        type: "url";
        url: string;
        fileName: string;
      }
    | {
        type: "txt";
        text: string;
        fileName: string;
      }
    | {
        type: "file";
        file: File;
        fileName: string;
      }
) => {
  let reader: FileReader;

  if (isNativeApp()) {
    switch (params.type) {
      case "file":
        reader = new FileReader();
        reader.readAsDataURL(params.file);
        reader.onload = async () => {
          const base64 = reader.result as string;
          await downloadFile({
            type: "base64",
            base64,
            fileName: params.fileName,
          });
        };
        break;

      case "base64":
        await Filesystem.writeFile({
          path: `/${params.fileName}`,
          data: params.base64,
          directory: Directory.Cache,
        }).then(({ uri }) => Share.share({ url: uri, title: params.fileName }));
        break;

      case "url":
        const response = await fetch(params.url);
        const blob = await response.blob();
        reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = async () => {
          const base64 = reader.result as string;
          await downloadFile({
            type: "base64",
            base64,
            fileName: params.fileName,
          });
        };
        break;

      case "txt":
        await downloadFile({
          type: "base64",
          base64: Buffer.from(params.text).toString("base64"),
          fileName: params.fileName,
        });
        break;
    }

    return;
  }

  switch (params.type) {
    case "file":
      const url = URL.createObjectURL(params.file);
      await downloadFile({
        type: "url",
        url,
        fileName: params.fileName,
      });
      break;

    case "base64":
      break;

    case "url":
      const downloadLink = document.createElement("a");
      downloadLink.href = params.url;
      downloadLink.download = params.fileName;

      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(params.url);
      break;

    case "txt":
      const blob = new Blob([params.text], { type: "text/plain" });

      await downloadFile({
        type: "url",
        url: URL.createObjectURL(blob),
        fileName: params.fileName,
      });
      break;
  }
};

export const gotoTerms = () => {
  window.open(
    "https://docs.google.com/document/d/e/2PACX-1vSKzK6_-tzlPIPef8On-BtYbL-TEGFP7ZvzruRdoxXrea7V0BcBIdwJWF8l3593fp0rmzJOPQyY-haQ/pub",
    "_blank"
  );
};

export const gotoPrivacy = () => {
  window.open(
    "https://docs.google.com/document/d/e/2PACX-1vRPGpFVOovhGQB9_4Q0r1CusUCB1fjyKct3dTybK8uLRkRJ14hjuyq1tYa37yqwW9HvaYUFxs58gTRe/pub",
    "_blank"
  );
};
export const downloadApp = async (device?: "iOS" | "Android") => {
  const deviceOS = device || getMobileOperatingSystem();

  if (deviceOS === "iOS") {
    return window.open(IOS_APP_LINK, "_self");
  }

  return window.open(ANDROID_APP_LINK, "_self");
};

export const contactUs = (subject?: string) => {
  if (subject) {
    window.open(`mailto:sadeck@1transcribe.com?subject=${subject}`, "_blank");
  } else {
    window.open("mailto:sadeck@1transcribe.com", "_blank");
  }
};

export const suggestFeature = () => {
  window.open("https://eznoteai.canny.io", "_self");
};

export const fileExists = async (url: string) => {
  try {
    if (!url) return false;
    const fileExists = await fetch(url, {
      method: "HEAD",
    }).then((res) => res.ok);

    return fileExists;
  } catch (error) {
    return false;
  }
};

export const downloadTranscriptAsDocx = async ({
  fileName,
  items,
}: {
  fileName: string;
  items: { text: string; metadata?: string }[];
}) => {
  try {
    const { Document, Paragraph, TextRun, Packer } = await import("docx");

    const childrenArray: any[] = [];

    items.forEach((item) => {
      const paragraph = new Paragraph({ children: [] });

      if (item.metadata) {
        const metadataRun = new TextRun(item.metadata);
        paragraph.addChildElement(metadataRun);
        paragraph.addChildElement(new TextRun({ text: "", break: 1 }));
      }

      const textRun = new TextRun(item.text.trim());
      paragraph.addChildElement(textRun);

      childrenArray.push(paragraph);
      item.metadata && childrenArray.push(new Paragraph({ text: "" }));
    });

    const doc = new Document({
      sections: [{ children: childrenArray }],
    });

    const buffer = await Packer.toBuffer(doc);

    if (isNativeApp()) {
      const base64 = Buffer.from(buffer).toString("base64");
      await downloadFile({
        type: "base64",
        base64,
        fileName: `${fileName}.docx`,
      });
      return;
    }

    // Create a Blob with the content
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    await downloadFile({
      type: "url",
      url,
      fileName: `${fileName}.docx`,
    });
  } catch (error) {
    console.error(error);
  }
};

export const downloadTranscriptAsPdf = async ({
  fileName,
  items,
}: {
  fileName: string;
  items: { text: string; metadata?: string }[];
}) => {
  try {
    const { jsPDF } = await import("jspdf");
    const { ArabicStringBase64 } = await import("@/fonts/arabic");

    const doc = new jsPDF({
      format: "a4",
      unit: "mm",
    });
    doc.addFileToVFS("Amiri-Regular.ttf", ArabicStringBase64);
    doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
    doc.setFont("Amiri"); // set font
    let yOffset = 20; // Start with more top margin
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 20; // Left and right margins
    const lineHeight = 5; // Reduced line height for tighter text

    items.forEach((item, index) => {
      const { metadata, text } = item;

      if (metadata) {
        if (yOffset > pageHeight - 30) {
          doc.addPage();
          yOffset = 20;
        }
        doc.setFontSize(10);
        doc.setTextColor(100); // Gray color for metadata
        doc.text(metadata, margin, yOffset);
        yOffset += lineHeight;
      }

      doc.setFontSize(12);
      doc.setTextColor(0); // Black color for main text
      const maxWidth = pageWidth - 2 * margin;
      const lines = doc.splitTextToSize(text.trim(), maxWidth);

      lines.forEach((line: string, lineIndex: number) => {
        if (yOffset > pageHeight - 30) {
          doc.addPage();
          yOffset = 20;
        }
        doc.text(line, margin, yOffset);
        yOffset += lineHeight;
      });

      // Add a small space only if it's not the last item
      if (index < items.length - 1) {
        yOffset += lineHeight * 0.5;
      }
    });

    if (isNativeApp()) {
      const base64 = doc.output("datauristring");
      await downloadFile({
        type: "base64",
        base64,
        fileName: `${fileName}.pdf`,
      });
      return;
    }

    doc.save(`${fileName}.pdf`);
  } catch (error) {
    console.error(error);
  }
};

export const formatDateTime = (date: string) => {
  const d = new Date(date);
  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);

  const time = d.toLocaleString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: false,
  });

  if (d.toDateString() === now.toDateString()) {
    return i18n.t("ui.today") + ", " + time;
  }
  if (d.toDateString() === yesterday.toDateString()) {
    return i18n.t("ui.yesterday") + ", " + time;
  }

  return d.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: false,
  });
};

export const formatTimestamp = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
};

export const isMobileWindow = () => {
  return window.innerWidth < 768;
};

import { InAppReview } from "@capacitor-community/in-app-review";
import { getPlatform, isNativeApp } from "@/models/platform";
import { Share } from "@capacitor/share";
import { StatusBar, Style } from "@capacitor/status-bar";
import {
  ANDROID_APP_LINK,
  IOS_APP_LINK,
  REVENUE_CAT_ANDROID_API_KEY,
  REVENUE_CAT_IOS_API_KEY,
} from "@/constants";
import { CustomerInfo, Purchases } from "@revenuecat/purchases-capacitor";
import { useUserSlice } from "./useUserSlice";
import * as serverClient from "@/models/serverClient";
import { useUISlice } from "./useUISlice";

export const requestReview = () => {
  if (isNativeApp()) {
    InAppReview.requestReview();
  }
};

export const shareApp = () => {
  if (getPlatform() === "android") {
    Share.share({
      title: "Download 1Transcribe",
      text: "Never write notes again. Download 1Transcribe now!",
      url: ANDROID_APP_LINK,
    });
  }

  if (getPlatform() === "ios") {
    Share.share({
      title: "Download 1Transcribe",
      text: "Never write notes again. Download 1Transcribe now!",
      url: IOS_APP_LINK,
    });
  }

  if (getPlatform() === "web") {
    window.open(ANDROID_APP_LINK, "_blank");
  }
};

export const setStatusBarStyleDark = async (color = "#ffffff") => {
  if (isNativeApp()) {
    await StatusBar.setStyle({ style: Style.Light });
    await StatusBar.setBackgroundColor({ color });
  }
};

export const configureRevenueCat = (
  callback?: (customerInfo: CustomerInfo) => void
) => {
  if (isNativeApp()) {
    const userToken = useUserSlice.getState().userToken;

    if (!userToken) return undefined;

    const authUser = serverClient.parseJwt(userToken);

    if (!authUser?.userId) return undefined;

    const revenueCatCustomerInfo = useUISlice.getState().revenueCatCustomerInfo;
    if (revenueCatCustomerInfo) {
      callback?.(revenueCatCustomerInfo);

      return useUserSlice
        .getState()
        .setIsSubscribed(
          revenueCatCustomerInfo?.activeSubscriptions?.length > 0
        );
    }

    Purchases.configure({
      appUserID: authUser.userId,
      apiKey:
        getPlatform() === "ios"
          ? REVENUE_CAT_IOS_API_KEY
          : REVENUE_CAT_ANDROID_API_KEY,
    }).then(async () => {
      Purchases.getCustomerInfo().then(async ({ customerInfo }) => {
        useUISlice.getState().setRevenueCatCustomerInfo(customerInfo);

        const isSubscribed = customerInfo.activeSubscriptions.length > 0;
        useUserSlice.getState().setIsSubscribed(isSubscribed);

        callback?.(customerInfo);
      });
    });
  }
};

import { useRouter } from "@/_router";
import { useTranscribe } from "@/models/useTranscribe";
import NativeTranscriptViewerAudio from "@/ui/NativeTranscriptViewerAudio";
import TranscribeLoadingIndicator from "@/ui/TranscribeLoadingIndicator";
import NativePaywall from "@/ui/NativePaywall";
import { useUISlice } from "@/models/useUISlice";
import { useTranscript } from "@/models/useTranscript";
import { useEffect } from "react";
import NativePage from "@/ui/NativePage";

export default function NativeTranscribe() {
  const { uploadProgress, runTranscribe } = useTranscribe();
  const { status, progress } = useUISlice((state) => ({
    status: state?.transcribeParams?.status,
    progress: state?.transcribeParams?.progress,
  }));
  const router = useRouter();

  useEffect(() => {
    if (status === "UPLOADING") {
      runTranscribe();
    }
  }, []);

  const { currentTranscript } = useTranscript();

  if (status && status !== "DONE") {
    return (
      <NativePage onBackButtonPress={() => router.back()}>
        <TranscribeLoadingIndicator
          status={status || "ERROR"}
          uploadProgress={uploadProgress}
          transcriptionProgress={progress}
        />
      </NativePage>
    );
  }

  return (
    <NativeTranscriptViewerAudio
      item={currentTranscript}
      Paywall={<NativePaywall />}
    />
  );
}

import { View, Text, Image, StyleSheet } from "react-native";
import { Colors } from "@/constants/Colors";
import { ms } from "./moderateScale";
import { isMobileWindow } from "@/models/utils";
import { useTranslation } from "react-i18next";
import { useRouter } from "@/_router";

interface FeatureItemProps {
  title: string;
  description: string;
  imageUri: string;
}

const FeatureItem = ({ title, description, imageUri }: FeatureItemProps) => {
  const isMobile = isMobileWindow();
  return (
    <View
      style={{
        flex: 3,
        backgroundColor: Colors.gray2,
        borderRadius: ms(16),
        padding: isMobile ? ms(24) : ms(32),
        maxHeight: isMobile ? undefined : ms(300),
      }}
    >
      <Text
        style={{
          fontSize: isMobile ? ms(18) : ms(20),
          fontWeight: "500",
          color: Colors.text,
        }}
      >
        {title}
      </Text>
      <Text
        style={{
          fontSize: ms(13),
          color: Colors.textSecondary,
          lineHeight: ms(18),
          marginTop: ms(8),
        }}
      >
        {description}
      </Text>
      <Image
        source={{ uri: imageUri }}
        style={{
          width: isMobile ? "100%" : ms(60),
          height: isMobile ? ms(60) : ms(60),
          marginTop: ms(16),
          resizeMode: "contain",
        }}
      />
    </View>
  );
};

const LandingPageFeatures = () => {
  const isMobile = isMobileWindow();
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <View style={{ flex: 1, marginTop: ms(50) }}>
      <View
        style={{
          flexDirection: isMobile ? "column" : "row",
          gap: isMobile ? ms(24) : ms(32),
          alignSelf: "center",
          marginHorizontal: isMobile ? ms(16) : 0,
          marginBottom: ms(50),
          maxWidth: ms(1024),
        }}
      >
        <FeatureItem
          title={t("landingPage.features.items.accurateTranscripts.title")}
          description={t(
            "landingPage.features.items.accurateTranscripts.description"
          )}
          imageUri="/icons/emoji_target.svg"
        />
        <FeatureItem
          title={t("landingPage.features.items.summaryAndActions.title")}
          description={t(
            "landingPage.features.items.summaryAndActions.description"
          )}
          imageUri="/icons/emoji_speaker.svg"
        />
        <FeatureItem
          title={t("landingPage.features.items.shareNotes.title")}
          description={t("landingPage.features.items.shareNotes.description")}
          imageUri="/icons/emoji_lock.svg"
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({});

export default LandingPageFeatures;

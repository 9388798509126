import { View, Text, StyleSheet, Pressable } from "react-native";
import { ms } from "@/ui/moderateScale";
import { Colors } from "@/constants/Colors";
import { useTranslation } from "react-i18next";
import Icon from "@/ui/Icon";
import { getBasePath } from "@/models/utils";
import { isMobileWindow } from "@/models/utils";
import { useTranscript } from "@/models/useTranscript";
import { Transcript } from "@/models/types";
import Menu from "./Menu";
import { useRouter } from "@/_router";
import AudioPlayer from "./AudioPlayer";

const isMobile = isMobileWindow();

export default function TranscriptViewerAudioHeader({
  item,
  audio,
}: {
  item: Transcript;
  audio: {
    play: () => void;
    pause: () => void;
    hasError: boolean;
    position: number;
    isPlaying: boolean;
    seekTo: (position: number) => void;
    isLoading: boolean;
    rate: number;
    setRate: (rate: number) => void;
  };
}) {
  const { t } = useTranslation();
  const router = useRouter();
  const { copy, download, downloadAudio } = useTranscript();
  const {
    play,
    pause,
    hasError,
    position,
    isPlaying,
    seekTo,
    isLoading,
    rate,
    setRate,
  } = audio;

  if (!item?.originalFileUrl) return null;

  const duration = item.segments?.[item.segments.length - 1]?.end || 0;

  const _onDismiss = () => {
    const fromCheckout = window.location.href.includes("checkout_success");

    if (fromCheckout) {
      return router.replace(`${getBasePath()}/transcripts`);
    }

    router.back();
  };

  const onRateChange = (rate: number, setRate: (rate: number) => void) => {
    const rates = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];
    const currentIndex = rates.indexOf(rate);
    const nextIndex = (currentIndex + 1) % rates.length;
    setRate(rates[nextIndex]);
  };

  return (
    <View
      style={
        {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          flexDirection: "row",
          alignItems: "center",
          alignSelf: "center",
          backgroundColor: Colors.white,
          width: "100%",
          borderBottomWidth: ms(1),
          borderColor: Colors.gray3,
        } as any
      }
    >
      <Pressable
        onPress={_onDismiss}
        style={StyleSheet.flatten([
          styles.backContainer,
          isMobile && {
            padding: 0,
            margin: 0,
            width: AudioPlayer.HEIGHT - ms(12),
            height: AudioPlayer.HEIGHT,
            justifyContent: "center",
          },
        ])}
      >
        <Icon
          icon="arrowLeft"
          style={StyleSheet.flatten([
            styles.backIcon,
            isMobile && { width: ms(26), height: ms(26) },
          ])}
        />
        {!isMobile && <Text style={styles.backText}>{t("ui.back")}</Text>}
      </Pressable>

      <View style={{ flexGrow: 1 }}>
        {!hasError && (
          <AudioPlayer
            duration={duration}
            loading={isLoading}
            _pauseOrPlay={() => {
              if (isPlaying) {
                pause();
              } else {
                play();
              }
            }}
            _seekPrevious={() => seekTo(position - 10)}
            _seekNext={() => seekTo(position + 10)}
            _setRate={() => onRateChange(rate, setRate)}
            _seekPosition={(position) => seekTo(position)}
            isPlaying={isPlaying}
            rate={rate}
            position={position}
          />
        )}
      </View>

      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Menu
          actions={[
            {
              text: t("ui.copyTranscript"),
              onPress: () => copy(item.id),
            },
            {
              text: t("ui.downloadAudio"),
              onPress: () => downloadAudio({ id: item.id }),
            },
            {
              text: t("ui.downloadTranscript"),
              onPress: () => download(item.id),
            },
          ]}
          actionComponent={
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                borderColor: Colors.accent,
                paddingHorizontal: ms(16),
                paddingVertical: ms(8),
                borderRadius: ms(8),
                backgroundColor: Colors.accent,
                marginRight: ms(16),
              }}
            >
              {!isMobile && (
                <Text
                  style={{
                    color: Colors.white,
                    fontSize: ms(16),
                    marginRight: ms(4),
                  }}
                >
                  {t("ui.export")}
                </Text>
              )}
              <Icon
                icon={isMobile ? "moreVertical" : "caretLeft"}
                style={{
                  tintColor: Colors.white,
                  transform: [{ rotate: "-90deg" }],
                  width: ms(20),
                  height: ms(20),
                }}
              />
            </View>
          }
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  backContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: ms(16),
    paddingRight: ms(24),
    justifyContent: "center",
    height: AudioPlayer.HEIGHT,
  },
  backIcon: {
    tintColor: Colors.text,
    width: ms(22),
    height: ms(22),
  },
  backText: {
    color: Colors.text,
    marginLeft: ms(8),
    fontSize: ms(14),
    marginTop: ms(-2),
  },
});

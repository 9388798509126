import React, { useEffect, useRef } from "react";
import { StyleSheet, Text, View, Platform, Pressable } from "react-native";
import { Colors } from "@/constants";
import PrimaryButton from "@/ui/PrimaryButton";
import Icon from "@/ui/Icon";
import { useTranslation } from "react-i18next";
import { App } from "@capacitor/app";
import { ms } from "@/ui/moderateScale";
import { setStatusBarStyleDark } from "@/models/useNative";

const ModalContainer = (props: {
  title: string;
  onDismiss: () => void;
  onPrimaryButtonPress?: () => void;
  onLeftButtonPress?: () => void;
  primaryButtonText?: string;
  children: React.ReactNode;
  modalWidth: any;
  modalMaxWidth?: number;
  hideDismissIcon?: boolean;
  subtitle?: string;
  primaryButtonDisabled?: boolean;
  destructive?: boolean;
  cancelText?: string;
  hideCancel?: boolean;
  shouldPersistBackdropClick?: boolean;
  loading?: boolean;
}) => {
  const ref = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (props.shouldPersistBackdropClick) return;

      if (ref.current && !ref.current.contains(event.target)) {
        props.onDismiss();
      }
    };

    const listenBackButton = () => {
      if (props.shouldPersistBackdropClick) return;

      App.addListener("backButton", () => {
        props.onDismiss();
      });
    };

    listenBackButton();

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      App.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    setStatusBarStyleDark(ModalContainer.OVERLAY_COLOR);

    return () => {
      setStatusBarStyleDark("#ffffff");
    };
  }, []);

  return (
    <View
      style={
        [
          s.container,
          Platform.OS === "web" && {
            position: "fixed" as any,
            width: "100vw" as any,
            height: "100vh" as any,
          },
        ] as any
      }
    >
      <Pressable
        style={s.backdrop}
        onPress={props.shouldPersistBackdropClick ? undefined : props.onDismiss}
      />
      <View ref={ref} style={s.wrapper}>
        <View
          style={
            {
              borderRadius: ms(16),
              paddingHorizontal: ms(24),
              paddingTop: ms(20),
              paddingBottom: ms(20),
              backgroundColor: "rgba(255, 255, 255, 0.95)",
              backdropFilter: "blur(20px)",
              width: props.modalWidth,
              maxWidth: props.modalMaxWidth || undefined,
              boxShadow: "0 8px 32px rgba(0, 0, 0, 0.12)",
            } as any
          }
        >
          <View style={s.headerContainer}>
            <Text numberOfLines={1} style={s.title}>
              {props.title}
            </Text>

            {!props.hideDismissIcon && (
              <Pressable onPress={props.onDismiss} style={s.closeButton}>
                <Icon icon="close" style={s.closeIcon} />
              </Pressable>
            )}
          </View>

          {props.subtitle && <Text style={s.subtitle}>{props.subtitle}</Text>}

          <View style={s.childrenContainer}>{props.children}</View>

          <View style={s.buttonContainer}>
            {!props.hideCancel && (
              <PrimaryButton
                height={ms(44)}
                style={s.cancelButton}
                textStyle={s.cancelButtonText}
                text={props.cancelText || t("ui.cancelButton")}
                onPress={props.onLeftButtonPress || props.onDismiss}
              />
            )}

            <View style={{ width: ms(12) }} />

            {!props.hideCancel && props.onPrimaryButtonPress && (
              <PrimaryButton
                height={ms(44)}
                loading={props.loading}
                style={{
                  minWidth: ms(130),
                  backgroundColor: props.destructive
                    ? Colors.error
                    : Colors.accent,
                  borderRadius: ms(10),
                  opacity: props.primaryButtonDisabled ? 0.5 : 1,
                }}
                disabled={Boolean(props.primaryButtonDisabled)}
                text={props.primaryButtonText}
                onPress={props.onPrimaryButtonPress}
              />
            )}
          </View>

          {props.hideCancel && props.onPrimaryButtonPress && (
            <PrimaryButton
              height={ms(44)}
              loading={props.loading}
              style={{
                minWidth: ms(130),
                backgroundColor: props.destructive
                  ? Colors.error
                  : Colors.accent,
                borderRadius: ms(10),
                opacity: props.primaryButtonDisabled ? 0.5 : 1,
              }}
              disabled={Boolean(props.primaryButtonDisabled)}
              text={props.primaryButtonText}
              onPress={props.onPrimaryButtonPress}
            />
          )}
        </View>
      </View>
    </View>
  );
};

ModalContainer.OVERLAY_COLOR = "rgba(0, 0, 0, 0.4)";

const s = StyleSheet.create({
  container: {
    zIndex: 1000,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "absolute",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    ...(Platform.OS === "ios" && {
      paddingTop: 0,
      marginTop: 0,
    }),
  },
  backdrop: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: ModalContainer.OVERLAY_COLOR,
    backdropFilter: "blur(5px)",
  } as any,
  wrapper: {
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    // width: "100%",
    padding: ms(20),
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: ms(12),
  },
  title: {
    lineHeight: ms(28),
    fontSize: ms(20),
    fontWeight: "600",
    color: Colors.text,
    flex: 1,
    marginRight: ms(16),
  },
  subtitle: {
    marginBottom: ms(16),
    lineHeight: ms(20),
    fontSize: ms(15),
    color: Colors.subtitle,
  },
  childrenContainer: {
    marginBottom: ms(24),
  },
  closeButton: {
    padding: ms(8),
    marginRight: -ms(8),
    borderRadius: ms(20),
    backgroundColor: "rgba(0, 0, 0, 0.05)",
  } as any,
  closeIcon: {
    width: ms(16),
    height: ms(16),
    tintColor: Colors.text,
  },
  buttonContainer: {
    alignItems: "center",
    alignSelf: "flex-end",
    flexDirection: "row",
  },
  cancelButton: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: ms(10),
  },
  cancelButtonText: {
    color: Colors.text,
    fontSize: ms(16),
    fontWeight: "500",
  },
  primaryButton: {
    minWidth: ms(130),
    backgroundColor: Colors.accent,
    borderRadius: ms(10),
  },
  singleButton: {
    minWidth: ms(130),
    backgroundColor: Colors.accent,
    borderRadius: ms(10),
  },
  destructiveButton: {
    backgroundColor: Colors.error,
  },
  disabledButton: {
    opacity: 0.5,
  },
}) as any;

export default ModalContainer;

import { View, Text, StyleSheet, Pressable, Image } from "react-native";
import { isMobileWindow } from "@/models/utils";
import { Colors } from "@/constants/Colors";
import { useTranslation } from "react-i18next";
import { ms } from "@/ui/moderateScale";
import { DESKTOP_MAX_WIDTH } from "@/constants";
import { gotoTerms, gotoPrivacy, contactUs, downloadApp } from "@/models/utils";

const isMobile = isMobileWindow();

const LandingPageFooter = () => {
  const { t } = useTranslation();

  return (
    <View style={s.container}>
      <View style={s.content}>
        <View
          style={[
            s.mainContent,
            {
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              justifyContent: "space-between",
            },
          ]}
        >
          <View style={s.downloadButtons}>
            <Pressable
              onPress={() => downloadApp("Android")}
              accessibilityLabel={t("landingPage.footer.downloadAndroid")}
            >
              <Image
                source={{ uri: "/icons/download_android.svg" }}
                style={s.downloadButton}
              />
            </Pressable>
            <View style={{ width: ms(16) }} />
            <Pressable
              onPress={() => downloadApp("iOS")}
              accessibilityLabel={t("landingPage.footer.downloadIOS")}
            >
              <Image
                source={{ uri: "/icons/download_ios.svg" }}
                style={s.downloadButton}
              />
            </Pressable>
          </View>

          <View
            style={StyleSheet.flatten([
              s.linksSection,
              isMobile && { marginTop: ms(20), marginBottom: ms(8) },
            ])}
          >
            <Pressable onPress={gotoTerms}>
              <Text style={s.link}>{t("landingPage.footer.terms")}</Text>
            </Pressable>
            <View style={s.linkDivider} />
            <Pressable onPress={gotoPrivacy}>
              <Text style={s.link}>{t("landingPage.footer.privacy")}</Text>
            </Pressable>
            <View style={s.linkDivider} />
            <Pressable onPress={() => contactUs()}>
              <Text style={s.link}>{t("landingPage.footer.contact")}</Text>
            </Pressable>
          </View>
        </View>
      </View>
    </View>
  );
};

const s = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    borderTopWidth: 1,
    borderTopColor: Colors.gray3,
  },
  content: {
    maxWidth: DESKTOP_MAX_WIDTH,
    marginHorizontal: "auto",
    width: "100%",
    paddingVertical: ms(16),
  },
  mainContent: {},
  downloadButtons: {
    flexDirection: "row",
    alignItems: "center",
  },
  downloadButton: {
    resizeMode: "contain",
    width: ms(160),
    height: ms(50),
  },
  linksSection: {
    flexDirection: "row",
    alignItems: "center",
    gap: ms(12),
  },
  link: {
    color: Colors.textSecondary,
    fontSize: ms(12),
    fontWeight: "500",
  },
  linkDivider: {
    width: ms(4),
    height: ms(4),
    borderRadius: ms(2),
    backgroundColor: Colors.gray3,
  },
  copyright: {
    textAlign: "center",
    color: Colors.textSecondary,
    fontSize: ms(14),
  },
});

export default LandingPageFooter;

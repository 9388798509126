import { useState } from "react";
import {
  StyleProp,
  StyleSheet,
  Text,
  TextInput,
  View,
  ViewStyle,
} from "react-native";
import { ms } from "@/ui/moderateScale";
import { Colors } from "@/constants";

const Input = ({
  type = "text",
  placeholder,
  label,
  value,
  onTextChange,
  height,
  disabled = false,
  autoFocus = false,
  multiLine = false,
  style = {},
}: {
  type?: "text" | "password";
  placeholder?: string;
  label?: string;
  height?: number;
  value: string;
  onTextChange: (text: string) => void;
  disabled?: boolean;
  autoFocus?: boolean;
  style?: StyleProp<ViewStyle>;
  multiLine?: boolean;
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <View>
      {label && (
        <Text
          style={{
            fontWeight: "300",
            fontSize: ms(13),
            color: Colors.subtitle,
            marginBottom: ms(4),
          }}
        >
          {label}
        </Text>
      )}
      <TextInput
        autoCorrect={false}
        autoFocus={autoFocus}
        multiline={multiLine}
        onChangeText={onTextChange}
        value={value}
        secureTextEntry={type === "password"}
        placeholder={placeholder}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        placeholderTextColor={Colors.subtitle}
        editable={!disabled}
        style={StyleSheet.flatten([
          {
            outlineStyle: "none",
            borderColor: focused && !disabled ? Colors.text : Colors.gray4,
            borderRadius: ms(4),
            borderWidth: ms(1),
            height: height || (multiLine ? ms(180) : ms(44)),
            paddingTop: multiLine ? ms(8) : undefined,
            paddingBottom: multiLine ? ms(8) : undefined,
            paddingLeft: ms(12),
            fontSize: ms(16),
            backgroundColor: disabled ? Colors.gray2 : Colors.white,
            color: Colors.text,
            lineHeight: multiLine ? ms(24) : undefined,
          },
          style,
        ])}
      />
    </View>
  );
};

export default Input;

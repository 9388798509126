import { AUDIO_LANGUAGES, SUPPORTED_MEDIA_FILE_EXTENSIONS } from "@/constants";
import { useTranslation } from "react-i18next";
import { logger } from "@/fetchData/posthog";
import toast from "react-hot-toast";
import { useUISlice } from "@/models/useUISlice";
import { useRouter } from "@/_router";
import { getBasePath } from "@/models/utils";
import { datadogLogs } from "@datadog/browser-logs";
import { useUserSlice } from "@/models/useUserSlice";
import useFileSelector from "@/models/useFileSelector";
import { useRecorder } from "@/models/useRecorder";
import { useState } from "react";
import { getPlatform } from "./platform";

export const useCreateTranscription = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { openFilePicker } = useFileSelector();
  const [recorderVisible, setRecorderVisible] = useState(false);
  const platform = getPlatform();

  const {
    status,
    elapsedTime,
    isRecording,
    startRecording,
    stopRecording,
    pauseOrPlay,
    cancelRecording,
  } = useRecorder();

  const importFile = async () => {
    const file = await openFilePicker(SUPPORTED_MEDIA_FILE_EXTENSIONS);

    datadogLogs.logger.info("[TranscribeFunnel][2] - File selected", {
      platform,
    });
    validateAndProcessFile(file);
  };

  const startRecordingAudio = () => {
    startRecording().then(() => {
      setRecorderVisible(true);
      datadogLogs.logger.info("[TranscribeFunnel][2] - Recording started", {
        platform,
      });
    });
  };

  const stopRecordingAudio = () => {
    setRecorderVisible(false);
    stopRecording((file) => {
      validateAndProcessFile(file);
    });
  };

  const cancelRecordingAudio = () => {
    useUISlice.getState().setAlertModal({
      title: t("ui.cancelRecording"),
      subtitle: t("ui.cancelRecordingMessage"),
      cancelText: t("ui.cancelButton"),
      button: {
        text: t("ui.okButton"),
        destructive: true,
        onPress: async () => {
          setRecorderVisible(false);
          cancelRecording();
        },
      },
    });
  };

  const validateAndProcessFile = async (file: File) => {
    if (!file) return;

    const extension = `.${file.name.split(".").pop()}`.toLowerCase();
    const isAudioOrVideo =
      file.type.toLowerCase().includes("audio") ||
      file.type.toLowerCase().includes("video");

    if (
      !SUPPORTED_MEDIA_FILE_EXTENSIONS.includes(extension) &&
      !isAudioOrVideo
    ) {
      logger.info("Unsupported file", { name: file.name });
      toast.error(t("ui.onlyAudioAndVideoFilesSupported"));
      return;
    }

    showLanguageSelector(file);
  };

  const showLanguageSelector = (file: File) => {
    const defaultLanguageIndex = AUDIO_LANGUAGES.findIndex(
      (lang) => lang.value === useUserSlice.getState().transcriptionLanguage
    );

    useUISlice.getState().setAlertSelect({
      title: t("ui.transcriptionLanguage"),
      options: AUDIO_LANGUAGES.map((lang) => lang.label),
      defaultOption: defaultLanguageIndex,
      onSelect: (index) => {
        useUISlice.getState().setTranscribeParams({
          file,
          language: AUDIO_LANGUAGES[index].value,
          status: "UPLOADING",
          progress: 0,
        });

        datadogLogs.logger.info(
          "[TranscribeFunnel][3] - transcription language selected",
          { language: AUDIO_LANGUAGES[index].value, platform }
        );

        useUISlice.getState().setAlertSelect(undefined);

        setTimeout(() => {
          router.push(`${getBasePath()}/transcribe?id=new`);
        }, 100);
      },
    });
  };

  // Get recording status text
  const getRecordingStatusText = () => {
    if (status === "paused") {
      return t("ui.statusPaused");
    }
    return t("ui.statusRecording");
  };

  return {
    importFile,
    startRecordingAudio,
    stopRecordingAudio,
    cancelRecordingAudio,
    recorderVisible,
    status,
    elapsedTime,
    isRecording,
    pauseOrPlay,
    getRecordingStatusText,
  };
};

export default useCreateTranscription;

import { useUserSlice } from "@/models/useUserSlice";
import { AuthUser } from "@/models/types";
import { useGoogleLogin } from "@react-oauth/google";
import { useRouter } from "@/_router";
import { getBasePath } from "@/models/utils";
import * as serverClient from "@/models/serverClient";
import { SignInWithApple } from "@capacitor-community/apple-sign-in";
import toast from "react-hot-toast";
import { useUISlice } from "@/models/useUISlice";
import { useTranslation } from "react-i18next";
import { getPlatform, isNativeApp } from "@/models/platform";
import { logger } from "@/fetchData/posthog";
import WebPaywall from "@/ui/WebPaywall";
import { version } from "../../package.json";
import posthog from "posthog-js";

let _callback: ((accessToken: string) => void) | undefined = undefined;

export const useAuth = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const _isValidEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email.trim());
  };

  const currentAuthUser = (givenToken?: string): AuthUser | undefined => {
    const userToken = givenToken || useUserSlice.getState().userToken;

    if (!userToken) return undefined;

    const authUser = serverClient.parseJwt(userToken);

    if (authUser.exp < Date.now() / 1000) {
      return undefined;
    }

    return authUser;
  };

  const onGoogleAccessTokenReceived = async (googleAccessToken: string) => {
    const authenticateResponse = await serverClient.authenticateAsync({
      type: "OnGoogleSignIn",
      payload: {
        googleAccessToken,
        deviceTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        deviceLanguage: navigator.language.toLowerCase(),
        deviceType: getPlatform(),
      },
    });

    if (authenticateResponse.error) {
      return toast.error(t("useAuth.invalidEmail"));
    }

    const { accessToken } = authenticateResponse.data;

    onAuthSuccess(accessToken, _callback);
  };

  const signInWithGoogle = async ({
    callback,
  }: {
    callback?: (authToken: string) => void;
  }) => {
    if (isAuthenticated()) {
      return callback?.(useUserSlice.getState().userToken);
    }

    _callback = callback;

    try {
      signInWithGoogleWeb();
    } catch (e) {
      if (e?.error !== "popup_closed_by_user") {
      }
    }
  };

  const signInWithGoogleWeb = useGoogleLogin({
    onSuccess: async (credentialResponse) => {
      onGoogleAccessTokenReceived(credentialResponse.access_token);
    },
    onError: () => undefined,
  });

  const signInWithApple = async ({
    callback,
  }: {
    callback?: (authToken: string) => void;
  }) => {
    if (isAuthenticated()) {
      return callback?.(useUserSlice.getState().userToken);
    }

    try {
      const appleUser = await SignInWithApple.authorize({
        clientId: "com.dktlabs.eznoteai",
        redirectURI: "https://eznote-ai.firebaseapp.com/__/auth/handler",
        scopes: "email",
        state: "12345",
      });

      const authenticateResponse = await serverClient.authenticateAsync({
        type: "OnAppleSignIn",
        payload: {
          appleIdentityToken: appleUser?.response?.identityToken,
          deviceTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          deviceLanguage: navigator.language.toLowerCase(),
          deviceType: getPlatform(),
        },
      });

      if (authenticateResponse.error) {
        return toast.error(t("useAuth.invalidEmail"));
      }

      const { accessToken } = authenticateResponse.data;

      onAuthSuccess(accessToken, callback);
    } catch (e) {}
  };

  const signInWithMobile = async ({
    callback,
  }: {
    callback?: (authToken: string) => void;
  }) => {
    if (useUserSlice.getState().userToken) {
      callback?.(useUserSlice.getState().userToken);
      return;
    }

    const { Device } = await import("@capacitor/device");

    let deviceId = useUserSlice.getState().deviceId;

    if (!deviceId) {
      deviceId = `${getPlatform()}_${(await Device.getId()).identifier.replace(/:/g, "").replace(/-/g, "")}`;
      useUserSlice.getState().setDeviceId(deviceId);
    }

    const authenticateResponse = await serverClient.authenticateAsync({
      type: "OnMobileSignIn",
      payload: {
        deviceId,
        deviceType: getPlatform(),
        deviceLanguage: navigator.language.toLowerCase(),
        deviceTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });

    if (authenticateResponse?.data?.accessToken) {
      onAuthSuccess(authenticateResponse.data.accessToken, callback);
      return callback?.(authenticateResponse.data.accessToken);
    }
  };

  const _refreshUserTranscripts = async (authToken: string) => {};

  const onAuthSuccess = async (
    authToken: string,
    callback?: (authToken: string) => void
  ) => {
    try {
      useUserSlice.getState().setUserToken(authToken);
      const user = currentAuthUser(authToken);
      useUISlice.getState().setAlertPromptSignIn(undefined);

      _refreshUserTranscripts(authToken);
      if (!isNativeApp()) {
        WebPaywall.hasActiveSubscription(true);
      }

      posthog.identify(user?.userId, {
        email: user?.email,
        ip: user?.ip,
        country: user?.country,
        appVersion: version,
      });
      posthog.startSessionRecording();

      logger.info("Funnel 0: user authenticated", { user });
    } catch (e) {
      logger.error("userAuth: User authentication failed", {
        error: e,
      });
    }

    callback && callback(authToken);
    _callback = undefined;
  };

  const signIn = ({
    forceSignIn = false,
    onGoogleSignIn,
    callback,
  }: {
    forceSignIn?: boolean;
    onGoogleSignIn?: () => void;
    callback?: (authToken: string) => void;
  }) => {
    if (isAuthenticated()) {
      return callback?.(useUserSlice.getState().userToken);
    }

    useUISlice.getState().setAlertPromptSignIn({
      title: t("useAuth.title"),
      subtitle: t("useAuth.subtitle"),
      placeholder: t("useAuth.signInEmailPlaceholder"),
      hideDismissIcon: forceSignIn,
      hideCancel: forceSignIn,
      onGoogleSignIn: onGoogleSignIn
        ? () => {
            signInWithGoogle({ callback: onGoogleSignIn });
          }
        : undefined,
      shouldPersistBackdropClick: true,
      button: {
        text: t("useAuth.signInButton"),
        onPress: async (input) => {
          const email = input.trim().toLowerCase();
          const validEmail = _isValidEmail(email);

          if (!validEmail) {
            return toast.error(t("useAuth.invalidEmail"));
          }

          useUISlice.getState().setAlertPromptSignIn({
            ...useUISlice.getState().alertPromptSignIn,
            onGoogleSignIn: undefined,
            defaultValue: email,
            loading: true,
          });

          const otpRequestResponse = await serverClient.authenticateAsync({
            type: "OnOneTimeCodeRequested",
            payload: {
              email,
              deviceType: getPlatform(),
              deviceLanguage: navigator.language,
              deviceTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          });

          useUISlice.getState().setAlertPromptSignIn({
            ...useUISlice.getState().alertPromptSignIn,
            onGoogleSignIn: undefined,
            loading: false,
          });

          if (otpRequestResponse?.error) {
            return toast.error(t("useAuth.invalidEmail"));
          }

          useUISlice.getState().setAlertPromptSignIn({
            title: t("useAuth.oneTimeCodeTitle"),
            subtitle: t("useAuth.oneTimeCodeSubtitle", { email }),
            placeholder: t("useAuth.oneTimeCodePlaceholder"),
            hideCancel: true,
            hideDismissIcon: forceSignIn,
            shouldPersistBackdropClick: true,
            button: {
              text: t("useAuth.oneTimeCodeButton"),
              onPress: async (input) => {
                useUISlice.getState().setAlertPromptSignIn({
                  ...useUISlice.getState().alertPromptSignIn,
                  defaultValue: input,
                  loading: true,
                });

                const otpVerifyResponse = await serverClient.authenticateAsync({
                  type: "OnOneTimeCodeSubmit",
                  payload: { email, oneTimeCode: parseInt(input) },
                });

                useUISlice.getState().setAlertPromptSignIn({
                  ...useUISlice.getState().alertPromptSignIn,
                  loading: false,
                });

                if (
                  otpVerifyResponse &&
                  otpVerifyResponse.error === "InvalidOneTimeCode"
                ) {
                  return toast.error(t("useAuth.invalidCode"));
                }

                if (otpVerifyResponse && otpVerifyResponse.data) {
                  const { accessToken } = otpVerifyResponse.data;

                  useUISlice.getState().setAlertPromptSignIn(undefined);

                  await onAuthSuccess(accessToken, callback);
                }
              },
            },
          });
        },
      },
    });
  };

  const isAuthenticated = (): boolean => {
    const user = currentAuthUser();
    return Boolean(user);
  };

  const signOut = () => {
    useUserSlice.getState().setUserToken(undefined);
    useUserSlice.getState().setTranscripts([]);
    useUserSlice.getState().setIsSubscribed(false);
    router.replace(getBasePath());
  };

  return {
    signIn,
    signInWithGoogle,
    signInWithApple,
    currentAuthUser,
    signInWithMobile,
    isAuthenticated,
    signOut,
  };
};

import { View } from "react-native";
import { useUISlice } from "@/models/useUISlice";
import ModalContainer from "@/ui/ModalContainer";
import { ms } from "@/ui/moderateScale";

const AlertModal = () => {
  const modal = useUISlice((state) => state.alertModal);
  const setModal = useUISlice((state) => state.setAlertModal);

  if (!modal) return null;

  return (
    <ModalContainer
      title={modal.title}
      loading={modal.loading}
      subtitle={modal.subtitle}
      onLeftButtonPress={modal.onLeftButtonPress}
      onDismiss={modal.onDismiss || (() => setModal(undefined))}
      shouldPersistBackdropClick={modal.shouldPersistBackdropClick}
      primaryButtonText={modal.button.text}
      onPrimaryButtonPress={modal.button.onPress}
      hideDismissIcon={modal.hideDismissIcon}
      hideCancel={!modal.cancelText || modal.hideCancel}
      cancelText={modal.cancelText}
      destructive={modal.button.destructive}
      modalWidth={ms(360)}
    >
      <View />
    </ModalContainer>
  );
};

export default AlertModal;

import React from "react";
import { View, Text } from "react-native";
import { useUISlice } from "@/models/useUISlice";
import ModalContainer from "@/ui/ModalContainer";
import CheckboxButton from "@/ui/CheckboxButton";
import PrimaryButton from "@/ui/PrimaryButton";
import { useTranslation } from "react-i18next";
import { ms } from "@/ui/moderateScale";
import RadioButton from "@/ui/RadioButton";
import { Colors } from "@/constants/Colors";

const AlertExportTranscriptOptions = () => {
  const alertExportTranscriptOptions = useUISlice(
    (state) => state.alertExportTranscriptOptions
  );
  const [exportType, setExportType] = React.useState<
    "txt" | "docx" | "pdf" | "srt"
  >("docx");

  const [withTimestamps, setWithTimestamps] = React.useState(false);
  const [withSpeakers, setWithSpeakers] = React.useState(false);
  const [withMonologue, setWithMonologue] = React.useState(false);
  const { t } = useTranslation();

  if (!alertExportTranscriptOptions) return null;

  const _onDismiss = () => {
    setWithTimestamps(false);
    setWithSpeakers(false);
    setWithMonologue(false);
    useUISlice.getState().setAlertExportTranscriptOptions(undefined);
  };

  return (
    <ModalContainer
      title={t("ui.alertExportTranscriptOptions.title")}
      onDismiss={_onDismiss}
      hideCancel
      modalWidth={360}
    >
      <View style={{ height: ms(16) }} />
      <Text
        style={{ fontSize: ms(12), fontWeight: "300", color: Colors.gray6 }}
      >
        {t("ui.alertExportTranscriptOptions.exportFormat")}
      </Text>

      <RadioButton
        text={`${t("ui.alertExportTranscriptOptions.txt")}`}
        isSelected={exportType === "txt"}
        onPress={() => setExportType("txt")}
      />
      <RadioButton
        text={`${t("ui.alertExportTranscriptOptions.docx")}`}
        isSelected={exportType === "docx"}
        onPress={() => setExportType("docx")}
      />
      <RadioButton
        text={`${t("ui.alertExportTranscriptOptions.pdf")}`}
        isSelected={exportType === "pdf"}
        onPress={() => setExportType("pdf")}
      />
      <RadioButton
        text={`${t("ui.alertExportTranscriptOptions.srt")}`}
        isSelected={exportType === "srt"}
        onPress={() => {
          setExportType("srt");
          setWithTimestamps(true);
        }}
      />

      <Text
        style={{
          fontSize: ms(12),
          marginTop: ms(24),
          fontWeight: "300",
          color: Colors.gray6,
        }}
      >
        {t("ui.alertExportTranscriptOptions.outputOptions")}
      </Text>

      <View style={{ height: ms(16) }} />
      <CheckboxButton
        isRight
        title={t("ui.alertExportTranscriptOptions.includeTimestamps")}
        containerStyle={{ marginBottom: ms(24) }}
        value={withTimestamps}
        onCheckboxChange={(value) => {
          setWithTimestamps(value);
          setWithMonologue(false);
        }}
      />

      {alertExportTranscriptOptions.hasSpeakers && (
        <>
          <CheckboxButton
            isRight
            title={t("ui.alertExportTranscriptOptions.includeSpeakers")}
            containerStyle={{ marginBottom: ms(24) }}
            value={withSpeakers}
            onCheckboxChange={(value) => {
              setWithSpeakers(value);
              value && setWithMonologue(false);
            }}
          />
        </>
      )}

      <CheckboxButton
        isRight
        title={t("ui.alertExportTranscriptOptions.exportAsMonologue")}
        containerStyle={{ marginBottom: ms(32) }}
        value={withMonologue}
        onCheckboxChange={(value) => {
          setWithMonologue(value);

          if (value) {
            setWithSpeakers(false);
            setWithTimestamps(false);
          }
        }}
      />

      <PrimaryButton
        text={t("ui.alertExportTranscriptOptions.exportButton")}
        onPress={() => {
          alertExportTranscriptOptions.onExport({
            exportType,
            withTimestamps,
            withSpeakers,
            withMonologue,
          });
          _onDismiss();
        }}
      />
      <View style={{ marginBottom: -ms(24) }} />
    </ModalContainer>
  );
};

export default AlertExportTranscriptOptions;

import { Colors } from "@/constants/Colors";
import Icon from "@/ui/Icon";
import Menu from "@/ui/Menu";
import { ms } from "@/ui/moderateScale";
import { View, Text, StyleSheet, Dimensions } from "react-native";
import { useTranslation } from "react-i18next";
import NativePage from "@/ui/NativePage";
import Recorder from "@/ui/Recorder";
import useCreateTranscription from "@/models/useCreateTranscription";
import ListTranscript from "@/ui/ListTranscript";
import { App } from "@capacitor/app";
import { contactUs } from "@/models/utils";
import { useUserSlice } from "@/models/useUserSlice";
import PrimaryButton from "@/ui/PrimaryButton";
import NativePaywall from "@/ui/NativePaywall";
import { useEffect, useState } from "react";
import * as serverClient from "@/models/serverClient";
import { gotoPrivacy, gotoTerms } from "@/models/utils";
import { useUISlice } from "@/models/useUISlice";

const NativeTranscripts = () => {
  const {
    importFile,
    startRecordingAudio,
    stopRecordingAudio,
    cancelRecordingAudio,
    recorderVisible,
    status,
    elapsedTime,
    isRecording,
    pauseOrPlay,
    getRecordingStatusText,
  } = useCreateTranscription();
  const { t } = useTranslation();
  const customerInfo = useUISlice((state) => state.revenueCatCustomerInfo);
  const [migrateVisible, setMigrateVisible] = useState(false);

  useEffect(() => {
    const checkMigrationStatus = async () => {
      if (useUserSlice.getState().migrateStatus === "success") return;

      const purchaseDate = Object.values(
        customerInfo?.allPurchaseDates || {}
      )[0];

      if (purchaseDate) {
        setMigrateVisible(true);
      }
    };

    customerInfo && checkMigrationStatus();
  }, [customerInfo]);

  return (
    <NativePage
      HeaderComponent={
        <NativeTranscripts.Header
          onStartRecording={startRecordingAudio}
          onImportFile={importFile}
        />
      }
    >
      <View>
        {recorderVisible && (
          <View
            style={{
              backgroundColor: Colors.accentSecondary,
              marginTop: ms(32),
              borderRadius: ms(16),
              padding: ms(24),
              paddingBottom: 0,
              width: Math.min(ms(400), Dimensions.get("window").width - ms(32)),
              alignSelf: "center",
            }}
          >
            <Recorder
              statusText={getRecordingStatusText()}
              elapsedTime={elapsedTime}
              status={status}
              cancelRecording={cancelRecordingAudio}
              stopRecording={stopRecordingAudio}
              pauseOrPlay={pauseOrPlay}
              isRecording={isRecording}
            />
          </View>
        )}

        {!recorderVisible && (
          <View style={{ flex: 1 }}>
            <ListTranscript
              key={migrateVisible ? "migrate" : "transcripts"}
              EmptyComponent={
                migrateVisible && <NativeTranscripts.MigrateComponent />
              }
            />
          </View>
        )}
      </View>
    </NativePage>
  );
};

NativeTranscripts.Header = (props?: {
  onStartRecording?: () => void;
  onImportFile?: () => void;
}) => {
  const { t } = useTranslation();

  const actions = [
    {
      text: t("ui.privacy"),
      onPress: () => {
        App.removeAllListeners().then(() => {
          gotoPrivacy();
        });
      },
    },
    {
      text: t("ui.terms"),
      onPress: () => {
        App.removeAllListeners().then(() => {
          gotoTerms();
        });
      },
    },
    {
      text: t("ui.contactUs"),
      onPress: () => {
        App.removeAllListeners().then(() => {
          contactUs(
            encodeURIComponent(
              `Hello I need help with 1transcribe (#DO NOT REMOVE: ${useUserSlice.getState()?.deviceId})`
            )
          );
        });
      },
    },
  ].filter(Boolean);

  return (
    <View style={styles.header}>
      <Menu
        actions={actions}
        actionComponent={
          <Icon
            icon="moreHorizontal"
            containerStyle={styles.headerIconContainer}
            style={{ tintColor: Colors.text }}
          />
        }
      />

      <Text style={{ fontSize: ms(16), fontWeight: "600", color: Colors.text }}>
        1Transcribe.com
      </Text>

      {props?.onStartRecording && props?.onImportFile && (
        <Menu
          dark
          menuItemTextStyle={{
            lineHeight: ms(28),
          }}
          menuItemStyle={{
            borderBottomWidth: StyleSheet.hairlineWidth,
            borderBottomColor: Colors.white + "20",
          }}
          actions={[
            {
              text: t("ui.recordAudio"),
              onPress: props?.onStartRecording,
            },
            {
              text: t("ui.importFile"),
              onPress: props?.onImportFile,
            },
          ]}
          actionComponent={
            <View
              style={{
                ...styles.headerIconContainer,
                backgroundColor: Colors.accent,
              }}
            >
              <Icon icon="plus" style={{ tintColor: Colors.white }} />
            </View>
          }
        />
      )}
    </View>
  );
};

NativeTranscripts.MigrateComponent = () => {
  const { t } = useTranslation();
  const migrateStatus = useUserSlice((state) => state.migrateStatus);
  const [loading, setLoading] = useState(false);

  const _recover = async () => {
    setLoading(true);
    const customerInfo = await NativePaywall.initialize();
    const purchaseDate = Object.values(customerInfo.allPurchaseDates)[0];

    if (purchaseDate) {
      const migrationResponse = await serverClient.migrateMobileUserAsync({
        lastPurchaseDateMillis: new Date(purchaseDate).getTime(),
      });

      useUserSlice
        .getState()
        .setMigrateStatus(migrationResponse.error ? "error" : "success");

      if (!migrationResponse.error) {
        setTimeout(() => {
          window.location.reload();
        }, 100);
      }
    }
    setLoading(false);
  };

  const _emailSupport = () => {
    contactUs(
      encodeURIComponent(
        `Hello I cannot recover my transcripts (#DO NOT REMOVE: ${useUserSlice.getState()?.deviceId})`
      )
    );
  };

  let buttonText = t("ui.emptyTranscriptsRecoverButton");
  let description = t("ui.emptyTranscriptsRecoverText");
  if (migrateStatus === "error") {
    buttonText = t("ui.migrationErrorButton");
    description = t("ui.migrationErrorText");
  }

  return (
    <View>
      <Text style={styles.emptyText}>{description}</Text>
      <PrimaryButton
        loading={loading}
        disabled={loading}
        loadingColor={Colors.accent}
        style={{ backgroundColor: Colors.accentSecondary }}
        textStyle={{ color: Colors.accent }}
        text={buttonText}
        onPress={migrateStatus === "error" ? _emailSupport : _recover}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    marginTop: ms(4),
    height: ms(64),
    backgroundColor: Colors.white,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: ms(12),
    shadowColor: Colors.text,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.15,
    shadowRadius: 3,
    elevation: 2, // for Android
    zIndex: 1, // ensure shadow is visible
  },
  headerIconContainer: {
    width: ms(40),
    height: ms(40),
    borderRadius: ms(40),
    backgroundColor: Colors.gray3,
    alignItems: "center",
    justifyContent: "center",
  },
  banner: {
    backgroundColor: "#00ff00" + "30",
    flexDirection: "row",
    alignItems: "center",
    gap: ms(4),
    paddingVertical: ms(12),
    paddingHorizontal: ms(12),
    justifyContent: "center",
  },
  bannerText: {
    fontWeight: "500",
    color: Colors.text,
    fontSize: ms(14),
  },
  bannerIcon: {
    transform: [{ rotate: "180deg" }],
    tintColor: Colors.text,
    width: ms(20),
    height: ms(20),
  },
  recorderContainer: {
    backgroundColor: Colors.accentSecondary,
    marginTop: ms(32),
    borderRadius: ms(16),
    padding: ms(24),
    paddingBottom: 0,
    width: Math.min(ms(400), Dimensions.get("window").width - ms(32)),
    alignSelf: "center",
  },
  scrollView: {
    paddingTop: ms(16),
  },
  itemHeader: {
    height: ms(32),
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: Colors.gray2,
  },
  itemHeaderText: {
    flex: 1,
    paddingLeft: ms(12),
    color: Colors.gray6,
    fontSize: ms(12),
    fontWeight: "300",
  },
  itemContainer: {
    height: ms(56),
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: Colors.gray3,
  },
  itemText: {
    paddingLeft: ms(12),
    color: Colors.text,
    fontSize: ms(15),
    flexGrow: 1,
  },
  itemIconContainer: {
    marginLeft: ms(12),
    width: ms(40),
    height: ms(40),
    paddingRight: ms(12),
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    tintColor: Colors.text,
    width: ms(20),
    height: ms(20),
  },
  emptyContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  emptyText: {
    color: Colors.gray6,
    fontSize: ms(14),
    fontWeight: "300",
    textAlign: "center",
    paddingHorizontal: ms(12),
    lineHeight: ms(20),
    marginBottom: ms(16),
  },
});

export default NativeTranscripts;

import { create } from "zustand";
import { CustomerInfo } from "@revenuecat/purchases-capacitor";
import { Status } from "@/models/useTranscribe";

type State = {
  guestToken?: string;
  revenueCatCustomerInfo?: CustomerInfo;
  device: {
    isMobile: boolean;
    width: number;
    height: number;
  };
  transcribeParams?: {
    file: File;
    language: string;
    status: Status;
    progress: number;
  };
  alertSelect?: {
    title?: string;
    buttonText?: string;
    defaultOption?: number;
    options: string[];
    onSelect?: (index: number) => void;
    onSelectAsync?: (index: number) => Promise<void>;
  };
  alertExportTranscriptOptions?: {
    hasSpeakers: boolean;
    onExport: (options: {
      exportType: "docx" | "pdf" | "txt" | "srt";
      withTimestamps: boolean;
      withSpeakers: boolean;
      withMonologue: boolean;
    }) => void;
  };
  useUploadParams?: {
    fileType: string;
    key: string;
  };
  alertPromptSignIn?: {
    title: string;
    subtitle: string;
    placeholder: string;
    shouldPersistBackdropClick?: boolean;
    multiLine?: boolean;
    loading?: boolean;
    hideDismissIcon?: boolean;
    hideCancel?: boolean;
    defaultValue?: string;
    button: {
      text: string;
      onPress: (text: string) => void;
      destructive?: boolean;
    };
    onGoogleSignIn?: () => void;
  };
  alertModal?: {
    title: string;
    loading?: boolean;
    subtitle: string;
    shouldPersistBackdropClick?: boolean;
    hideDismissIcon?: boolean;
    hideCancel?: boolean;
    onDismiss?: () => void;
    onLeftButtonPress?: () => void;
    button?: { text: string; onPress: () => void; destructive?: boolean };
    cancelText?: string;
  };
  alertWidgetVisible?: "recorder" | "nativeRecorder" | "paywall";
  alertPrompt?: {
    title: string;
    subtitle?: string;
    placeholder: string;
    shouldPersistBackdropClick?: boolean;
    multiLine?: boolean;
    loading?: boolean;
    hideDismissIcon?: boolean;
    hideCancel?: boolean;
    defaultValue?: string;
    button: {
      text: string;
      onPress: (text: string) => void;
      destructive?: boolean;
    };
  };
};

type Actions = {
  setAlertModal: (payload: State["alertModal"]) => void;
  setGuestToken: (payload: State["guestToken"]) => void;
  setAlertSelect: (payload: State["alertSelect"]) => void;
  setDevice: (payload: State["device"]) => void;
  setTranscribeParams: (payload: State["transcribeParams"]) => void;
  setRevenueCatCustomerInfo: (payload: State["revenueCatCustomerInfo"]) => void;
  setAlertExportTranscriptOptions: (
    payload: State["alertExportTranscriptOptions"]
  ) => void;
  setUseUploadParams: (payload: State["useUploadParams"]) => void;
  setAlertPromptSignIn: (payload: State["alertPromptSignIn"]) => void;
  setAlertPrompt: (payload: State["alertPrompt"]) => void;
  setAlertWidgetVisible: (payload: State["alertWidgetVisible"]) => void;
  reset: () => void;
};

// define the initial state
const initialState: State = {
  device: {
    isMobile: false,
    width: window.innerWidth,
    height: window.innerHeight,
  },
};

export const useUISlice = create<State & Actions>()((set, get) => ({
  ...initialState,
  setGuestToken: (guestToken) => {
    set({ guestToken });
  },
  setRevenueCatCustomerInfo: (revenueCatCustomerInfo) => {
    set({ revenueCatCustomerInfo });
  },
  setTranscribeParams: (transcribeParams) => {
    set({ transcribeParams });
  },
  setAlertSelect: (payload) => {
    set({ alertSelect: payload });
  },
  setUseUploadParams: (payload) => {
    set({ useUploadParams: payload });
  },
  setDevice: (payload) => {
    set({ device: payload });
  },
  setAlertPromptSignIn: (payload) => {
    set({ alertPromptSignIn: payload });
  },
  setAlertExportTranscriptOptions: (payload) => {
    set({ alertExportTranscriptOptions: payload });
  },
  setAlertModal: (payload) => {
    set({ alertModal: payload });
  },
  setAlertPrompt: (payload) => {
    set({ alertPrompt: payload });
  },
  setAlertWidgetVisible: (payload) => {
    set({ alertWidgetVisible: payload });
  },
  reset: () => {
    set({ ...initialState });
  },
}));

import React, { useEffect } from "react";
import { View, ScrollView, Dimensions } from "react-native";
import { useUISlice } from "@/models/useUISlice";
import ModalContainer from "@/ui/ModalContainer";
import PrimaryButton from "@/ui/PrimaryButton";
import { useTranslation } from "react-i18next";
import { ms } from "@/ui/moderateScale";
import RadioButton from "@/ui/RadioButton";

const AlertSelect = () => {
  const alertSelect = useUISlice((state) => state.alertSelect);
  const [loading, setLoading] = React.useState(false);

  const [selectedOption, setSelectedOption] = React.useState(
    alertSelect?.defaultOption || 0
  );
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedOption(alertSelect?.defaultOption || 0);
  }, [alertSelect]);

  const _onDismiss = () => {
    useUISlice.getState().setAlertSelect(undefined);
  };

  if (!alertSelect) return null;

  return (
    <ModalContainer
      title={alertSelect.title || t("ui.alertSelect.title")}
      onDismiss={_onDismiss}
      hideCancel
      modalWidth={ms(360)}
    >
      <View style={{ height: ms(16) }} />

      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ maxHeight: Dimensions.get("window").height * 0.5 }}
      >
        {alertSelect.options.map((option, index) => (
          <RadioButton
            key={`${option}-${index}`}
            text={option}
            isSelected={selectedOption === index}
            onPress={() => setSelectedOption(index)}
          />
        ))}
      </ScrollView>

      <View style={{ height: ms(16) }} />
      <PrimaryButton
        loading={loading}
        text={alertSelect.buttonText || t("ui.alertSelect.buttonText")}
        onPress={async () => {
          if (alertSelect.onSelectAsync) {
            setLoading(true);
            await alertSelect.onSelectAsync(selectedOption);
            _onDismiss();
            setLoading(false);
          } else {
            alertSelect.onSelect(selectedOption);
            _onDismiss();
          }
        }}
      />
      <View style={{ marginBottom: -ms(24) }} />
    </ModalContainer>
  );
};

export default AlertSelect;

import React, { useEffect, useRef, useState } from "react";
import {
  Pressable,
  StyleSheet,
  Text,
  View,
  StyleProp,
  ViewStyle,
  TextStyle,
  ImageStyle,
} from "react-native";
import { Colors } from "@/constants";
import { ms } from "@/ui/moderateScale";
import Popup from "reactjs-popup";
import Icon, { IconType } from "@/ui/Icon";

export type PopupPosition =
  | "top left"
  | "top center"
  | "top right"
  | "right top"
  | "right center"
  | "right bottom"
  | "bottom left"
  | "bottom center"
  | "bottom right"
  | "left top"
  | "left center"
  | "left bottom"
  | "center center";

export type MenuAction = {
  text: string;
  icon?: IconType;
  notificationText?: string;
  destructive?: boolean;
  onPress: () => void;
};

const MenuItem = ({
  index,
  action,
  menuRef,
  style = {},
  textStyle = {},
  iconStyle = {},
  hoverBackgroundColor = "rgba(0, 0, 0, 0.05)",
}: {
  index: number;
  action: MenuAction;
  menuRef: any;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  iconStyle?: StyleProp<ImageStyle>;
  hoverBackgroundColor?: string;
}) => {
  const [active, setActive] = useState("");

  const _onPress = (action: MenuAction) => {
    action.onPress();
    menuRef.current.close();
    setActive("");
  };

  if (index === 0) {
    return <input key={action.text} style={{ display: "none" }} />;
  }

  return (
    <Pressable
      key={action.text}
      onPress={() => _onPress(action)}
      onHoverIn={() => setActive(action.text)}
      onHoverOut={() => setActive("")}
      style={StyleSheet.flatten([
        s.itemContainer,
        style as any,
        active === action.text && { backgroundColor: hoverBackgroundColor },
        index > 1 &&
          {
            // removing border styles
          },
      ])}
    >
      {action.icon && (
        <View style={{ marginRight: ms(8) }}>
          <Icon
            icon={action.icon}
            style={StyleSheet.flatten([
              { width: ms(18), height: ms(18) },
              iconStyle,
              action.destructive && { tintColor: Colors.error },
            ] as any)}
          />
        </View>
      )}

      <Text
        style={StyleSheet.flatten([
          s.itemText,
          textStyle as any,
          action.destructive && { color: Colors.error },
        ])}
      >
        {action.text}
      </Text>

      {Boolean(action.notificationText) && (
        <View style={s.notifContainer}>
          <Text style={s.notifText}>{action.notificationText}</Text>
        </View>
      )}
    </Pressable>
  );
};

const Menu = ({
  actionComponent,
  actions,
  position = "bottom right",
  menuContainerStyle = {},
  menuItemStyle = {},
  menuItemTextStyle = {},
  menuItemIconStyle = {},
  hoverBackgroundColor = "rgba(0, 0, 0, 0.05)",
  dark = false,
}: {
  actions: MenuAction[];
  actionComponent: React.ReactNode;
  position?: PopupPosition;
  menuContainerStyle?: StyleProp<ViewStyle>;
  menuItemStyle?: StyleProp<ViewStyle>;
  menuItemTextStyle?: StyleProp<TextStyle>;
  menuItemIconStyle?: StyleProp<ImageStyle>;
  hoverBackgroundColor?: string;
  dark?: boolean;
}) => {
  const ref = useRef<any>();
  const actionButtonRef = useRef<HTMLDivElement>(null);
  const [dynamicPosition, setDynamicPosition] =
    useState<PopupPosition>(position);

  useEffect(() => {
    const updatePopupPosition = () => {
      if (position) return;

      if (actionButtonRef.current) {
        const rect = actionButtonRef.current.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        // Calculate available space in each direction
        const spaceBelow = windowHeight - rect.bottom;
        const spaceAbove = rect.top;
        const spaceRight = windowWidth - rect.right;
        const spaceLeft = rect.left;

        // Minimum space needed for the popup (adjust these values as needed)
        const minHeight = ms(200); // Minimum height needed for popup
        const minWidth = ms(200); // Minimum width needed for popup

        // Determine vertical position
        let vertical = spaceBelow >= minHeight ? "bottom" : "top";

        // Determine horizontal position
        let horizontal;
        if (spaceRight >= minWidth) {
          horizontal = "left";
        } else if (spaceLeft >= minWidth) {
          horizontal = "right";
        } else {
          horizontal = "center";
        }

        // Special cases for center positioning
        if (spaceAbove < minHeight && spaceBelow < minHeight) {
          vertical = "right";
        }

        if (spaceLeft < minWidth && spaceRight < minWidth) {
          horizontal = "center";
        }

        setDynamicPosition(`${vertical} ${horizontal}` as PopupPosition);
      }
    };

    // Initial positioning
    updatePopupPosition();

    // Update on scroll and resize
    window.addEventListener("resize", updatePopupPosition);
    window.addEventListener("scroll", updatePopupPosition);

    // Cleanup
    return () => {
      window.removeEventListener("resize", updatePopupPosition);
      window.removeEventListener("scroll", updatePopupPosition);
    };
  }, [actions]);

  const ActionButton = React.memo(
    React.forwardRef(({ ...props }, ref) => (
      <div ref={ref as any} {...props}>
        <div ref={actionButtonRef} style={{ cursor: "pointer" }}>
          {actionComponent}
        </div>
      </div>
    )),
    (prevProps, nextProps) => prevProps === nextProps
  );

  const items = [{ text: "1", onPress: null }, ...actions];

  return (
    <Popup
      ref={ref}
      trigger={() => <ActionButton />}
      position={dynamicPosition}
      on="click"
      closeOnDocumentClick
      contentStyle={
        {
          padding: 0,
          border: "none",
          borderRadius: ms(4),
          maxHeight: "80vh",
          overflowY: "auto",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          backgroundColor: dark ? Colors.text : "white",
        } as any
      }
      arrow={false}
      keepTooltipInside={true}
    >
      <View
        style={StyleSheet.flatten([
          s.container,
          menuContainerStyle,
          dark && { backgroundColor: Colors.text },
        ])}
      >
        {items.map((action, index) => (
          <MenuItem
            key={action.text}
            index={index}
            action={action}
            menuRef={ref}
            style={StyleSheet.flatten([
              menuItemStyle,
              dark && { backgroundColor: Colors.text },
            ])}
            textStyle={StyleSheet.flatten([
              menuItemTextStyle,
              dark && { color: Colors.white },
            ])}
            iconStyle={StyleSheet.flatten([
              menuItemIconStyle,
              dark && !action.destructive && { tintColor: Colors.white },
            ])}
            hoverBackgroundColor={
              dark ? "rgba(255, 255, 255, 0.1)" : hoverBackgroundColor
            }
          />
        ))}
      </View>
    </Popup>
  );
};

const s = StyleSheet.create({
  container: {
    minWidth: ms(200),
    backgroundColor: "#ffffff",
    padding: 0,
    borderRadius: ms(4),
    overflow: "hidden",
  },
  itemContainer: {
    flexDirection: "row",
    paddingHorizontal: ms(16),
    alignItems: "center",
    paddingVertical: ms(12),
    backgroundColor: "#ffffff",
  },
  itemText: {
    color: Colors.title,
    fontSize: ms(16),
    fontWeight: "400",
  },
  notifContainer: {
    marginLeft: ms(8),
    minWidth: ms(18),
    height: ms(18),
    paddingHorizontal: ms(6),
    borderRadius: ms(9),
    backgroundColor: Colors.error,
    alignItems: "center",
    justifyContent: "center",
  },
  notifText: {
    color: Colors.white,
    fontWeight: "600",
    fontSize: ms(11),
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  },
});

export default React.memo(Menu, (prevProps, nextProps) => {
  return prevProps.actions.toString() === nextProps.actions.toString();
});

import { hooks } from "@generouted/react-router/client";
import { useCallback, useRef, useEffect } from "react";
import { Path as RouterPath } from "./router";

export type Path = RouterPath | string;
export type Params = {};
export type ModalPath = never;

export const { useModals, useNavigate, useParams } = hooks<
  Path,
  Params,
  ModalPath
>();

type RouterOptions = {
  shallow?: boolean;
  scroll?: boolean;
};

type PushOptions = RouterOptions & {
  replace?: boolean;
};

const NAVIGATION_DEBOUNCE_MS = 300;

export function useRouter() {
  const navigate = useNavigate();
  const lastNavigationTime = useRef<number>(0);
  const navigationInProgress = useRef<boolean>(false);

  // Navigation guard to prevent rapid consecutive navigations
  const shouldAllowNavigation = useCallback(() => {
    const now = Date.now();
    if (
      navigationInProgress.current ||
      now - lastNavigationTime.current < NAVIGATION_DEBOUNCE_MS
    ) {
      return false;
    }
    lastNavigationTime.current = now;
    return true;
  }, []);

  // Handle navigation completion
  const handleNavigationComplete = useCallback(() => {
    navigationInProgress.current = false;
  }, []);

  // Setup navigation event listeners
  useEffect(() => {
    const handlePopState = () => {
      handleNavigationComplete();
    };

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [handleNavigationComplete]);

  const push = useCallback(
    (url: Path, options: PushOptions = {}) => {
      if (!shouldAllowNavigation()) {
        return;
      }

      try {
        navigationInProgress.current = true;
        navigate(url, {
          replace: options.replace,
          preventScrollReset: !options.scroll,
          state: { ...window.history.state, timestamp: Date.now() },
        });

        // Reset navigation state after a successful push
        setTimeout(handleNavigationComplete, 100);
      } catch (error) {
        console.error("Navigation error:", error);
        handleNavigationComplete();
      }
    },
    [navigate, shouldAllowNavigation, handleNavigationComplete]
  );

  const replace = useCallback(
    (url: Path, options: RouterOptions = {}) => {
      if (!shouldAllowNavigation()) {
        return;
      }

      try {
        navigationInProgress.current = true;
        navigate(url, {
          replace: true,
          preventScrollReset: !options.scroll,
          state: { ...window.history.state, timestamp: Date.now() },
        });

        setTimeout(handleNavigationComplete, 100);
      } catch (error) {
        console.error("Navigation error:", error);
        handleNavigationComplete();
      }
    },
    [navigate, shouldAllowNavigation, handleNavigationComplete]
  );

  const back = useCallback(() => {
    if (!shouldAllowNavigation()) {
      return;
    }

    try {
      navigationInProgress.current = true;
      navigate(-1);
      setTimeout(handleNavigationComplete, 100);
    } catch (error) {
      console.error("Navigation error:", error);
      handleNavigationComplete();
    }
  }, [navigate, shouldAllowNavigation, handleNavigationComplete]);

  const forward = useCallback(() => {
    if (!shouldAllowNavigation()) {
      return;
    }

    try {
      navigationInProgress.current = true;
      navigate(1);
      setTimeout(handleNavigationComplete, 100);
    } catch (error) {
      console.error("Navigation error:", error);
      handleNavigationComplete();
    }
  }, [navigate, shouldAllowNavigation, handleNavigationComplete]);

  return {
    push,
    replace,
    back,
    forward,
    pathname: window.location.pathname,
    query: Object.fromEntries(new URLSearchParams(window.location.search)),
    asPath: window.location.pathname + window.location.search,
  };
}

// Ensure Router methods use the same navigation protection
export const Router = {
  push: (url: string) => {
    try {
      window.history.pushState({}, "", url);
      window.location.href = url;
    } catch (error) {
      console.error("Router.push error:", error);
      window.open(url, "_self");
    }
  },
  replace: (url: string) => {
    try {
      window.history.replaceState({}, "", url);
      window.location.href = url;
    } catch (error) {
      console.error("Router.replace error:", error);
      window.open(url, "_self");
    }
  },
};

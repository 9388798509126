import { View, Text, StyleSheet, Dimensions } from "react-native";
import { ms } from "@/ui/moderateScale";
import { Colors } from "@/constants/Colors";
import { useTranslation } from "react-i18next";
import PrimaryButton from "@/ui/PrimaryButton";
import Icon from "@/ui/Icon";
import { formatDuration, formatDateTime } from "@/models/utils";
import LandingPageContainer from "@/ui/LandingPageContainer";
import { isMobileWindow } from "@/models/utils";
import { useTranscript } from "@/models/useTranscript";
import { useAudioPlayer } from "@/models/useAudioPlayer";
import { Transcript } from "@/models/types";
import TranscriptViewerAudioSegmentItem from "@/ui/TranscriptViewerAudioSegmentItem";
import { useState, useEffect } from "react";
import TranscriptViewerAudioHeader from "./TranscriptViewerAudioHeader";

const WIDTH = Dimensions.get("window").width;
const isMobile = isMobileWindow();
const SEGMENTS_PER_PAGE = 20; // Number of segments to load at once

export default function TranscriptViewerAudio({
  item,
  Paywall,
}: {
  item: Transcript;
  Paywall: React.ReactNode;
}) {
  const { t } = useTranslation();
  const { copy, download } = useTranscript();
  const {
    play,
    pause,
    hasError,
    position,
    isPlaying,
    seekTo,
    isLoading,
    rate,
    setRate,
  } = useAudioPlayer(
    item?.isFullTranscript ? item?.originalFileUrl : item?.audioUrl
  );

  const [visibleSegments, setVisibleSegments] =
    useState<number>(SEGMENTS_PER_PAGE);

  // Reset visible segments when transcript changes
  useEffect(() => {
    setVisibleSegments(SEGMENTS_PER_PAGE);
  }, [item?.id]);

  if (!item?.originalFileUrl) return null;

  const duration = item.segments?.[item.segments.length - 1]?.end || 0;

  const loadMoreSegments = () => {
    setVisibleSegments((prev) =>
      Math.min(prev + SEGMENTS_PER_PAGE, item.segments?.length || 0)
    );
  };

  const hasMoreSegments =
    item.segments && visibleSegments < item.segments.length;

  return (
    <LandingPageContainer
      bgColor={isMobile ? Colors.white : Colors.gray3}
      withBorder={isMobile}
      hideLanguageSelector={isMobile}
      Header={
        <TranscriptViewerAudioHeader
          item={item}
          audio={{
            play,
            pause,
            hasError,
            position,
            isPlaying,
            seekTo,
            isLoading,
            rate,
            setRate,
          }}
        />
      }
    >
      <View style={styles.container}>
        <View
          style={[
            styles.contentContainer,
            isMobile && styles.contentContainerMobile,
          ]}
        >
          <Text numberOfLines={4} style={styles.fileName}>
            {item.fileName}
          </Text>
          <View style={styles.metaContainer}>
            <View style={styles.dateContainer}>
              <Icon
                icon="calendar"
                containerStyle={styles.metaIcon}
                style={styles.metaIcon}
              />
              <Text style={styles.metaText}>
                {formatDateTime(item.createdAt)}
              </Text>
            </View>

            <View style={styles.clockContainer}>
              <Icon
                icon="clock"
                containerStyle={styles.metaIcon}
                style={styles.metaIcon}
              />
              <Text style={styles.metaText}>{formatDuration(duration)}</Text>
            </View>
          </View>

          <View style={styles.buttonContainer}>
            <PrimaryButton
              icon="copy"
              iconStyle={styles.buttonIconStyle}
              style={styles.actionButton}
              textStyle={styles.actionButtonText}
              text={t("ui.copy")}
              onPress={() => copy(item.id)}
            />
            <PrimaryButton
              icon="download"
              iconStyle={styles.buttonIconStyle}
              style={styles.actionButton}
              textStyle={styles.actionButtonText}
              text={t("ui.download")}
              onPress={() => download(item.id)}
            />
          </View>

          {item.segments && (
            <View style={styles.transcriptContainer}>
              {item.segments.slice(0, visibleSegments).map((segment) => {
                const start = segment.start || 0;
                const end = segment.end || duration;
                const isSelected = position > start && position < end;

                return (
                  <View key={segment.start} style={styles.transcriptItem}>
                    <TranscriptViewerAudioSegmentItem
                      segment={segment}
                      audio={{
                        hasError,
                        seekTo,
                        position,
                        duration,
                        pause: () => {
                          if (isPlaying) {
                            pause();
                          }
                        },
                        play: () => {
                          seekTo(start);
                          if (!isPlaying) {
                            play();
                          }
                        },
                        isPlaying: isSelected && isPlaying,
                      }}
                      transcriptId={item.id}
                      isSelected={isSelected}
                      onPress={(seconds) => {
                        seekTo(seconds);
                        if (!isPlaying) {
                          play();
                        }
                      }}
                    />
                  </View>
                );
              })}

              {hasMoreSegments && (
                <View style={styles.loadMoreContainer}>
                  <PrimaryButton
                    style={styles.loadMoreButton}
                    textStyle={styles.loadMoreButtonText}
                    text={t("ui.loadMore")}
                    onPress={loadMoreSegments}
                  />
                  <Text style={styles.segmentCountText}>
                    {`${visibleSegments} / ${item.segments.length} ${t("ui.items")}`}
                  </Text>
                </View>
              )}
            </View>
          )}

          {Paywall && Paywall}
        </View>
      </View>
    </LandingPageContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: ms(24),
    backgroundColor: Colors.gray3,
    alignItems: "center",
    justifyContent: "center",
  },
  contentContainer: {
    marginTop: ms(48),
    width: Math.min(820, WIDTH),
    padding: ms(64),
    alignSelf: "center",
    backgroundColor: Colors.white,
    borderWidth: ms(1),
    borderColor: Colors.gray4,
    marginBottom: ms(32),
  },
  contentContainerMobile: {
    paddingHorizontal: ms(16),
    paddingVertical: ms(16),
    paddingTop: ms(24),
    marginTop: 0,
    marginBottom: 0,
    borderWidth: 0,
  },
  fileName: {
    maxWidth: ms(780),
    fontSize: ms(24),
    color: Colors.gray10,
    fontWeight: "500",
    flexWrap: "wrap",
  },
  transcriptContainer: {
    flex: 1,
    height: "100%",
    marginTop: ms(24),
  },
  transcriptItem: {
    width: "100%",
  },
  metaContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 16,
  },
  dateContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  metaIcon: {
    width: 12,
    height: 12,
  },
  metaText: {
    marginLeft: 4,
    fontWeight: "300",
    fontSize: 12,
    color: Colors.gray8,
  },
  clockContainer: {
    marginLeft: 16,
    flexDirection: "row",
    alignItems: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    gap: ms(16),
  },
  buttonIconStyle: {
    tintColor: Colors.accent,
    width: ms(16),
    height: ms(16),
  },
  actionButton: {
    backgroundColor: Colors.accentSecondary,
  },
  actionButtonText: {
    color: Colors.accent,
  },
  loadMoreContainer: {
    alignItems: "center",
    marginTop: ms(16),
    marginBottom: ms(16),
  },
  loadMoreButton: {
    backgroundColor: Colors.accentSecondary,
    paddingHorizontal: ms(24),
  },
  loadMoreButtonText: {
    color: Colors.accent,
  },
  segmentCountText: {
    marginTop: ms(8),
    fontSize: ms(12),
    color: Colors.gray8,
  },
});

import { useState } from "react";
import { View, StyleSheet, Text, Pressable } from "react-native";
import { ms } from "@/ui/moderateScale";
import { Colors } from "@/constants";

const RadioButton = ({
  text,
  isSelected,
  onPress,
  disabled = false,
}: {
  text: string;
  isSelected: boolean;
  onPress: () => void;
  disabled?: boolean;
}) => {
  const [active, setActive] = useState(false);

  return (
    <Pressable
      disabled={disabled}
      onHoverIn={() => setActive(true)}
      onHoverOut={() => setActive(false)}
      onPress={onPress}
      style={StyleSheet.flatten([s.radioContainer])}
    >
      <Text style={s.radioText}>{text}</Text>
      <View
        style={StyleSheet.flatten([
          s.radioBigButton,
          isSelected ? s.radioBigButtonSelected : s.radioBigButtonUnSelected,
          active && { borderColor: Colors.accent },
        ])}
      >
        <View style={s.radioSmallButton} />
      </View>
    </Pressable>
  );
};

const s = StyleSheet.create({
  radioContainer: {
    height: ms(40),
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  radioText: {
    fontSize: ms(14),
    fontWeight: "400",
    color: Colors.text,
  },
  radioBigButton: {
    borderWidth: ms(1.5),
    borderColor: Colors.subtitle,
    width: ms(20),
    height: ms(20),
    borderRadius: ms(20),
    alignItems: "center",
    justifyContent: "center",
  },
  radioBigButtonSelected: {
    backgroundColor: Colors.accent,
    borderColor: Colors.accent,
  },
  radioBigButtonUnSelected: {
    backgroundColor: Colors.white,
    borderColor: Colors.subtitle,
  },
  radioSmallButton: {
    height: ms(10),
    width: ms(10),
    borderRadius: ms(5),
    backgroundColor: Colors.white,
  },
});

export default RadioButton;

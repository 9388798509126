import * as ServerTypes from "@/models/types";
import { useUserSlice } from "@/models/useUserSlice";
import { Router } from "@/_router";
import { __DEV__ } from "@/constants";
import { getBasePath } from "@/models/utils";
import { useUISlice } from "@/models/useUISlice";

let serverUrl = "https://1transcribe.com/api";
// let serverUrl = "https://1transcribe-api-test.dkt201474-com.workers.dev";

if (__DEV__) {
  serverUrl = "http://localhost:8787";
}

export const parseJwt = (token: string): ServerTypes.AuthUser => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const cipher = (jsonData: { [key: string]: any }): string => {
  const hexBuff = Buffer.from(JSON.stringify(jsonData));
  const hexData = hexBuff.toString("hex");

  const base64Buff = Buffer.from(hexData);
  return base64Buff.toString("base64");
};

export const authenticateAsync = async (
  params: ServerTypes.ApiRequestAuthenticate["payload"]
): Promise<ServerTypes.ApiResponseAuthenticate> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "Authenticate",
      payload: params,
    };

    return await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

const useAuthorized = (res: Response): any => {
  if (res.status === 403) {
    Router.replace(`${getBasePath()}/auth?status=logout`);

    return res.json();
  }

  return res.json();
};

const getTokenAsync = async (): Promise<string> => {
  let token = useUserSlice.getState().userToken;

  if (!token) {
    token = useUISlice.getState().guestToken;
  }

  if (!token) {
    Router.replace(`${getBasePath()}/`);
    return "";
  }

  if (token) {
    const authUser = parseJwt(token);
    const expirationTime = authUser?.exp ? authUser.exp * 1000 : 0; // Convert to milliseconds
    const isExpired = Date.now() >= expirationTime;

    if (isExpired) {
      useUserSlice.getState().setUserToken(undefined);
      useUISlice.getState().setGuestToken(undefined);
      Router.replace(`${getBasePath()}/`);
      return "";
    }
  }

  return token;
};

export const transcribeAsync = async (
  params: ServerTypes.ApiRequestTranscribe["payload"]
): Promise<ServerTypes.ApiResponseTranscribe> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "Transcribe",
      payload: params,
    };

    return await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await getTokenAsync(),
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const uploadFileAsync = async (params: {
  key: string;
  body?: ArrayBuffer;
  signal?: AbortSignal;
  partNumber?: string;
  uploadId?: string;
  parts?: { PartNumber: number; ETag: string }[];
}): Promise<ServerTypes.ApiResponseUploadFile> => {
  try {
    const url = new URL(`${serverUrl}/upload-file`);
    url.searchParams.append("action", "UploadFile");
    url.searchParams.append("key", params.key);
    if (params.partNumber) {
      url.searchParams.append("partNumber", params.partNumber);
    }
    if (params.parts) {
      url.searchParams.append("parts", JSON.stringify(params.parts));
    }
    if (params.uploadId) {
      url.searchParams.append("uploadId", params.uploadId);
    }

    return await fetch(url, {
      signal: params.signal,
      method: "POST",
      headers: {
        Authorization: await getTokenAsync(),
        Accept: "application/json",
        "Content-Type": "application/octet-stream",
      },
      body: params.body,
    }).then((res) => res.json());
  } catch (e) {
    __DEV__ && console.log(e);
    return { error: e, ok: false, action: "UploadFile" };
  }
};

export const subscriptionAsync = async (
  params: ServerTypes.ApiRequestSubscription["payload"]
): Promise<ServerTypes.ApiResponseSubscription> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "Subscription",
      payload: params,
    };

    return await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await getTokenAsync(),
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const transcriptAsync = async (
  params: ServerTypes.ApiRequestTranscript["payload"]
): Promise<ServerTypes.ApiResponseTranscript> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "Transcript",
      payload: params,
    };

    return await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await getTokenAsync(),
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const migrateMobileUserAsync = async (
  params: ServerTypes.ApiRequestMigrateMobileUser["payload"]
): Promise<ServerTypes.ApiResponseMigrateMobileUser> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "MigrateMobileUser",
      payload: params,
    };

    return await fetch(serverUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: await getTokenAsync(),
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

import {
  View,
  StyleSheet,
  Dimensions,
  ScrollView,
  StyleProp,
  ViewStyle,
} from "react-native";
import LandingPageHeader from "@/ui/LandingePageHeader";
import LandingPageFooter from "@/ui/LandingePageFooter";
import AlertExportTranscriptOptions from "@/ui/AlertExportTranscriptOptions";
import AlertPrompt from "@/ui/AlertPrompt";
import AlertPromptSignIn from "./AlertPromptSignIn";
import AlertModal from "./AlertModal";
import WebPaywall from "./WebPaywall";
import { useEffect } from "react";
import { useRouter } from "@/_router";
import { useTranscribe } from "@/models/useTranscribe";

export default function LandingPageContainer({
  children,
  bgColor,
  onLogoPress,
  style,
  hideLanguageSelector,
  hideLogoTitle,
  withBorder,
  Header,
}: {
  children: React.ReactNode;
  hideLanguageSelector?: boolean;
  hideLogoTitle?: boolean;
  bgColor?: string;
  withBorder?: boolean;
  onLogoPress?: () => void;
  style?: StyleProp<ViewStyle>;
  Header?: React.ReactNode;
}) {
  const router = useRouter();
  const { retranscribeOriginalFile } = useTranscribe();

  useEffect(() => {
    WebPaywall.onCheckoutSuccess(router, retranscribeOriginalFile);
  }, []);

  return (
    <>
      <View style={[styles.container, { backgroundColor: bgColor }, style]}>
        {Header ? (
          Header
        ) : (
          <View style={styles.headerContainer}>
            <LandingPageHeader
              onLogoPress={onLogoPress}
              hideLanguageSelector={hideLanguageSelector}
              hideLogoTitle={hideLogoTitle}
              withBorder={withBorder}
            />
          </View>
        )}
        <ScrollView
          style={styles.scrollContainer}
          contentContainerStyle={styles.scrollContent}
        >
          {children}
        </ScrollView>
        <LandingPageFooter />
      </View>

      <AlertExportTranscriptOptions />
      <AlertPrompt />
      <AlertPromptSignIn />
      <AlertModal />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: Dimensions.get("window").width,
    minHeight: Dimensions.get("window").height,
    position: "absolute",
    top: 0,
    left: 0,
  },
  headerContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    backgroundColor: "white",
  } as any,
  scrollContainer: {
    flex: 1,
    width: "100%",
    marginTop: LandingPageHeader.HEIGHT,
  },
  scrollContent: {
    flexGrow: 1,
    alignItems: "center",
  },
});

import NativeTranscripts from "@/pages/native/transcripts";
import { App } from "@capacitor/app";
import { useEffect } from "react";
import { useAuth } from "@/models/useAuth";
import { useTranscript } from "@/models/useTranscript";
import NativePaywall from "@/ui/NativePaywall";
import { datadogLogs } from "@datadog/browser-logs";
import { getPlatform } from "@/models/platform";

const NativeApp = () => {
  const { signInWithMobile } = useAuth();
  const { listTranscripts } = useTranscript();
  const platform = getPlatform();

  useEffect(() => {
    datadogLogs.logger.info("[TranscribeFunnel][1] - Page loaded", {
      platform,
    });

    signInWithMobile({
      callback: () => {
        NativePaywall.initialize(true);
        listTranscripts();
      },
    });

    const listenBackButton = () => {
      App.addListener("backButton", () => {
        App.minimizeApp();
      });
    };

    listenBackButton();

    return () => {
      App.removeAllListeners();
    };
  }, []);

  return <NativeTranscripts />;
};

export default NativeApp;

import { View, StyleSheet, Text } from "react-native";
import { ms } from "@/ui/moderateScale";
import { Colors } from "@/constants";
import { ActivityIndicator } from "react-native";
import { isMobileWindow } from "@/models/utils";
import { Status } from "@/models/useTranscribe";
import { useRouter } from "@/_router";
import { useTranslation } from "react-i18next";
import PrimaryButton from "@/ui/PrimaryButton";

export default function TranscribeLoadingIndicator(item: {
  status: Status;
  uploadProgress: number;
  transcriptionProgress: number;
}) {
  const isMobile = isMobileWindow();
  const { t } = useTranslation();
  const router = useRouter();

  let progress = item.uploadProgress;

  if (item.transcriptionProgress > 0) {
    progress = Math.min(item.transcriptionProgress, 100);
  }

  const statusMapper = {
    UPLOADING: {
      title: t("ui.uploadingTitle"),
      description: t("ui.uploadingDescription"),
    },
    TRIMMING: {
      title: t("ui.scanningTitle"),
      description: t("ui.scanningDescription"),
    },
    TRANSCRIBING: {
      title: t("ui.transcribingTitle"),
      description: t("ui.transcribingDescription"),
    },
    ERROR: {
      title: t("ui.unexpectedErrorTitle"),
      description: t("ui.unexpectedErrorDescription"),
    },
  };

  return (
    <View
      style={StyleSheet.flatten([
        styles.section,
        isMobile && { margin: ms(16), width: "auto" },
      ])}
    >
      <View style={styles.sectionTitleContainer}>
        {item.status !== "ERROR" && (
          <ActivityIndicator size="small" color={Colors.accentPrimary} />
        )}
        <Text
          style={StyleSheet.flatten([
            styles.sectionTitle,
            { color: item.status === "ERROR" ? Colors.error : Colors.text },
          ])}
        >
          {statusMapper[item.status].title}
        </Text>
      </View>

      {["UPLOADING", "TRIMMING", "TRANSCRIBING"].includes(item.status) && (
        <View style={styles.progressBarContainer}>
          <View
            style={[
              styles.progressBar,
              {
                width: `${progress}%`,
                backgroundColor: Colors.accentPrimary,
              },
            ]}
          />
        </View>
      )}
      <Text style={styles.infoText}>
        {statusMapper[item.status].description}
      </Text>

      {["ERROR"].includes(item.status) && (
        <PrimaryButton
          style={{ marginTop: ms(24) }}
          text={t("ui.goBackButton")}
          onPress={() => router.back()}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  section: {
    marginTop: ms(48),
    backgroundColor: Colors.gray2,
    borderRadius: ms(8),
    padding: ms(24),
    maxWidth: ms(400),
    width: "100%",
  },
  sectionTitle: {
    color: Colors.text,
    fontSize: ms(16),
    fontWeight: "500",
    textAlign: "center",
  },
  sectionTitleContainer: {
    flexDirection: "row",
    justifyContent: "center",
    gap: ms(8),
    marginBottom: ms(16),
  },
  progressBarContainer: {
    width: "100%",
    height: ms(4),
    backgroundColor: Colors.gray4,
    borderRadius: ms(2),
    marginBottom: ms(12),
  },
  progressBar: {
    height: "100%",
    borderRadius: ms(2),
  },
  infoText: {
    color: Colors.textSecondary,
    fontSize: ms(14),
    textAlign: "center",
    lineHeight: ms(18),
  },
});

import React from "react";
import { Transcript } from "@/models/types";
import { View, Text, Pressable, StyleSheet } from "react-native";
import Icon from "@/ui/Icon";
import { useTranslation } from "react-i18next";
import { ms } from "./moderateScale";
import { Colors } from "@/constants/Colors";
import { formatTimestamp } from "@/models/utils";
import { useTranscript } from "@/models/useTranscript";
import { useTranscribe } from "@/models/useTranscribe";

export default React.memo(
  function TranscriptViewerAudioSegmentItem({
    segment,
    audio,
    transcriptId,
    isSelected,
    onPress,
  }: {
    onPress: (seconds: number) => void;
    isSelected: boolean;
    transcriptId: string;
    segment: Transcript["segments"][number];
    audio: {
      hasError: boolean;
      play: (seconds: number) => void;
      pause: () => void;
      position: number;
      duration: number;
      isPlaying: boolean;
      seekTo: (seconds: number) => void;
    };
  }) {
    const { t } = useTranslation();
    const { editSegment, renameSpeaker } = useTranscript();
    const { retranscribeWithSpeakerLabels } = useTranscribe();

    return (
      <Pressable
        onPress={() => onPress(segment.start)}
        style={styles.container}
      >
        <View style={styles.header}>
          <View style={styles.headerLeft}>
            {segment.speaker ? (
              <Text
                onPress={() => renameSpeaker(transcriptId, segment.speaker)}
                style={styles.speaker}
              >
                {isNaN(Number(segment.speaker))
                  ? segment.speaker
                  : `${t("ui.speaker")} ${Number(segment.speaker) + 1}`}
              </Text>
            ) : (
              <Pressable
                onPress={() => retranscribeWithSpeakerLabels(transcriptId)}
              >
                <Text style={styles.speaker}>{t("ui.addSpeaker")}</Text>
              </Pressable>
            )}
            {!audio.hasError && (
              <Pressable
                disabled={audio.hasError}
                onPress={() => {
                  if (audio.isPlaying) {
                    audio.pause();
                  } else {
                    audio.play(segment.start);
                  }
                }}
                style={styles.playButton}
              >
                <Icon
                  icon={audio.isPlaying ? "pause" : "play"}
                  style={styles.playIcon}
                />
              </Pressable>
            )}
            <Text style={styles.timestamp}>
              {formatTimestamp(segment.start)}
            </Text>
          </View>

          <Pressable
            onPress={() => editSegment(transcriptId, segment)}
            style={styles.editButton}
          >
            <Icon icon="pen" style={styles.editIcon} />
          </Pressable>
        </View>
        <Text style={[styles.text, isSelected && styles.selectedText]}>
          {segment.text.split(/<mark>(.*?)<\/mark>/).map((part, i) => (
            <Text key={i} style={i % 2 === 1 ? styles.highlight : {}}>
              {part}
            </Text>
          ))}
        </Text>
      </Pressable>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.isSelected === nextProps.isSelected &&
      prevProps.segment.start === nextProps.segment.start &&
      prevProps.segment.end === nextProps.segment.end &&
      prevProps.segment.text === nextProps.segment.text &&
      prevProps.segment.speaker === nextProps.segment.speaker &&
      prevProps.audio.isPlaying === nextProps.audio.isPlaying &&
      prevProps.audio.position === nextProps.audio.position &&
      prevProps.onPress === nextProps.onPress
    );
  }
);

const styles = StyleSheet.create({
  container: {
    marginBottom: ms(16),
    paddingVertical: ms(8),
    borderRadius: ms(8),
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: ms(4),
  },
  headerLeft: {
    flexDirection: "row",
    alignItems: "center",
  },
  speaker: {
    fontSize: ms(14),
    fontWeight: "600",
    color: Colors.text,
    marginRight: ms(12),
    textDecorationLine: "underline",
    textDecorationColor: Colors.accent,
    textDecorationStyle: "dotted",
  },
  timestamp: {
    fontSize: ms(12),
    color: Colors.textSecondary,
  },
  text: {
    fontSize: ms(16),
    lineHeight: ms(26),
    color: Colors.text,
    marginTop: ms(8),
    marginBottom: ms(8),
  },
  selectedText: {
    backgroundColor: "rgba(255, 165, 0, 0.075)", // Light orange highlight
  },
  playButton: {
    marginRight: ms(8),
    width: ms(20),
    height: ms(20),
    backgroundColor: Colors.gray4,
    borderRadius: ms(12),
    justifyContent: "center",
    alignItems: "center",
  },
  playIcon: {
    tintColor: Colors.text,
    width: ms(16),
    height: ms(16),
  },
  editButton: {
    width: ms(24),
    height: ms(24),
    justifyContent: "center",
    alignItems: "center",
  },
  editIcon: {
    tintColor: Colors.text,
    width: ms(16),
    height: ms(16),
  },
  highlight: {
    backgroundColor: "yellow",
  },
});

import { Clipboard } from "@capacitor/clipboard";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
export const useClipboard = () => {
  const { t } = useTranslation();

  const copyToClipboard = async (text: string) => {
    try {
      await Clipboard.write({
        string: text,
      });

      toast.success(t("ui.copied"));

      return true;
    } catch (error) {
      return false;
    }
  };

  const readFromClipboard = async () => {
    try {
      const { value } = await Clipboard.read();
      return value;
    } catch (error) {
      return null;
    }
  };

  return {
    copy: copyToClipboard,
    paste: readFromClipboard,
  };
};

import { i18n } from "@/locales";
import { Transcript } from "../../../server/cloudflare/src/lib/types";
import * as serverClient from "./serverClient";
import { useUISlice } from "./useUISlice";
import { useUserSlice } from "./useUserSlice";
import {
  downloadFile,
  downloadTranscriptAsDocx,
  downloadTranscriptAsPdf,
  formatDuration,
} from "@/models/utils";
import { useClipboard } from "./useClipboard";
import { datadogLogs } from "@datadog/browser-logs";
import { getPlatform, isNativeApp } from "./platform";

export const useTranscript = () => {
  const transcripts = useUserSlice((state) => state.transcripts);
  const clipboard = useClipboard();
  const platform = getPlatform();
  const currentTranscript = useUserSlice((state) => {
    const id = new URLSearchParams(window.location.search).get("id");
    return state.transcripts.find((t) => t.id === id);
  });

  const listTranscripts = async (): Promise<Transcript[]> => {
    if (!useUserSlice.getState().userToken) return [];

    const transcriptsResponse = await serverClient.transcriptAsync({
      type: "list",
    });

    if (transcriptsResponse?.data?.transcripts) {
      useUserSlice
        .getState()
        .setTranscripts(transcriptsResponse.data.transcripts.reverse());

      return transcriptsResponse.data.transcripts;
    }

    return [];
  };

  const editSegment = (id: string, segment: Transcript["segments"][number]) => {
    const transcript = transcripts.find((t) => t.id === id);
    if (!transcript) return;

    datadogLogs.logger.info("[TranscribeFunnel][8.a1] - edit segment", {
      transcriptId: id,
      isNative: isNativeApp(),
      platform,
    });

    useUISlice.getState().setAlertPrompt({
      title: i18n.t("ui.editTranscript"),
      defaultValue: segment.text,
      placeholder: i18n.t("ui.transcript"),
      multiLine: true,
      button: {
        text: i18n.t("ui.save"),
        onPress: (text: string) => {
          useUserSlice.getState().updateTranscript(id, {
            segments: transcript.segments.map((s) =>
              s.start === segment.start ? { ...s, text: text.trim() } : s
            ),
          });
          useUISlice.getState().setAlertPrompt(undefined);
        },
      },
    });
  };

  const renameSpeaker = (id: string, previousSpeaker: string) => {
    const transcript = transcripts.find((t) => t.id === id);
    if (!transcript) return;

    datadogLogs.logger.info("[TranscribeFunnel][8.a2] - rename speaker", {
      transcriptId: id,
      isNative: isNativeApp(),
      platform,
    });

    useUISlice.getState().setAlertPrompt({
      title: i18n.t("ui.renameSpeaker"),
      defaultValue: isNaN(Number(previousSpeaker))
        ? previousSpeaker
        : `${i18n.t("ui.speaker")} ${Number(previousSpeaker) + 1}`,
      placeholder: i18n.t("ui.speaker"),
      button: {
        text: i18n.t("ui.save"),
        onPress: (text: string) => {
          useUserSlice.getState().updateTranscript(id, {
            segments: transcript.segments.map((s) =>
              s.speaker === previousSpeaker ? { ...s, speaker: text.trim() } : s
            ),
          });
          useUISlice.getState().setAlertPrompt(undefined);
        },
      },
    });
  };

  const rename = (id: string) => {
    const transcript = transcripts.find((t) => t.id === id);
    if (!transcript) return;

    datadogLogs.logger.info("[TranscribeFunnel][8.a3] - rename transcript", {
      transcriptId: id,
      isNative: isNativeApp(),
      platform,
    });

    useUISlice.getState().setAlertPrompt({
      title: i18n.t("ui.renameTranscriptionTitle"),
      defaultValue: transcript.fileName,
      placeholder: i18n.t("ui.renameTranscriptionPlaceholder"),
      button: {
        text: i18n.t("ui.renameButton"),
        onPress: (text: string) => {
          if (text.trim().length === 0) return;
          useUserSlice
            .getState()
            .updateTranscript(id, { fileName: text.trim() });
          useUISlice.getState().setAlertPrompt(undefined);
        },
      },
    });
  };

  const remove = (id: string) => {
    useUISlice.getState().setAlertModal({
      title: i18n.t("ui.deleteTranscriptionTitle"),
      subtitle: i18n.t("ui.deleteTranscriptionSubtitle"),
      cancelText: i18n.t("ui.deleteTranscriptionCancelButton"),
      button: {
        text: i18n.t("ui.deleteTranscriptionDeleteButton"),
        destructive: true,
        onPress: () => {
          datadogLogs.logger.info(
            "[TranscribeFunnel][8.a4] - remove transcript",
            { transcriptId: id, isNative: isNativeApp(), platform }
          );

          useUserSlice.getState().deleteTranscript(id);

          useUISlice.getState().setAlertModal(undefined);
        },
      },
    });
  };

  const download = async (id: string) => {
    const transcript = useUserSlice
      .getState()
      .transcripts.find((t) => t.id === id);
    if (!transcript) return;

    useUISlice.getState().setAlertExportTranscriptOptions({
      hasSpeakers: transcript.segments.some((s) => s.speaker),
      onExport: async ({
        withSpeakers,
        withTimestamps,
        withMonologue,
        exportType,
      }) => {
        let output: { metadata?: string; text: string }[] = [];

        const getSpeaker = (speaker: string) => {
          return isNaN(Number(speaker))
            ? speaker
            : `${i18n.t("ui.speaker")} ${Number(speaker) + 1}`;
        };

        if (withSpeakers && withTimestamps) {
          output = transcript.segments.map((t) => ({
            metadata: `${getSpeaker(t.speaker)} • ${formatDuration(t.start)} - ${formatDuration(t.end)}`,
            text: t.text,
          }));
        }

        if (withSpeakers && !withTimestamps) {
          output = transcript.segments.map((t) => ({
            metadata: getSpeaker(t.speaker),
            text: t.text,
          }));
        }

        if (!withSpeakers && withTimestamps) {
          const convertToSubtitleTime = (timeInMs: number): string => {
            const milliseconds = timeInMs % 1000;
            const seconds = Math.floor((timeInMs / 1000) % 60);
            const minutes = Math.floor((timeInMs / (1000 * 60)) % 60);
            const hours = Math.floor((timeInMs / (1000 * 60 * 60)) % 24);

            const formattedMilliseconds = String(milliseconds).padStart(3, "0");
            const formattedSeconds = String(seconds).padStart(2, "0");
            const formattedMinutes = String(minutes).padStart(2, "0");
            const formattedHours = String(hours).padStart(2, "0");

            return `${formattedHours}:${formattedMinutes}:${formattedSeconds},${formattedMilliseconds}`;
          };

          if (exportType === "srt") {
            output = transcript.segments.map((t) => ({
              metadata: `${convertToSubtitleTime(t.start * 1000)} - ${convertToSubtitleTime(t.end * 1000)}`,
              text: t.text,
            }));
          } else {
            output = transcript.segments.map((t) => ({
              metadata: `${formatDuration(t.start)} - ${formatDuration(t.end)}`,
              text: t.text,
            }));
          }
        }

        if (!withSpeakers && !withTimestamps) {
          output = transcript.segments.map((t) => ({ text: t.text }));
        }

        if (withMonologue) {
          output = [{ text: transcript.segments.map((t) => t.text).join(" ") }];
        }

        switch (exportType) {
          case "txt":
          case "srt":
            await downloadFile({
              type: "txt",
              text: output
                .map(({ metadata, text }) => {
                  let content = "";
                  if (metadata) {
                    content += metadata + "\n";
                  }
                  content += text.trim() + "\n\n";
                  return content;
                })
                .join(""),
              fileName: `${transcript.fileName}.${exportType}`,
            });
            break;

          case "docx":
            await downloadTranscriptAsDocx({
              fileName: transcript.fileName,
              items: output,
            });
            break;
          case "pdf":
            await downloadTranscriptAsPdf({
              fileName: transcript.fileName,
              items: output,
            });
            break;
        }
        datadogLogs.logger.info(
          "[TranscribeFunnel][8.a5] - download transcript",
          { transcriptId: id, isNative: isNativeApp(), platform }
        );
      },
    });
  };

  const downloadAudio = async (params: { id: string }) => {
    const transcript = useUserSlice
      .getState()
      .transcripts.find((t) => t.id === params.id);
    if (!transcript) return;

    datadogLogs.logger.info("[TranscribeFunnel][8.a6] - download audio", {
      transcriptId: params.id,
      isNative: isNativeApp(),
      platform,
    });

    downloadFile({
      type: "url",
      url: transcript.audioUrl,
      fileName: `${transcript.fileName}${transcript.audioUrl.substring(transcript.audioUrl.lastIndexOf("."))}`,
    });
  };

  const copy = (id: string) => {
    const transcript = transcripts.find((t) => t.id === id);
    if (!transcript) return;

    datadogLogs.logger.info("[TranscribeFunnel][8.a7] - copy transcript", {
      transcriptId: id,
      isNative: isNativeApp(),
      platform,
    });

    const text = transcript.segments
      ?.map((segment) => segment.text)
      .join("\n\n");
    clipboard.copy(text);
  };

  return {
    transcripts,
    currentTranscript,
    listTranscripts,
    editSegment,
    rename,
    renameSpeaker,
    remove,
    download,
    downloadAudio,
    copy,
  };
};

import { useRouter } from "@/_router";
import { appLanguages, i18n } from "@/locales";
import WebApp from "@/pages/web";
import { useEffect } from "react";

function LocalePage() {
  const router = useRouter();

  useEffect(() => {
    let locale = router.pathname.split("/")[1];

    const pathMappers = {
      "transcribir-audio-a-texto": "es",
      "transcrever-audio-para-texto": "pt",
      "transcribe-audio-to-text": "en",
    };

    if (pathMappers[locale]) {
      locale = pathMappers[locale];
    }

    if (appLanguages.map((lang) => lang.value).includes(locale)) {
      localStorage.setItem("locale", locale as string);
      i18n.changeLanguage(locale as string);
    } else {
      router.push("/");
    }
  }, [router.pathname]);

  return <WebApp />;
}

export default LocalePage;
